import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './NavContainer.css';

export default class NavContainer extends Component {
  render() {
    const { hideSidebar, children, navbar, sidebar } = this.props;

    return (
      <div className="NavContainer">
        {navbar}
        <div className="NavContainer--main">
          {!hideSidebar && sidebar}
          <div className="NavContainer--content">{children}</div>
        </div>
      </div>
    );
  }
}

NavContainer.propTypes = {
  hideSidebar: PropTypes.bool,
  navbar: PropTypes.element.isRequired,
  sidebar: PropTypes.element,
};
