import { pickBy } from 'lodash';
import { FORM_ERROR } from 'final-form';
import { Toaster } from '@blueprintjs/core';

// allowedSizes should be an array of { width, height, maxBytes }
export function validateImageSize(allowedSizes, { width, height, bytes }) {
  let hasValidSize = false;
  for (const allowedSize of allowedSizes) {
    if (width !== allowedSize.width || height !== allowedSize.height) {
      continue;
    }

    if (bytes !== undefined && bytes > allowedSize.maxBytes) {
      const sizeKB = Math.ceil(bytes / 1024);
      const maxSizeKB = Math.ceil(allowedSize.maxBytes / 1024);
      return `Image is ${sizeKB} KB; max allowed is ${maxSizeKB} KB.`;
    }

    hasValidSize = true;
  }

  if (!hasValidSize) {
    const sizeNames = allowedSizes.map(
      (size) => `${size.width}x${size.height}`
    );
    return `Image has invalid dimensions ${width}x${height}; must be ${sizeNames.join(
      ' or '
    )}`;
  }
}

// Get only dirty values (using top-level form keys) from a form
export function getDirtyValues(values, form) {
  const dirtyTopLevelFields = new Set();
  for (const [name, dirty] of Object.entries(form.getState().dirtyFields)) {
    if (dirty) {
      const topLevelName = name.split('.')[0];
      dirtyTopLevelFields.add(topLevelName);
    }
  }

  return pickBy(values, (value, key) => dirtyTopLevelFields.has(key));
}

// Convert an exception into a final-form error message
export function getFormErrorObject(e) {
  return {
    [FORM_ERROR]: e.message,
  };
}

const FormToaster = Toaster.create();

export function createFormErrorToast(e) {
  FormToaster.show({
    intent: 'danger',
    message: e.message,
  });
}
