import { AsyncActionHandler } from 'lib/action-handler';
import apollo from '../apollo';

import { updateAppVersionMutation } from 'gam/queries';
import { mergeApps } from 'gam/state-utils';

export default new AsyncActionHandler({
  name: 'update_app_version',
  defaultState: {
    apps: {},
  },
  handler(app, version, changes) {
    const { developerProfileId, refId } = app;
    const { buildId } = version;

    return async () => {
      const { data: response } = await apollo.mutate({
        mutation: updateAppVersionMutation,
        variables: {
          developerProfileId,
          refId,
          buildId,
          ...changes,
        },
      });

      return {
        entries: [response.app],
      };
    };
  },
  reduce(state, { entries }) {
    return {
      ...mergeApps(state, entries),
    };
  },
});
