import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Dialog, FormGroup } from '@blueprintjs/core';
import styled from 'styled-components';
import { useFormState, useForm } from 'react-final-form';

import {
  PromiseActionButton,
  PromiseActionStatusCallout,
} from 'lib/components/ActionStatus';

import { submitBuildForReview } from 'gam/actions';

import { SimpleForm, InputField } from 'lib/controls/form';
import { RequirementsPopover } from 'lib/controls/popover';

import { nonEmpty } from 'lib/form/validation';
import { getFormRequirements } from '../../utils';

export const NotesForReviewersField = ({ name }) => (
  <InputField
    name={name || 'developerComments'}
    data-test-type="notes-for-reviewers-field"
    component="textarea"
    className="bp3-input bp3-fill"
    maxLength={1000}
    placeholder={
      'Brief summary of changes. Please include any additional instructions needed ' +
      'for testing, such as test accounts or unlock codes.'
    }
    validate={nonEmpty('Must explain changes since last submission')}
  />
);

const mapDispatchToProps = (dispatch) => ({
  onSubmitBuildForReview: (app, version, data) =>
    dispatch(submitBuildForReview({ app, version, data })),
});

export const SubmitBuildForReviewForm = connect(
  null,
  mapDispatchToProps
)(({ app, version, onSubmitBuildForReview, onClose }) => {
  const [submitPromise, setSubmitPromise] = useState();

  const handleSubmit = useCallback(
    (values) => {
      const { developerComments } = values;

      const promise = onSubmitBuildForReview(app, version, {
        developerComments,
      });

      setSubmitPromise(promise);

      // Close dialog on success
      if (onClose) {
        promise.then(() => onClose());
      }
    },
    [app, version, onSubmitBuildForReview, onClose, setSubmitPromise]
  );

  const initialValues = {
    developerComments: '',
  };

  return (
    <SimpleForm initialValues={initialValues} onSubmit={handleSubmit}>
      <FormGroup label="Notes for Reviewers">
        <NotesForReviewersField />
      </FormGroup>
      <ReviewButton submitPromise={submitPromise} />
      <PromiseActionStatusCallout promise={submitPromise} />
    </SimpleForm>
  );
});

const ReviewButton = ({ submitPromise }) => {
  const formState = useFormState();
  const { submit } = useForm();
  const { valid } = formState;
  const requirements = getFormRequirements(formState);

  return (
    <RequirementsPopover requirements={requirements} disabled={valid}>
      <PromiseActionButton
        promise={submitPromise}
        disabled={!valid}
        onClick={submit}
      >
        Submit for Review
      </PromiseActionButton>
    </RequirementsPopover>
  );
};

const DialogContent = styled.div`
  margin: 1em 1em 0;
`;

export const SubmitBuildForReviewDialog = ({ isOpen, onClose, ...props }) => (
  <Dialog title="Submit Build for Review" isOpen={isOpen} onClose={onClose}>
    <DialogContent>
      <SubmitBuildForReviewForm {...props} onClose={onClose} />
    </DialogContent>
  </Dialog>
);
