/* eslint-disable */

// API
exports.fitbitApiUrl = process.env.REACT_APP_FITBIT_API_URL;
exports.registryApiUrl = `${exports.fitbitApiUrl}/1/`;
exports.registryGraphqlUrl = `${exports.registryApiUrl}app-registry/graphql`;

exports.fitbitAdminApiUrl = process.env.REACT_APP_FITBIT_ADMIN_API_URL;
exports.registryAdminApiUrl = `${exports.fitbitAdminApiUrl}/1/`;
exports.registryAdminGraphqlUrl = `${exports.registryAdminApiUrl}app-registry-admin/graphql`;
exports.desktopGalleryUrl = '';

exports.loginDurationSeconds = 86400; // 1 day for now

const envPrefix = (exports.envPrefix = process.env.REACT_APP_FITBIT_ENV_PREFIX || '');

exports.gamMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE;

// Auth
exports.fitbitClientId = process.env.REACT_APP_FITBIT_AUTH_CLIENT_ID;

// Other sites
exports.devSite =
  process.env.REACT_APP_DEV_SITE_URL ||
  (envPrefix.length === 0 ? 'https://dev.fitbit.com' : `https://dev.fitbit-${envPrefix}.com`);

exports.distributionAgreementUrl =
  process.env.REACT_APP_DISTRIBUTION_AGREEMENT_URL ||
  `${exports.devSite}/legal/app-distribution-agreement/`;

exports.guidelinesUrl =
  process.env.REACT_APP_GALLERY_GUIDELINES_URL ||
  `${exports.devSite}/legal/app-gallery-guidelines/`;

exports.apiEndpointsFitbit =
  envPrefix.length === 0 ? 'https://api.fitbit.com' : `https://api.fitbit-${envPrefix}.com`;

exports.studioUrl =
  process.env.REACT_APP_STUDIO_URL ||
  (envPrefix.length === 0
    ? 'https://studio.fitbit.com'
    : `https://${envPrefix}-studio.site-ops.fitbit.com`);

exports.devEnrollUrl = process.env.REACT_APP_DEV_ENROLL_URL || `${exports.studioUrl}/new-developer`;

exports.galleryUrl =
  process.env.REACT_APP_GALLERY_URL ||
  (envPrefix.length === 0
    ? 'https://gallery.fitbit.com'
    : `https://${envPrefix}-gallery.site-ops.fitbit.com`);

// Other config
exports.supportEmail = 'appgallerysupport@fitbit.com';
exports.defaultLanguage = 'en';
exports.fallbackLanguages = ['en', 'default'];

// Misc constants (TODO refactor)
exports.fitbitProfileId = '00000000-0000-4000-8000-000000f17b17';
exports.appMaxBannerImages = 5; // @todo enforce server-side as well
exports.appNameMaxLength = 30;
exports.developerProfileNameMaxLength = 50;
exports.emailMaxLength = 75;
exports.urlMaxLength = 150;
exports.maxImageBytes = 500 * 1024; // 500KB

// Minimum length of description
exports.descriptionMinLength = 150;

exports.supportedLanguages = {
  en: 'English',
  fr: 'French',
  it: 'Italian',
  de: 'German',
  es: 'Spanish',
  nl: 'Dutch',
  sv: 'Swedish',
  ja: 'Japanese',
  ko: 'Korean',
  'zh-cn': 'Chinese (Simplified)',
  'zh-tw': 'Chinese (Traditional)',
  pl: 'Polish',
  'pt-br': 'Portuguese (Brazil)',
  ro: 'Romanian',
  ru: 'Russian',
  cs: 'Czech',
  id: 'Indonesian',
  'nb-no': 'Norwegian',
};

exports.typeNameMap = {
  APP: 'App',
  CLOCK: 'Clockface',
  SERVICE: 'Service',
  TILE: 'Tile',
};

exports.typeNamePlural = {
  APP: 'Apps',
  CLOCK: 'Clocks',
  SERVICE: 'Services',
  TILE: 'Tiles',
};

exports.reverseTypeNamePlural = {
  apps: 'APP',
  clocks: 'CLOCK',
  services: 'SERVICE',
  tiles: 'TILE',
};

exports.appPublishStatusName = {
  DRAFT: 'Draft',
  PRIVATE: 'Private',
  PUBLISHED: 'Published',
  REMOVED: 'Removed',
};

exports.appPublishStatusDescriptions = {
  DRAFT: 'Waiting for at least one version to be uploaded',
  PRIVATE: 'App is available via direct link',
  PUBLISHED: 'App is publicly available in the App Gallery',
  REMOVED: 'App has been removed from the App Gallery',

  // LEGACY -- will be removed soon
  READY: 'Not published: need at least one published app version',
};

exports.reviewStatusName = {
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  CANCELLED: 'Cancelled',
  IN_REVIEW: 'In Review',
  WAITING_FOR_REVIEW: 'Waiting for Review',
};

exports.reviewCompleteStatuses = new Set(['APPROVED', 'REJECTED', 'CANCELLED']);

exports.changeRequestStatusName = {
  SUBMITTED: 'Submitted',
  REJECTED: 'Rejected',
  APPLIED: 'Applied',
  CANCELLED: 'Cancelled',
};

// When showing a screenshot for previews, etc, prioritize showing
// these screenshots first. Ideally we'd get this from the server.
exports.preferredScreenshotTypes = [
  'STANDARD_300X300_SCREENSHOT',
  'MIRA_SCREENSHOT',
  'MESON_SCREENSHOT',
];

exports.appCategories = {
  'health-and-fitness': {
    name: 'Health & Fitness',
  },
  'travel-and-transit': {
    name: 'Travel & Transit',
  },
  news: {
    name: 'News',
  },
  sports: {
    name: 'Sports',
  },
  productivity: {
    name: 'Productivity',
  },
  'home-and-iot': {
    name: 'Home & IOT',
  },
  'food-and-drink': {
    name: 'Food & Drink',
  },
  'lifestyle-and-entertainment': {
    name: 'Lifestyle & Entertainment',
  },
  'music-and-audio': {
    name: 'Music & Audio',
  },
  'business-and-finance': {
    name: 'Business & Finance',
  },
  games: {
    name: 'Games',
  },
  educational: {
    name: 'Educational',
  },
};

exports.clockTags = {
  analog: {
    name: 'Analog',
  },
  digital: {
    name: 'Digital',
  },
  abstract: {
    name: 'Abstract',
  },
  stats: {
    name: 'Stats',
  },
  customizeable: {
    name: 'Customizeable',
  },
  'cloud-driven': {
    name: 'Cloud Driven',
  },
};

exports.DATE_TIME_FORMAT = 'MMM Do YYYY h:mm A';
