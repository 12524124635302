import gql from 'graphql-tag';

function versionFragmentFields(buildReviewFields = '') {
  return gql`
  fragment version on AppVersion {
    createdAt
    publishedAt
    buildId
    versionString
    note
    platformDescriptors
    cPlatformDescriptors
    deviceApiVersion
    companionApiVersion
    clusterStorageIds
    publishStatus
    validPublishStatuses
    segments
    reviewStatus
    requestedPermissions
    buildReviewId
    buildReview {
      id
      createdAt
      updatedAt
      cancelledAt
      reviewStatus
      reviewedAt
      developerComments
      reviewerComments
      ${buildReviewFields}
    }
    tags
    storageFbaId
    fbaUrl
  }
`;
}

export const versionFragment = versionFragmentFields();
export const versionFragmentAdmin = versionFragmentFields('reviewerName');

export const localizedStringFragment = gql`
  fragment localizedString on LocalizedString {
    locale
    value
  }
`;

export const appAssetFragment = gql`
  fragment appAsset on AppAsset {
    id
    type
    uri
  }
`;

export const appAssetTypeInfoFragment = gql`
  fragment appAssetTypeInfo on AppAssetTypeInfo {
    type
    baseType
    category
    editorTitle
    width
    height
    scaleFactors
    mimeTypes
  }
`;

export const assetsFragment = gql`
  fragment assets on LocalizedAssetsByType {
    type
    value {
      # LocalizedAssets
      locale
      value {
        ...appAsset
      }
    }
  }

  ${appAssetFragment}
`;

export const deviceTypeInfoFragment = gql`
  fragment deviceTypeInfo on DeviceTypeInfo {
    type
    productName
    assetTypes {
      screenshot
    }
  }
`;

export const paymentInfoFragment = gql`
  fragment paymentInfo on PaymentInfo {
    provider
    otherProvider
    trialPeriodHours
    recurring
    price
  }
`;

export const actionValidityFragment = gql`
  fragment actionValidity on ActionValidity {
    action
    valid
    reasons {
      message
    }
  }
`;

export const changeRequestFragment = gql`
  fragment changeRequest on ChangeRequest {
    createdAt
    id
    type
    status
    changeInfoJson
    generatedDescription
    developerComments
    reviewerComments
  }
`;

function appFragmentFields(appFields = '', versionFields = versionFragment) {
  return gql`
    fragment app on App {
      id
      type
      developerProfileId
      developerProfileName {
        ...localizedString
      }
      refId
      publishStatus
      previewImageAsset {
        ...appAsset
      }
      assets {
        ...assets
      }
      supportedAssetTypes {
        ...appAssetTypeInfo
      }
      supportedDeviceTypes {
        ...deviceTypeInfo
      }
      name {
        ...localizedString
      }
      description {
        ...localizedString
      }
      learnMore {
        ...localizedString
      }
      learnMoreDeeplink {
        ...localizedString
      }
      versions {
        ...version
      }
      latestVersion {
        ...version
        actions {
          ...actionValidity
        }
      }
      currentReviewStatus
      categoryIds
      contactEmail
      contactUrl
      platforms
      extraPlatforms
      buildPlatforms
      payment {
        ...paymentInfo
      }
      isHidden
      isPremium
      actions {
        ...actionValidity
      }
      pagedChangeRequests(maxDaysOld: 14) {
        changeRequests {
          ...changeRequest
        }
      }
      ratingSummary {
        numRatings
        averageStars
        starCounts
      }
      privateLinkAccessKey

      ${appFields}
    }
  
    ${localizedStringFragment}
    ${assetsFragment}
    ${appAssetTypeInfoFragment}
    ${deviceTypeInfoFragment}
    ${versionFields}
    ${paymentInfoFragment}
    ${actionValidityFragment}
    ${changeRequestFragment}
  `;
}

export const appFragment = appFragmentFields();

const adminAppFields = `
  groups
  groupKeys
  extraPlatforms
  blacklistedPlatforms
  persistedFlags
  isPrimary
`;
export const appFragmentAdmin = appFragmentFields(adminAppFields, versionFragmentAdmin);
