import { uniq, find } from 'lodash';

import { galleryUrl } from 'lib/config';

import {
  SpecialPaymentProvider,
  PublicDeviceName,
  platformToScreenshotPlatformMap,
} from 'gam/constants';

const ILLEGAL_CHARS = /[^A-Za-z0-9!@#$%&+*()\-{}[\]\\:;"',. /?\u00C0-\u1FFF\u2C00-\uD7FF]+/;

export function getLatestVersion(app) {
  return app.versions[0];
}

export function isPaymentStatusLocked(app) {
  const { publishStatus, payment } = app;
  return publishStatus !== 'DRAFT' && payment && payment.provider === SpecialPaymentProvider.None;
}

export function isSubmittedAsPaid(app) {
  const { publishStatus, payment } = app;
  return publishStatus !== 'DRAFT' && payment && payment.provider !== SpecialPaymentProvider.None;
}

export function getLatestBuildReview(app) {
  const latestVersion = getLatestVersion(app);
  return latestVersion && latestVersion.buildReview;
}

export function getAppReviewStatus(app) {
  const latestVersion = getLatestVersion(app);
  return latestVersion ? latestVersion.reviewStatus : 'NOT_SUBMITTED';
}

export function getActionValidity({ actions }, action) {
  if (!actions) {
    throw new Error('no actions on provided object');
  }
  return find(actions, { action }) || {};
}

// For RequirementsPopover
export function getRequirementsFromActionValidity(actionValidity) {
  const { valid, reasons = [] } = actionValidity;

  if (valid) {
    return [];
  }

  const requirements = [];
  for (const reason of reasons) {
    requirements.push({
      key: reason.message,
      ok: false,
      text: reason.message,
    });
  }

  return requirements;
}

export function makePrivateLink(app) {
  return `${galleryUrl}/details/${app.id}`;
}

export function hasScreenshotSupport(app) {
  return app.supportedDeviceTypes.find((info) => info.assetTypes.screenshot) ? true : false;
}

export function hasInvalidCharacters(appName) {
  return ILLEGAL_CHARS.test(appName);
}

function getPlatformFromDescriptor(platformDescriptor) {
  return platformDescriptor.split(':')[0].toUpperCase();
}

export function isPlatformScreenshot(platformDescriptor, screenshot) {
  const platform = getPlatformFromDescriptor(platformDescriptor);
  const screenshotPlatform = platformToScreenshotPlatformMap[platform] || platform;
  const platformScreenshotType = `${screenshotPlatform}_SCREENSHOT`;

  return screenshot.assets[0].type === platformScreenshotType;
}

export function getExternalPlatformName(platformDescriptor) {
  const platform = getPlatformFromDescriptor(platformDescriptor);

  return PublicDeviceName[platform] || platform;
}

// Get deduplicated list of supported platform names
export function getExternalPlatformNames(platformDescriptors) {
  return uniq((platformDescriptors || []).map((desc) => getExternalPlatformName(desc)));
}

export const getFormRequirements = (formState) => {
  const requirements = [];
  const errorsObj = formState.errors || {};

  for (const [key, errorText] of Object.entries(errorsObj)) {
    requirements.push({
      key,
      text: errorText,
    });
  }

  return requirements;
};

export function checkShouldSkipBuildReview(developerProfile) {
  return developerProfile && developerProfile.canPublish;
}
