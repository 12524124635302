import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog } from '@blueprintjs/core';
import styled from 'styled-components';
import { xor } from 'lodash';

import TagFields from 'gam/components/App/TagFields';
import {
  SimpleForm,
  TextAreaField,
  SubmitButton,
  FormErrorCallout,
} from 'lib/controls/form';
import { getFormErrorObject } from 'lib/form-utils';
import { MarginTop } from 'lib/controls/layout';
import { createChangeCategoriesRequest } from 'gam/actions';

const Content = styled.div`
  margin: 1em;
`;

const RequestChangeForm = ({ app, closeDialog }) => {
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    async (values) => {
      try {
        await dispatch(
          createChangeCategoriesRequest(app, {
            categoryIds: values.categoryIds,
            comments: values.comments,
          })
        );
      } catch (e) {
        // Return error so it gets shown as submitError
        return getFormErrorObject(e);
      }

      closeDialog();
    },
    [app, dispatch, closeDialog]
  );

  const validateCategories = useCallback(
    ({ categoryIds }) => {
      // Categories must be different to allow changes
      if (xor(app.categoryIds, categoryIds).length === 0) {
        return {
          categoryIds: 'No changes',
        };
      }

      if (app.type === 'APP') {
        // At least one category besides "All Apps"
        if (categoryIds.length !== 2) {
          return {
            categoryIds: 'Invalid category selection',
          };
        }
      } else {
        // At least one tag besides "All Clocks"
        if (categoryIds.length < 2) {
          return {
            categoryIds: 'At least one tag required',
          };
        }
      }

      return undefined;
    },
    [app]
  );

  const initialValues = {
    categoryIds: app.categoryIds,
    comments: '',
  };

  const title =
    app.type === 'APP' ? 'Request Category Change' : 'Request Tag Changes';

  return (
    <SimpleForm
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validateCategories}
    >
      <Content>
        <h3>{title}</h3>
        <TagFields />
        <TextAreaField
          name="comments"
          fill
          placeholder="Additional comments (optional)"
        />
        <MarginTop>
          <FormErrorCallout />
        </MarginTop>
        <MarginTop>
          <SubmitButton>Submit request</SubmitButton>
        </MarginTop>
      </Content>
    </SimpleForm>
  );
};

const CategoryChangeDialog = ({ app, isOpen, onClose }) => (
  <Dialog isOpen={isOpen} onClose={onClose}>
    <RequestChangeForm app={app} closeDialog={onClose} />
  </Dialog>
);

export default CategoryChangeDialog;
