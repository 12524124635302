import React, { Component } from 'react';
import { connect } from 'react-redux';
import { groupBy, omit } from 'lodash';

import { MarginTop } from 'lib/controls/layout';
import { PromiseActionButton, PromiseActionStatusCallout } from 'lib/components/ActionStatus';

import LocalizedEditor from 'gam/components/LocalizedEditor';
import LocalizedDetailsPanel from './LocalizedDetailsPanel';
import GeneralDetailsPanel from './GeneralDetailsPanel';
import PaymentDetailsPanel from 'gam/components/PaymentDetailsPanel';

import { AppAssets } from 'gam/models/Assets';
import { updateApp } from 'gam/actions';
import { supportedLanguages } from 'lib/config';
import { isSubmittedAsPaid } from 'gam/utils';

import './AppDetailsEditor.css';

const GENERAL_PANEL_REF_ID = 'general';
const PAYMENT_PANEL_REF_ID = 'payment';

class AppDetailsEditor extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState(props);
    this.panelRefs = {};
  }

  getInitialState(props) {
    return {
      detailsByLocale: this.getInitialDetailsByLocale(props.app),
      generalDetails: {
        contactEmail: props.app.contactEmail,
        contactUrl: props.app.contactUrl,
      },
      paymentInfo: props.app.payment,
      paymentInvalid: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    // If the app object has changed identity
    if (nextProps.app !== this.props.app) {
      this.setState(this.getInitialState(nextProps));
    }
  }

  get defaultLanguage() {
    return 'en'; // FIXME
  }

  setPanelRef(refId, panel) {
    this.panelRefs[refId] = panel;
  }

  getInitialDetailsByLocale(app) {
    const { admin } = this.props;
    const detailsByLocale = {};
    let fields = [];
    if (admin) {
      fields = ['name', 'description', 'learnMore', 'learnMoreDeeplink'];
    } else {
      fields = ['name', 'description'];
    }

    for (const field of fields) {
      for (const { locale, value } of app[field] || []) {
        detailsByLocale[locale] = {
          ...detailsByLocale[locale],
          [field]: value,
        };
      }
    }

    const allScreenshots = new AppAssets(app).getScreenshots();

    for (const { locale, assets } of allScreenshots) {
      let localeDetails = detailsByLocale[locale] || {};
      const screenshots = localeDetails.screenshots || [];

      localeDetails = {
        ...localeDetails,
        screenshots: [...screenshots, ...assets],
      };
      detailsByLocale[locale] = localeDetails;
    }

    return detailsByLocale;
  }

  handleAddLanguage = (lang) => {
    this.setState({
      detailsByLocale: {
        ...this.state.detailsByLocale,
        [lang]: {
          isNew: true,
        },
      },
    });
  };

  handleRemoveLanguage = (lang) => {
    const { existingNamesEditable } = this.props;
    const { detailsByLocale } = this.state;

    if (lang === this.defaultLanguage) {
      return;
    }

    if (!existingNamesEditable && !detailsByLocale[lang].isNew) {
      return;
    }

    this.setState({
      detailsByLocale: omit(detailsByLocale, lang),
    });
  };

  getLocalizedUpdateData() {
    const app = this.props.app;
    const admin = this.props.admin;

    const nameStrings = [];
    const descriptionStrings = [];
    const learnMoreStrings = [];
    const learnMoreDeeplinkStrings = [];

    const assets = new AppAssets(app);

    Object.keys(this.state.detailsByLocale).forEach((lang) => {
      const langData = this.panelRefs[lang].getData();

      if (langData.name) {
        nameStrings.push({ locale: lang, value: langData.name });
      }
      if (langData.description) {
        descriptionStrings.push({ locale: lang, value: langData.description });
      }
      if (langData.screenshots) {
        const screenshotsByType = groupBy(langData.screenshots, 'type');

        for (const type of assets.getScreenshotAssetTypes()) {
          const existing = assets.getLocalizedAssets(type, lang);
          const updatedAssetsOfType = screenshotsByType[type] || [];

          if (existing || updatedAssetsOfType.length > 0) {
            // Update or clear existing assets of this type
            assets.setLocalizedAssets(type, lang, updatedAssetsOfType);
          }
        }
      }

      if (admin) {
        if (langData.learnMore) {
          learnMoreStrings.push({ locale: lang, value: langData.learnMore });
        }

        if (langData.learnMoreDeeplink) {
          learnMoreDeeplinkStrings.push({ locale: lang, value: langData.learnMoreDeeplink });
        }
      }
    });

    let formFields = {};

    formFields = {
      name: nameStrings,
      description: descriptionStrings,
      assets,
    };

    if (admin) {
      formFields.learnMore = learnMoreStrings;
      formFields.learnMoreDeeplink = learnMoreDeeplinkStrings;
    }

    return formFields;
  }

  getGeneralUpdateData() {
    const data = this.panelRefs[GENERAL_PANEL_REF_ID].getData();
    return {
      contactEmail: data.contactEmail || '',
      contactUrl: data.contactUrl || '',
    };
  }

  getPaymentUpdateData() {
    const paymentPanel = this.panelRefs[PAYMENT_PANEL_REF_ID];

    // If we click Save on a DRAFT, there will be no panel to access
    // In that case we'll just return an empty payment object
    if (!paymentPanel) {
      return {};
    }

    return paymentPanel.getData();
  }

  handleSave = () => {
    const changes = {
      ...this.getGeneralUpdateData(),
      ...this.getLocalizedUpdateData(),
      ...this.getPaymentUpdateData(),
    };

    const savePromise = this.props.onSaveUpdate(changes);

    this.setState({
      savePromise,
    });
  };

  paymentValidityChanged = (valid) => {
    if (this.state.paymentInvalid === valid) {
      this.setState({ paymentInvalid: !valid });
    }
  };

  get saveDisabled() {
    return this.state.paymentInvalid;
  }

  renderLocalizedPanel(lang) {
    const {
      editable,
      existingNamesEditable,
      app,
      descriptionEditDisabled,
      screenshotsEditDisabled,
    } = this.props;
    const { detailsByLocale } = this.state;
    const langData = detailsByLocale[lang];
    const nameEditable = editable && (existingNamesEditable || langData.isNew);

    return (
      <LocalizedDetailsPanel
        lang={lang}
        defaultLang={this.defaultLanguage}
        app={app}
        data={detailsByLocale[lang]}
        editable={editable}
        nameEditable={nameEditable}
        existingNamesEditable={existingNamesEditable}
        descriptionEditDisabled={descriptionEditDisabled}
        screenshotsEditDisabled={screenshotsEditDisabled}
        ref={(ref) => this.setPanelRef(lang, ref)}
        admin={this.props.admin}
      />
    );
  }

  renderGeneralDetailsPanel() {
    const { editable } = this.props;
    return (
      <GeneralDetailsPanel
        editable={editable}
        data={this.state.generalDetails}
        ref={(ref) => this.setPanelRef(GENERAL_PANEL_REF_ID, ref)}
      />
    );
  }

  renderPaymentDetailsPanel() {
    const { editable, app, admin } = this.props;

    // If they've selected a provider and therefore have a paid app, they will see the payment dialog
    if (isSubmittedAsPaid(app) || admin) {
      return (
        <PaymentDetailsPanel
          editable={editable}
          validityChanged={this.paymentValidityChanged}
          data={this.state.paymentInfo}
          admin={admin}
          ref={(ref) => this.setPanelRef(PAYMENT_PANEL_REF_ID, ref)}
        />
      );
    }
  }

  render() {
    const { editable } = this.props;
    const { detailsByLocale, savePromise } = this.state;
    const currentLanguages = Object.keys(supportedLanguages).filter(
      (lang) => !!detailsByLocale[lang]
    );

    return (
      <div>
        <div className="AppDetailsEditor">
          {this.renderGeneralDetailsPanel()}
          {this.renderPaymentDetailsPanel()}
        </div>
        <h3>Localized details</h3>
        <LocalizedEditor
          editable={editable}
          defaultLanguage={this.defaultLanguage}
          languages={currentLanguages}
          onAddLanguage={this.handleAddLanguage}
          onRemoveLanguage={this.handleRemoveLanguage}
          renderPanel={(lang) => this.renderLocalizedPanel(lang)}
        />
        {editable && (
          <MarginTop>
            <PromiseActionButton
              disabled={this.saveDisabled}
              promise={savePromise}
              data-test-type="save-app-edits-button"
              onClick={this.handleSave}>
              Save
            </PromiseActionButton>
            <PromiseActionStatusCallout promise={savePromise} />

            {this.saveDisabled && (
              <MarginTop className="bp3-callout bp3-intent-warning">
                Invalid or missing values for highlighted payment fields
              </MarginTop>
            )}
          </MarginTop>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, { app }) => ({
  onSaveUpdate: (changes) => dispatch(updateApp(app, changes)),
});

export default connect(undefined, mapDispatchToProps)(AppDetailsEditor);
