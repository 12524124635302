import React, { Component } from 'react';

// Helper that hooks up a button to opening a dialog
export default class DialogOpener extends Component {
  state = {
    isOpen: false,
  };

  open = () => {
    this.setState({ isOpen: true });
  };

  close = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { button, dialog } = this.props;

    const riggedButton = React.cloneElement(button, {
      onClick: this.open,
    });

    const riggedDialog = React.cloneElement(dialog, {
      isOpen: this.state.isOpen,
      onClose: this.close,
    });

    return (
      <React.Fragment>
        {riggedButton}
        {riggedDialog}
      </React.Fragment>
    );
  }
}
