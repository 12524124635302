import '@babel/polyfill';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/react-hooks';
import apolloClient from './apollo';

import store from './store';
import GAM from './GAM';

import 'normalize.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import 'lib/common.css';

export default class Main extends Component {
  render() {
    return (
      <Provider store={store}>
        <ApolloProvider client={apolloClient}>
          <GAM />
        </ApolloProvider>
      </Provider>
    );
  }
}

ReactDOM.render(<Main />, document.getElementById('root'));
