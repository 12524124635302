import gql from 'graphql-tag';
import {
  versionFragment,
  appFragment,
  appFragmentAdmin,
  localizedStringFragment,
  appAssetFragment,
  actionValidityFragment,
  changeRequestFragment,
} from 'lib/graphql/common/app-fragments';

export { getCategoriesQuery } from '../lib/graphql/queries/categories-queries';

export const appSummaryFragment = gql`
  fragment appSummary on App {
    id
    type
    createdAt
    developerProfileId
    developerProfileName {
      ...localizedString
    }
    refId
    publishStatus
    lastPublishedAt
    name {
      ...localizedString
    }
    previewImageAsset {
      ...appAsset
    }
    platforms
    ratingSummary {
      numRatings
      averageStars
    }
  }

  ${localizedStringFragment}
  ${appAssetFragment}
`;

export const pagedAppsFragment = gql`
  fragment pagedApps on PagedApps {
    apps {
      ...appSummary
    }
    nextPages
    offset
  }

  ${appSummaryFragment}
`;

export const developerProfileFragment = gql`
  fragment developerProfile on DeveloperProfile {
    id
    name {
      ...localizedString
    }
    website {
      ...localizedString
    }
    apps {
      ...appSummary
      currentReviewStatus
    }
    actions {
      ...actionValidity
    }
    canPublish
  }

  ${localizedStringFragment}
  ${appSummaryFragment}
  ${actionValidityFragment}
`;

export const firmwareChannelFragment = gql`
  fragment firmwareChannel on FirmwareChannel {
    id
    name
    isRelease
  }
`;

export const firmwareChannelDeviceFragment = gql`
  fragment firmwareChannelDevice on FirmwareChannelDevice {
    type
    productName
    firmwareChannelInfo {
      current {
        ...firmwareChannel
      }
      available {
        ...firmwareChannel
      }
    }
  }

  ${firmwareChannelFragment}
`;

export const getEnrollmentQuery = gql`
  query enrollment {
    enrollment {
      isDeveloper
      userProfile {
        fullName
      }
      requiredAgreements
      needDeviceRegistration
      hasAvailableDevices
      hasUnavailableDevices
      hasEmailVerified
    }
  }
`;

export const getUserDeveloperInfoQuery = gql`
  query getUserDeveloperInfoQuery {
    userDeveloperInfo {
      id
      developerProfileIds
      developerProfiles {
        ...developerProfile
      }
      permissions
      roles
    }
  }

  ${developerProfileFragment}
`;

export const updateDeviceChannelEnrollmentMutation = gql`
  mutation updateDeviceChannelEnrollmentMutation($deviceType: String!, $channelId: ID!) {
    updatedDevice: updateDeviceChannelEnrollment(deviceType: $deviceType, channelId: $channelId) {
      ...firmwareChannelDevice
    }
  }

  ${firmwareChannelDeviceFragment}
`;

export const setupNewDeveloperMutation = gql`
  mutation setupNewDeveloper($name: String!, $signedAgreements: [String!]!) {
    developerProfiles: setupNewDeveloper(name: $name, signedAgreements: $signedAgreements) {
      ...developerProfile
    }
  }

  ${developerProfileFragment}
`;

export const getDeveloperProfilesQuery = gql`
  query getDeveloperProfilesQuery {
    developerProfiles {
      ...developerProfile
    }
  }

  ${appFragment}
  ${developerProfileFragment}
`;

export const createAppMutation = gql`
  mutation createAppMutation($developerProfileId: ID!, $name: String!, $appType: String!) {
    app: createApp(developerProfileId: $developerProfileId, name: $name, type: $appType) {
      ...app
    }
  }

  ${appFragment}
`;

export const updateAppMutation = gql`
  mutation updateAppMutation($developerProfileId: ID!, $refId: ID!, $update: UpdateAppInput) {
    app: updateApp(developerProfileId: $developerProfileId, refId: $refId, update: $update) {
      ...app
    }
  }

  ${appFragment}
`;

export const uploadAppAssetMutation = gql`
  mutation uploadAppAssetMutation(
    $developerProfileId: ID!
    $refId: ID!
    $type: String!
    $file: ID!
  ) {
    appAsset: uploadAppAsset(
      developerProfileId: $developerProfileId
      refId: $refId
      type: $type
      file: $file
    ) {
      ...appAsset
    }
  }

  ${appAssetFragment}
`;

export const uploadAppVersionMutation = gql`
  mutation uploadAppVersionMutation(
    $developerProfileId: ID!
    $refId: ID!
    $file: ID!
    $versionString: String
    $developerComments: String
  ) {
    app: uploadAppVersion(
      developerProfileId: $developerProfileId
      refId: $refId
      file: $file
      versionString: $versionString
      developerComments: $developerComments
      submitForReview: true
    ) {
      ...app
    }
  }

  ${appFragment}
`;

export const uploadNewAppVersionMutation = gql`
  mutation uploadNewAppVersionMutation(
    $developerProfileId: ID!
    $refId: ID!
    $storageFbaId: ID!
    $versionString: String
    $developerComments: String
  ) {
    app: uploadNewAppVersion(
      developerProfileId: $developerProfileId
      refId: $refId
      storageFbaId: $storageFbaId
      versionString: $versionString
      developerComments: $developerComments
      submitForReview: true
    ) {
      ...app
    }
  }

  ${appFragment}
`;

export const updateAppVersionMutation = gql`
  mutation updateAppVersionMutation(
    $developerProfileId: ID!
    $refId: ID!
    $buildId: ID!
    $versionString: String
    $note: String
    $publishStatus: String
    $segments: [String!]
  ) {
    app: updateAppVersion(
      developerProfileId: $developerProfileId
      refId: $refId
      buildId: $buildId
      versionString: $versionString
      note: $note
      publishStatus: $publishStatus
      segments: $segments
    ) {
      ...app
    }
  }

  ${appFragment}
`;

export const publishAppVersionMutation = gql`
  mutation publishAppVersion($developerProfileId: ID!, $refId: ID!, $buildId: ID!) {
    appVersion: publishAppVersion(
      developerProfileId: $developerProfileId
      refId: $refId
      buildId: $buildId
    ) {
      ...version
    }
  }

  ${versionFragment}
`;

export const publishAppMutation = gql`
  mutation publishApp($developerProfileId: ID!, $refId: ID!) {
    app: publishApp(developerProfileId: $developerProfileId, refId: $refId) {
      ...app
    }
  }

  ${appFragment}
`;

export const submitBuildForReviewMutation = gql`
  mutation submitBuildForReviewMutation(
    $developerProfileId: ID!
    $refId: ID!
    $buildId: ID!
    $developerComments: String!
  ) {
    submitBuildForReview(
      developerProfileId: $developerProfileId
      refId: $refId
      buildId: $buildId
      developerComments: $developerComments
    ) {
      id
    }
  }
`;

export const cancelBuildInReviewMutation = gql`
  mutation cancelBuildInReviewMutation($id: ID!, $developerProfileId: ID!, $refId: ID!) {
    cancelBuildInReview(id: $id, developerProfileId: $developerProfileId, refId: $refId) {
      id
    }
  }
`;

export const getAppQuery = gql`
  query getAppQuery($developerProfileId: ID!, $refId: ID!) {
    app(developerProfileId: $developerProfileId, refId: $refId) {
      ...app
    }
  }

  ${appFragment}
`;

export const getAppAdminQuery = gql`
  query getAppQuery($developerProfileId: ID!, $refId: ID!) {
    app(developerProfileId: $developerProfileId, refId: $refId) {
      ...app
    }
  }

  ${appFragmentAdmin}
`;

export const updateAppCategoriesMutation = gql`
  mutation updateAppCategories($developerProfileId: ID!, $refId: ID!, $categoryIds: [ID!]!) {
    app: updateAppCategories(
      developerProfileId: $developerProfileId
      refId: $refId
      categoryIds: $categoryIds
    ) {
      ...app
    }
  }

  ${appFragment}
`;

export const getPaymentProvidersQuery = gql`
  query getPaymentProvidersQuery {
    paymentProviders
  }
`;

export const createChangeCategoriesRequestMutation = gql`
  mutation createChangeCategoriesRequestMutation(
    $developerProfileId: ID!
    $refId: ID!
    $categoryIds: [ID!]!
    $comments: String
  ) {
    changeRequest: createChangeCategoriesRequest(
      developerProfileId: $developerProfileId
      refId: $refId
      categoryIds: $categoryIds
      comments: $comments
    ) {
      ...changeRequest
    }
  }

  ${changeRequestFragment}
`;

export const cancelChangeRequestMutation = gql`
  mutation cancelChangeRequestMutation($developerProfileId: ID!, $refId: ID!, $id: ID!) {
    changeRequest: cancelChangeRequest(
      developerProfileId: $developerProfileId
      refId: $refId
      id: $id
    ) {
      ...changeRequest
    }
  }

  ${changeRequestFragment}
`;

export const updateAllowlistDeveloper = gql`
  mutation changeAppExtraPlatformsDeveloper($appId: ID!, $platforms: [String!]!) {
    app: changeAppExtraPlatformsDeveloper(appId: $appId, platforms: $platforms) {
      ...app
    }
  }

  ${appFragment}
`;

export const getFbaSignedUploadUrl = gql`
  query getFbaSignedUploadUrl {
    signedUrl: getFbaSignedUploadUrl {
      fbaLink
      storageFbaId
    }
  }
`;

export const updateAppAccessKeyMutation = gql`
  mutation updateAppAccessKeyMutation(
    $developerProfileId: ID!
    $refId: ID!
    $clearAccessKey: Boolean
  ) {
    app: updateAppAccessKey(
      developerProfileId: $developerProfileId
      refId: $refId
      clearAccessKey: $clearAccessKey
    ) {
      ...app
    }
  }

  ${appFragment}
`;
