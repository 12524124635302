import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useField } from 'react-final-form';
import { Checkbox } from '@blueprintjs/core';
import { map, union, without } from 'lodash';

import { localize } from 'lib/utils';
import { getClockCategories } from 'gam/state-utils';

const TagFields = () => {
  const categories = useSelector((state) => getClockCategories(state));
  const {
    input: { value: selectedCategoryIds, onChange },
  } = useField('categoryIds');

  const categoryIdSet = new Set(selectedCategoryIds);

  const handleChange = useCallback(
    (event) => {
      const target = event.currentTarget;
      const enabled = target.checked;

      let id = target.dataset.id;
      let updatedIds = enabled ? union(selectedCategoryIds, [id]) : without(selectedCategoryIds, id);

      onChange(updatedIds);
    },
    [onChange, selectedCategoryIds]
  );

  // END OF HOOKS

  return (
    <div className="MiscDetailsEditor--tags flex-col">
      {map(categories, ({ id, name }) => (
        <Checkbox
          key={id}
          data-id={id}
          checked={categoryIdSet.has(id)}
          name={`categories.${id}`}
          label={localize(name)}
          onChange={handleChange}
        />
      ))}
    </div>
  );
};

export default TagFields;
