import { prepareRoutes, trackRouteStates } from 'lib/route-utils';
import { redirect } from 'redux-first-router';

import { shortenRefId } from 'lib/utils';
import { hasDeveloperProfile } from './state-utils';
import { loadCategoriesAsync, loadPaymentProviders } from './route-middleware';
import { AuthClient } from '../lib/network/auth-client';

import { fetchUserInfo, fetchApp, fetchEnrollment } from 'gam/actions';

export const ensureAuth = (thunk) => async (dispatch, getState) => {
  const { isLoggedIn } = getState().gam.session;
  if (!isLoggedIn) {
    await AuthClient.getInstance().login({ redirectUrl: document.baseURI });
  }

  if (thunk) {
    await dispatch(thunk, getState);
  }
};

const ensureProfileOnly = (thunk) => async (dispatch, getState) => {
  await dispatch(fetchUserInfo({ useCache: true }));

  if (!hasDeveloperProfile(getState())) {
    return dispatch({ type: 'SETUP_DEVELOPER' });
  }

  if (thunk) {
    await dispatch(thunk, getState);
  }
};

export const ensureProfile = (thunk) => ensureAuth(ensureProfileOnly(thunk));

const redirectToGallery = (path, openwith) => {
  window.location = `https://gallery.fitbit.com${path}${
    openwith ? `?openwith=${openwith}` : ''
  }`;
};

const routes = {
  HOME: {
    path: '/',
    thunk: async (dispatch, getState) => {
      await AuthClient.getInstance().login({});
      const { isLoggedIn } = getState().gam.session;
      if (isLoggedIn) {
        await ensureProfileOnly()(dispatch, getState);

        // Redirect to apps
        await dispatch(redirect({ type: 'APPS' }));
      }
    },
  },
  LOGIN: {
    path: '/login',
    thunk: () => {
      AuthClient.getInstance().login({ redirectUrl: document.baseURI });
    },
  },
  LOGOUT: {
    path: '/logout',
    thunk: (dispatch) => {
      AuthClient.getInstance().logout(() => {
        dispatch({ type: 'HOME' });
      });
    },
  },
  SETUP_DEVELOPER: {
    path: '/setup',
    middleware: [ensureAuth],
    thunk: async (dispatch) => {
      await Promise.all([
        dispatch(fetchUserInfo()),
        dispatch(fetchEnrollment()),
      ]);
    },
  },
  APPS: {
    path: '/apps',
    middleware: [ensureProfile],
  },
  PREVIEW_ENROLLMENT: {
    path: '/preview-enrollment',
    middleware: [ensureProfile],
  },
  EDIT_APP: {
    path: '/apps/:shortRefId',
    middleware: [ensureProfile, loadCategoriesAsync, loadPaymentProviders],
    thunk: async (dispatch, getState) => {
      const { shortRefId } = getState().location.payload;
      const { apps } = getState().gam;

      // Find app using the shortened refId
      const app = Object.values(apps).find(
        (app) => shortenRefId(app.refId) === shortRefId
      );

      // Fetch the rest of the app details
      await dispatch(
        fetchApp({
          developerProfileId: app.developerProfileId,
          refId: app.refId,
        })
      );
    },
  },
  FAQ: {
    path: '/faq',
  },
  GALLERY_LINK_DISCOVERY: {
    path: '/gallery/:discoveryPath(apps|clocks)/:gallery?',
    thunk(_, getState) {
      const {
        payload: { discoveryPath },
        query: { openwith } = {},
      } = getState().location;

      redirectToGallery(`/${discoveryPath}`, openwith);
    },
  },
  GALLERY_LINK_APP: {
    path: '/gallery/:appPath(app|clock)/:appId',
    thunk(_, getState) {
      const {
        payload: { appId },
        query: { openwith } = {},
      } = getState().location;

      redirectToGallery(`/details/${appId}`, openwith);
    },
  },
  GALLERY_LINK_COLLECTION: {
    path:
      '/gallery/:appType(apps|clocks)/:collectionPath(collection|category)/:collectionId',
    thunk(_, getState) {
      const {
        payload: { collectionPath, collectionId },
        query: { openwith } = {},
      } = getState().location;

      redirectToGallery(`/${collectionPath}/${collectionId}`, openwith);
    },
  },
};

const { routesMap, types } = prepareRoutes(routes, {
  middleware: [trackRouteStates],
  defaults: {
    // Override number conversion nonsense
    toPath: (value) => value,
    fromPath: (value) => value,
  },
});

export { routesMap, types as PAGE };
