import React from 'react';
import { FormRow } from 'lib/controls/form';

export default ({ version }) => {
  const { versionString, buildReview } = version;
  const { developerComments } = buildReview || {};

  return (
    <div className="VersionCard--form">
      <FormRow label="Version">{versionString}</FormRow>
      {developerComments && (
        <FormRow label="Notes for reviewers">{developerComments}</FormRow>
      )}
    </div>
  );
};
