import React from 'react';
import { connect } from 'react-redux';
import { NamedSection } from 'lib/controls/layout';
import { getApp } from 'gam/state-utils';
import { PublishStatus } from 'gam/constants';
import { localize } from 'lib/utils';
import { typeNameMap } from 'lib/config';

import AppOverview from 'gam/components/AppOverview';
import AccessKeyEditor from 'gam/components/AccessKeyEditor';
import MiscDetailsEditor from 'gam/components/MiscDetailsEditor';
import AppDetailsEditor from 'gam/components/AppDetailsEditor';
import VersionManager from 'gam/components/VersionManager';
import ChangeRequests from 'gam/components/App/ChangeRequests';

import './EditApp.css';

export const AppOverviewSection = (props) => (
  <NamedSection name={localize(props.app.name)} subname={typeNameMap[props.app.type]}>
    <AppOverview {...props} />
  </NamedSection>
);

export const AppDetailsSection = (props) => {
  const {
    app,
    app: {
      publishStatus,
      currentReviewStatus,
      pagedChangeRequests: { changeRequests },
    },
    editable,
    admin,
  } = props;

  // TODO: should get these states from server
  const appEditable =
    editable !== undefined
      ? editable
      : currentReviewStatus !== 'IN_REVIEW' && publishStatus !== 'REMOVED';
  const categoryEditable =
    editable !== undefined ? editable : appEditable && publishStatus !== 'PUBLISHED';
  const existingNamesEditable =
    editable !== undefined ? editable : appEditable && publishStatus !== 'PUBLISHED';
  const descriptionEditDisabled = changeRequests.some(
    (el) => el.type === 'CHANGE_DESCRIPTION' && el.status === 'SUBMITTED'
  );
  const screenshotsEditDisabled = changeRequests.some(
    (el) => el.type === 'CHANGE_ASSETS' && el.status === 'SUBMITTED'
  );

  return (
    <div>
      {changeRequests.length > 0 && <ChangeRequests app={app} />}
      {app.publishStatus !== PublishStatus.PUBLISHED && (
        <AccessKeyEditor app={app} editable={appEditable} />
      )}
      <MiscDetailsEditor editable={categoryEditable} {...props} />
      <AppDetailsEditor
        editable={appEditable}
        existingNamesEditable={existingNamesEditable}
        descriptionEditDisabled={descriptionEditDisabled}
        screenshotsEditDisabled={screenshotsEditDisabled}
        admin={admin}
        {...props}
      />
    </div>
  );
};

export const VersionManagerSection = (props) => (
  <NamedSection name="Manage versions">
    <VersionManager {...props} />
  </NamedSection>
);

export const EditApp = ({ app }) => (
  <div className="EditApp">
    <AppOverviewSection app={app} />
    <AppDetailsSection app={app} admin={false} />
    <VersionManagerSection app={app} editable />
  </div>
);

const mapStateToProps = ({ gam }) => ({
  app: getApp({ gam }, gam.currentAppRefId),
});

export default connect(mapStateToProps)(EditApp);
