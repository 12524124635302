import { AsyncActionHandler } from 'lib/action-handler';
import apollo from '../apollo';
import { updateAppMutation } from 'gam/queries';
import { mergeApps } from 'gam/state-utils';

export default new AsyncActionHandler({
  name: 'update_app',
  defaultState: {
    apps: {},
  },
  handler(app, changes) {
    return async () => {
      const { developerProfileId, refId } = app;

      const { data: response } = await apollo.mutate({
        mutation: updateAppMutation,
        variables: {
          developerProfileId,
          refId,
          update: changes,
        },
      });

      return {
        entries: [response.app],
      };
    };
  },
  reduce(state, { entries }) {
    return {
      ...mergeApps(state, entries),
    };
  },
});
