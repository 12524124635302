const toCamelCase = (str) =>
  str.replace(/_([a-z])/g, (s) => s[1].toUpperCase());

export function buildExports(actionHandlers) {
  // Setup default state from the action handlers
  const defaultState = actionHandlers.reduce(
    (state, actionHandler) => Object.assign(state, actionHandler.defaultState),
    {}
  );

  // Add convenient action creators for use in the app
  const actionCreators = {};

  for (const actionHandler of actionHandlers) {
    actionCreators[toCamelCase(actionHandler.name)] =
      actionHandler.actionCreator;
  }

  // Combine action reducers
  const reducers = (state = defaultState, action) => {
    for (let i = 0; i < actionHandlers.length; i++) {
      state = actionHandlers[i].reducers(state, action);
    }
    return state;
  };

  return {
    ...actionCreators,
    reducers,
  };
}
