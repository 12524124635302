import React from 'react';
import styled from 'styled-components';
import Link from 'redux-first-router-link';
import { Button } from '@blueprintjs/core';

// blueprintjs buttons have margin issues
const BottomMarginButton = styled(Button)`
  margin-bottom: 1em;
`;

export const LinkButton = ({ to, disabled, ...buttonProps }) => {
  const button = <BottomMarginButton disabled={disabled} {...buttonProps} />;
  return disabled ? button : <Link to={to}>{button}</Link>;
};
