import React, { Component } from 'react';
import { Button, Checkbox } from '@blueprintjs/core';

import agreementUrl from 'gam/docs/app-distribution-agreement.md';

import './SetupDeveloper.css';

export default class DistributionAgreement extends Component {
  state = {
    agree: false,
  };

  onChangeCheckbox = (evt) => {
    this.setState({ agree: evt.target.checked });
  };

  render() {
    const { onAgreeTerms } = this.props;
    const { agree } = this.state;

    return (
      <div className="SetupDeveloper--agreement">
        <iframe
          className="SetupDeveloper--agreeFrame"
          title="Distribution Agreement"
          src={agreementUrl}
        />
        <div className="SetupDeveloper--agreeBox">
          <div className="SetupDeveloper--agreeBoxContents" id="developer-setup-distribution-wrapper">
            <Checkbox
              id="developer-setup-distribution-checkbox"
              value={agree}
              onChange={this.onChangeCheckbox}
              label="I have read and agree to the App Distribution Agreement."
            />
            <Button
              id="developer-setup-distribution-button"
              disabled={!agree} onClick={onAgreeTerms}>
              Continue
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
