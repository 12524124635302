const noop = () => {};

export class AsyncActionHandler {
  constructor({
    name,
    stateProp = name,
    defaultState = {},
    handler = noop,
    reduce = noop,
  }) {
    Object.assign(
      this,
      {
        name,
        stateProp,
        handler,
        reduce,
        _defaultState: defaultState,
      },
      AsyncActionHandler.makeEventMixins(name)
    );
  }

  actionCreator = (...actionCreatorArgs) => async (dispatch, ...args) => {
    dispatch({ type: this.start });
    try {
      const actionThunk = this.handler(...actionCreatorArgs) || noop;
      const { ...actionData } = (await actionThunk(dispatch, ...args)) || {};
      dispatch({ type: this.complete, ...actionData });
    } catch (error) {
      dispatch({ type: this.error, error });
      throw error;
    }
  };

  reducers = (state = this.defaultState, { type, error, ...actionData }) => {
    switch (type) {
      case this.start:
        return {
          ...state,
          [this.stateProp]: {
            loading: true,
            error: false,
          },
        };
      case this.complete:
        const { [this.stateProp]: actionState, ...additionalState } =
          this.reduce(state, actionData) || {};
        return {
          ...state,
          [this.stateProp]: {
            loading: false,
            error: false,
            ...actionState,
          },
          ...additionalState,
        };
      case this.error:
        return {
          ...state,
          [this.stateProp]: {
            loading: false,
            error,
          },
        };
      default:
        return state;
    }
  };

  get defaultState() {
    return {
      [this.stateProp]: {
        loading: false,
        error: false,
      },
      ...this._defaultState,
    };
  }

  static events = ['start', 'error', 'complete'];

  static makeEventMixins(name) {
    const mixins = {};

    for (const event of AsyncActionHandler.events) {
      const upperEvent = AsyncActionHandler.makeTypeUpper(name, event);
      mixins[upperEvent] = upperEvent;
      mixins[event] = upperEvent;
    }

    return mixins;
  }

  static makeTypeUpper(name, event) {
    return `${name}_${event}`.toUpperCase();
  }
}

export class ActionHandler {
  constructor({ name, defaultState = {}, handler = noop, reduce = noop }) {
    Object.assign(
      this,
      {
        name,
        handler,
        reduce,
        defaultState,
      },
      ActionHandler.makeEventMixins(name)
    );
  }

  actionCreator = (...args) => ({
    type: this.eventName,
    ...this.handler(...args),
  });

  reducers = (state = this.defaultState, { type, ...actionData }) => {
    if (type !== this.eventName) {
      return state;
    }

    const actionState = this.reduce(state, actionData) || {};
    return {
      ...state,
      ...actionState,
    };
  };

  static makeEventMixins(name) {
    const upperEvent = name.toUpperCase();
    return {
      [upperEvent]: upperEvent,
      eventName: upperEvent,
    };
  }
}
