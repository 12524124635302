// Simple constant builder
function makeStringConstants(values) {
  return Object.freeze(
    values.reduce((acc, value) => {
      acc[value] = value;
      return acc;
    }, {})
  );
}

export const PublishStatus = makeStringConstants([
  'DRAFT',
  'PRIVATE',
  'PUBLISHED',
  'RESTRICTED',
  'REMOVED',
]);

export const PaymentStatus = makeStringConstants(['UNSET', 'FREE', 'PAID']);

export const RecurrenceFrequency = makeStringConstants(['Once', 'Monthly', 'Yearly', 'Other']);

export const SpecialPaymentProvider = makeStringConstants(['None', 'Unselected', 'Other']);

export const AppType = makeStringConstants(['APP', 'CLOCK', 'TILE', 'SERVICE']);

export const AssetType = makeStringConstants([
  'GALLERY_ICON', // Standard asset type for Gallery icons
]);

export const AssetCategory = makeStringConstants(['ICON', 'SCREENSHOT']);

export const CategoryGrouping = makeStringConstants(['SYSTEM']);

export const PublicDeviceName = Object.freeze({
  HIGGS: 'Ionic',
  MESON: 'Versa',
  GEMINI: 'Versa Lite',
  MIRA: 'Versa 2',
  ANTARES: 'Charge 3',
  CENTAURI: 'Inspire',
  CENTAURI_HR: 'Inspire HR',
  CENTAURI_KIDS: 'Ace 2',
  KOSHI: 'Charge 4',
  PROXIMA: 'Inspire 2',
  PROXIMA_KIDS: 'Ace 3',
  ATLAS: 'Versa 3',
  VULCAN: 'Sense',
  MORGAN: 'Charge 5',
  BUZZ: 'Luxe',
  RHEA: 'Sense 2',
  HERA: 'Versa 4',
  NYOTA: 'Inspire 3',
});

export const platformToScreenshotPlatformMap = Object.freeze({
  HIGGS: 'IONIC',
});
