import React from 'react';
import styled from 'styled-components';

const ProgressBar = (props) => {
  const { bgcolor, completed } = props;

  const PbContainer = styled.div`
    height: 20;
    width: 100%;
    background-color: #e0e0de;
    border-radius: 50;
    margin: 50;
  `;

  const Filter = styled.div`
    height: 100%;
    width: ${completed}%;
    background-color: ${bgcolor};
    transition: width 1s ease-in-out;
    border-radius: inherit;
    text-align: right;
  `;

  const PbLabel = styled.span`
    padding: 5px;
    color: white;
    font-weight: bold;
  `;

  return (
    <PbContainer>
      <Filter>
        <PbLabel>{`${completed}%`}</PbLabel>
      </Filter>
    </PbContainer>
  );
};

export default ProgressBar;
