import { Store } from 'redux';
// @ts-ignore
import { fitbitClientId } from '../config';

// Don't allow redirecting to login urls, since it could end up in a loop
const LOGIN_URL = /^.*\/login/i;

type AuthClientOptions = {
  storageKey: string;
  storageApi: Storage;
  clientId: string;
};

type FitbitAuth = {
  init: (clientId: string) => void;
  fbUserLogOutCallback: () => void;
  fbTokenUpdatedCallback: (accessToken: string) => void;
  fbUserSessionExpired: () => void;
  fbLogUserOut: (accessToken: string) => void;
  fbIsUserAuthenticated: (
    redirect: boolean,
    redirectLocation?: string
  ) => Promise<boolean>;
  fbGetCurrentAccessToken: () => string;
  fbGetUserId: () => string;
};

declare var Fitbit: FitbitAuth;

type LoginOptions = {
  redirectUrl: string;
  dispatch: Store<any>;
};

// iOS Safari throws quota errors for localStorage in private browsing mode
function storageAvailable(): boolean {
  try {
    const storage = window.localStorage;
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
}

interface SaveSessionOptions {
  isLoggedIn: boolean;
  userId?: string;
}

type SessionAction = (options: SaveSessionOptions) => any;

const defaultStorageApi = storageAvailable()
  ? window.localStorage
  : window.sessionStorage;

export class AuthClient {
  private storageKey: string;
  private storageApi: Storage;
  private clientId: string;
  private _isLoggedIn = false;
  private _accessToken: string;
  private _userId: string;

  private _sessionAction?: SessionAction;

  private static instance: AuthClient;

  public static getInstance(): AuthClient {
    if (!AuthClient.instance) {
      AuthClient.instance = new AuthClient();
    }

    return AuthClient.instance;
  }

  private constructor(
    options: AuthClientOptions = {
      storageApi: defaultStorageApi,
      storageKey: 'auth-session',
      clientId: fitbitClientId,
    }
  ) {
    const { storageKey, storageApi, clientId } = options;
    this.storageKey = storageKey;
    this.storageApi = storageApi;
    this.clientId = clientId;

    this.loadStorage();
    // Listen to storage changes in other windows
    window.addEventListener('storage', this.handleStorageUpdate);

    Fitbit.init(fitbitClientId);
    Fitbit.fbTokenUpdatedCallback = this.handleTokenUpdate;
    Fitbit.fbUserSessionExpired = this.handleSessionExpired;
  }

  public async login(options: LoginOptions) {
    const redirectUrl = this.cleanupRedirectUrl(options.redirectUrl);
    const isAuthenticated = await Fitbit.fbIsUserAuthenticated(
      !!redirectUrl,
      redirectUrl
    );
    // Actually this will always be true in the current accounts implementation.
    // keeping this here just-in-case it gets implemented.
    if (!isAuthenticated) {
      this.logout();
      return;
    }

    if (!this._sessionAction) {
      throw new Error('session action is not defined');
    }

    this._sessionAction({
      isLoggedIn: true,
      userId: await Fitbit.fbGetUserId(),
    });

    this._isLoggedIn = true;
    const accessToken = await Fitbit.fbGetCurrentAccessToken();
    this._userId = await Fitbit.fbGetUserId();

    this._accessToken = accessToken;
    this.setStorage(accessToken);
  }

  public logout(cb?: () => void) {
    Fitbit.fbLogUserOut(this._accessToken);

    Fitbit.fbUserLogOutCallback = () => {
      this._isLoggedIn = false;

      if (this._sessionAction) {
        this._sessionAction({
          isLoggedIn: false,
          userId: undefined,
        });
      }

      this.clearStorage();

      if (cb) {
        cb();
      }
    };
  }

  private setStorage(accessToken: string) {
    this.storageApi.setItem(this.storageKey, accessToken);
  }

  private isValidRedirectUrl(redirectUrl: string) {
    if (!redirectUrl) {
      return true;
    }

    if (LOGIN_URL.test(redirectUrl)) {
      return false;
    }

    return true;
  }

  // Currently just returns redirect URL or undefined
  private cleanupRedirectUrl(redirectUrl: string) {
    if (!redirectUrl) {
      return undefined; // just to make sure it's not something weird like NaN
    }

    if (!this.isValidRedirectUrl(redirectUrl)) {
      console.error(`invalid redirect URL: ${redirectUrl}`);
      return undefined;
    }

    return redirectUrl;
  }

  private handleStorageUpdate = (evt: StorageEvent) => {
    if (evt.storageArea === this.storageApi && evt.key === this.storageKey) {
      // console.log('auth storage changed in another window');
      this.loadStorage();
    }
  };

  private loadStorage() {
    this._accessToken = this.storageApi.getItem(this.storageKey) as string;
  }

  private handleSessionExpired = () => {
    this.login({ redirectUrl: document.baseURI });
  };

  private handleTokenUpdate = (accessToken: string) => {
    this.setStorage(accessToken);
  };

  private clearStorage() {
    this.storageApi.removeItem(this.storageKey);
  }

  public get userId(): string {
    return this._userId;
  }

  public get accessToken() {
    return this._accessToken;
  }

  public set sessionAction(sessionAction: SessionAction) {
    this._sessionAction = sessionAction;
  }
}
