import React, { Component } from 'react';
import { Button, Popover } from '@blueprintjs/core';

import './ImageManager.css';

export default class ImageBox extends Component {
  handleRemove = () => {
    this.props.onRemoveImage(this.props.image);
  };

  render() {
    const { width, height, image, className, menu, dragHandle } = this.props;

    return (
      <div className={`ImageManager--box ${className || ''}`}>
        <img
          className={`ImageManager--image`}
          draggable={false}
          style={{ width, height }}
          alt={image.type.split('_')[1].toLowerCase()}
          src={image.uri}
        />
        <div className="ImageManager--actions">
          {dragHandle}
          {menu && (
            <Popover content={menu}>
              <Button className="bp3-minimal" icon="more"></Button>
            </Popover>
          )}
        </div>
      </div>
    );
  }
}
