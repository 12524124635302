import { pick } from 'lodash';

import { AsyncActionHandler } from 'lib/action-handler';
import apollo from '../apollo';
import { getUserDeveloperInfoQuery } from 'gam/queries';

import { mergeDeveloperProfiles } from 'gam/state-utils';

export default new AsyncActionHandler({
  name: 'fetch_user_info',
  defaultState: {
    userDeveloperInfo: {},
    developerProfiles: {},
    apps: {},
  },
  handler({ useCache = false } = {}) {
    return async () => {
      const {
        data: { userDeveloperInfo },
      } = await apollo.query({
        query: getUserDeveloperInfoQuery,
        fetchPolicy: useCache ? 'cache-first' : 'network-only',
      });

      return {
        userDeveloperInfo,
      };
    };
  },
  reduce(state, { userDeveloperInfo }) {
    if (!userDeveloperInfo) {
      // No profile set up
      return {
        userDeveloperInfo: {},
      };
    }

    const developerProfiles = userDeveloperInfo.developerProfiles;

    return {
      userDeveloperInfo: pick(userDeveloperInfo, [
        'id',
        'developerProfileIds',
        'devices',
        'permissions',
        'roles',
      ]),
      ...mergeDeveloperProfiles(state, developerProfiles),
    };
  },
});
