import React, { Component } from 'react';
import { connect } from 'react-redux';

import { appPublishStatusName, typeNameMap, descriptionMinLength } from 'lib/config';

import { localize } from 'lib/utils';
import {
  getAppReviewStatus,
  getActionValidity,
  getRequirementsFromActionValidity,
  checkShouldSkipBuildReview,
} from 'gam/utils';
import { SUBMIT_FOR_REVIEW, PUBLISH_TILE } from 'lib/types';

import { RequirementsPopover } from 'lib/controls/popover';

import { PromiseActionButton, PromiseActionStatusCallout } from 'lib/components/ActionStatus';
import SubmitForReviewDialog from 'gam/components/SubmitForReviewDialog';

import { AppType } from 'gam/constants';
import { publishApp } from 'gam/actions';

class PublishStatusManager extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  showSubmitDialog = () => {
    this.setState({ showingSubmitDialog: true });
  };

  hideSubmitDialog = () => {
    this.setState({ showingSubmitDialog: false });
  };

  publishApp = () => {
    this.setState({ publishPromise: this.props.onPublishApp(this.props.app) });
  };

  getAdditionalRequirements = () => {
    const { app } = this.props;
    const requirements = [];

    // Other requirements (TODO: move to server)
    // TODO: check all languages?
    if (localize(app.description).length < descriptionMinLength) {
      requirements.push({
        key: 'description',
        ok: false,
        text: `Description must be at least ${descriptionMinLength} characters`,
      });
    }

    // 'No category' (all apps/clocks) category doesn't count
    if (app.categoryIds.length <= 1) {
      requirements.push({
        key: 'category',
        ok: false,
        text: 'Need to choose a category',
      });
    }

    if (!app.contactEmail && !app.contactUrl) {
      requirements.push({
        key: 'email',
        ok: false,
        text: 'Need to fill in support email or URL',
      });
    }

    if (!localize(app.name, 'en')) {
      requirements.push({
        key: 'name',
        ok: false,
        text: `Missing app name`,
      });
    }

    return requirements;
  };

  get submissionRequirements() {
    const { app } = this.props;

    // Get missing requirements from server
    const validity = getActionValidity(app, SUBMIT_FOR_REVIEW) || {};
    const requirements = getRequirementsFromActionValidity(validity) || [];

    return requirements.concat(this.getAdditionalRequirements());
  }

  get isReadyToSubmit() {
    return !this.submissionRequirements.some((req) => !req.ok);
  }

  get publishTileRequirements() {
    const { app } = this.props;

    // Get missing requirements from server
    const validity = getActionValidity(app, PUBLISH_TILE) || {};
    const requirements = getRequirementsFromActionValidity(validity) || [];

    return requirements.concat(this.getAdditionalRequirements());
  }

  get isReadyToPublishTile() {
    return !this.publishTileRequirements.some((req) => !req.ok);
  }

  renderStatus() {
    const { app } = this.props;
    const { publishPromise } = this.state;
    const typeName = typeNameMap[app.type];

    let title = appPublishStatusName[app.publishStatus];

    const latestReviewStatus = getAppReviewStatus(app);

    let shouldSkipReview = checkShouldSkipBuildReview(this.props.developerProfile);

    let text;
    let actions;

    switch (app.publishStatus) {
      case 'DRAFT':
        text = `Upload a build to make your ${typeName} available`;
        break;
      case 'PRIVATE':
        text = `Your ${typeName} is available to users with the link to it.`;

        if (app.type === AppType.TILE) {
          actions = (
            <React.Fragment>
              <RequirementsPopover
                disabled={this.isReadyToPublishTile}
                requirements={this.publishTileRequirements}>
                <PromiseActionButton
                  disabled={!this.isReadyToPublishTile}
                  onClick={this.publishApp}
                  promise={publishPromise}
                  data-test-type="publish-button">
                  Publish {typeName} now!
                </PromiseActionButton>
              </RequirementsPopover>
              <PromiseActionStatusCallout promise={publishPromise} />
            </React.Fragment>
          );
        } else if (latestReviewStatus === 'APPROVED') {
          title = 'Ready to publish';
          text = `Your ${typeName} has been approved and is ready to publish!`;
          actions = (
            <React.Fragment>
              <PromiseActionButton
                onClick={this.publishApp}
                promise={publishPromise}
                data-test-type="publish-button">
                Publish {typeName} now!
              </PromiseActionButton>
              <PromiseActionStatusCallout promise={publishPromise} />
            </React.Fragment>
          );
        } else if (latestReviewStatus === 'NOT_SUBMITTED') {
          if (!shouldSkipReview) {
            actions = (
              <RequirementsPopover
                disabled={this.isReadyToSubmit}
                requirements={this.submissionRequirements}>
                <PromiseActionButton
                  disabled={!this.isReadyToSubmit}
                  onClick={this.showSubmitDialog}>
                  Submit for Review
                </PromiseActionButton>
              </RequirementsPopover>
            );
          } else {
            // if should skip review, display directly the Publish button
            // this would be enabled if the requirements are met
            actions = (
              <React.Fragment>
                <RequirementsPopover
                  disabled={this.isReadyToSubmit}
                  requirements={this.submissionRequirements}>
                  <PromiseActionButton
                    disabled={!this.isReadyToSubmit}
                    onClick={this.publishApp}
                    promise={publishPromise}
                    data-test-type="publish-button">
                    Publish {typeName} now!
                  </PromiseActionButton>
                </RequirementsPopover>
                <PromiseActionStatusCallout promise={publishPromise} />
              </React.Fragment>
            );
          }
        } else if (latestReviewStatus === 'WAITING_FOR_REVIEW') {
          title = 'Waiting for Review';
          text = 'Your app is waiting for review by Fitbit.';
        } else if (latestReviewStatus === 'IN_REVIEW') {
          title = 'In Review';
          text = 'Your app is being reviewed by Fitbit now.';
        }
        break;
      case 'PUBLISHED':
        text = `Your ${typeName} is available to users in the Fitbit App Gallery.`;

        if (latestReviewStatus === 'WAITING_FOR_REVIEW') {
          title = 'Update Waiting for Review';
          text = `${text} An update is pending review.`;
        } else if (latestReviewStatus === 'IN_REVIEW') {
          title = 'Update in Review';
          text = `${text} An update is being reviewed now.`;
        } else if (latestReviewStatus === 'PUBLISHED') {
          title = 'Update Ready';
          text = `A new version of your ${typeName} has been approved and is ready for publication`;
        }

        break;
      default:
      // Whoops
    }

    return (
      <div>
        <b>{title}</b>
        <p>{text}</p>
        {actions}
      </div>
    );
  }

  render() {
    const { app, className } = this.props;
    const { showingSubmitDialog } = this.state;

    return (
      <div className={className}>
        {this.renderStatus()}
        <SubmitForReviewDialog
          app={app}
          isOpen={showingSubmitDialog}
          onClose={this.hideSubmitDialog}
          onSubmit={this.handleSubmitForReview}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ gam }, { app: { developerProfileId } }) => ({
  developerProfile: developerProfileId ? gam.developerProfiles[developerProfileId] : null,
});

const mapDispatchToProps = (dispatch) => ({
  onPublishApp: (app) => dispatch(publishApp(app)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PublishStatusManager);
