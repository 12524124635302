import { AsyncActionHandler } from 'lib/action-handler';
import apollo from '../apollo';
import { submitBuildForReviewMutation } from 'gam/queries';
import { updateAppMutation } from 'gam/queries';
import { isEmpty } from 'lodash';

import { fetchApp } from 'gam/actions';

export default new AsyncActionHandler({
  name: 'submit_build_for_review',
  handler({ app, version, data, appChanges }) {
    return async (dispatch) => {
      const { developerProfileId, refId } = app;
      const { buildId } = version;

      if (!isEmpty(appChanges)) {
        await apollo.mutate({
          mutation: updateAppMutation,
          variables: {
            developerProfileId,
            refId,
            update: appChanges,
          },
        });
      }

      await apollo.mutate({
        mutation: submitBuildForReviewMutation,
        variables: {
          developerProfileId,
          refId,
          buildId,
          ...data,
        },
      });

      // Refetch app
      await dispatch(fetchApp({ developerProfileId, refId }));
    };
  },
});
