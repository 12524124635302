import { AsyncActionHandler } from 'lib/action-handler';
import { publishAppVersionMutation } from 'gam/queries';
import apollo from '../apollo';

import { fetchApp } from 'gam/actions';

export default new AsyncActionHandler({
  name: 'publish_app_version',
  defaultState: {
    apps: {},
  },
  handler(app, version) {
    return async (dispatch) => {
      const { developerProfileId, refId } = app;
      const { buildId } = version;

      await apollo.mutate({
        mutation: publishAppVersionMutation,
        variables: {
          developerProfileId,
          refId,
          buildId,
        },
      });

      await dispatch(fetchApp({ developerProfileId, refId }));
    };
  },
});
