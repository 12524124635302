import { AsyncActionHandler } from 'lib/action-handler';
import apollo from '../apollo';
import { createChangeCategoriesRequestMutation } from 'gam/queries';
import { fetchApp } from 'gam/actions';

export default new AsyncActionHandler({
  name: 'create_change_categories_request',
  handler(app, { categoryIds, comments } = {}) {
    return async (dispatch) => {
      const { developerProfileId, refId } = app;

      await apollo.mutate({
        mutation: createChangeCategoriesRequestMutation,
        variables: {
          developerProfileId,
          refId,
          comments,
          categoryIds,
        },
      });

      // Reload app
      await dispatch(fetchApp({ developerProfileId, refId }));
    };
  },
});
