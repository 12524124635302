import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Overlay, Classes } from '@blueprintjs/core';
import { updateDeviceChannelEnrollment } from 'gam/actions';

import './PreviewEnrollment.css';

const enrollModalProps = {
  title: 'Enroll in Preview Firmware',
  body: (
    <div>
      <p>
        By enrolling into the preview firmware program you will see pre-released
        features.
      </p>
      <p>You will be prompted to update your device in the mobile app.</p>
    </div>
  ),
  confirmButtonText: 'Confirm Enroll',
};

const unenrollModalProps = {
  title: 'Unenroll from Preview Firmware',
  body: (
    <div>
      <p>
        By unenrolling your device from the preview firmware channel, you will
        be going back to the release firmware.
      </p>
      <p>To finalize unenrollment you need to factory reset your device.</p>
    </div>
  ),
  confirmButtonText: 'Confirm Unenroll',
};

const ConfirmModal = ({
  title,
  body,
  onCancel,
  onConfirm,
  confirmButtonText,
}) => (
  <div
    className={[
      Classes.CARD,
      Classes.ELEVATION_4,
      Classes.OVERLAY_CONTENT,
      'PreviewEnrollment--overlay',
    ].join(' ')}
  >
    <h4>{title}</h4>
    <div>{body}</div>
    <Button onClick={onCancel} className="margin-right">
      Cancel
    </Button>
    <Button onClick={onConfirm}>{confirmButtonText}</Button>
  </div>
);

class PreviewEnrollment extends Component {
  state = {
    shouldShowModal: false,
    deviceType: null,
    selectedChannelId: null,
  };

  renderDeviceRows() {
    const { devices } = this.props;
    return devices.map((device) => {
      const { isRelease } = device.firmwareChannelInfo.current;
      return (
        <tr key={device.type}>
          <td>{device.productName}</td>
          <td>
            Currently running {device.firmwareChannelInfo.current.name} firmware
          </td>
          <td>
            {isRelease ? (
              <select
                className="PreviewEnrollment--dropdown"
                value="release"
                onChange={({ target: { value } }) =>
                  this.showConfirmModal(device.type, value)
                }
              >
                <option value="release">Choose a firmware channel...</option>
                {device.firmwareChannelInfo.available
                  .filter(({ id }) => id !== 'release')
                  .map(({ id, name }) => (
                    <option value={id} key={id}>
                      {name}
                    </option>
                  ))}
              </select>
            ) : (
              <Button
                onClick={() => this.showConfirmModal(device.type, 'release')}
              >
                Roll back to release
              </Button>
            )}
          </td>
        </tr>
      );
    });
  }

  showConfirmModal = (deviceType, selectedChannelId) => {
    this.setState({ shouldShowModal: true, deviceType, selectedChannelId });
  };

  hideConfirmModal = () => {
    this.setState({
      shouldShowModal: false,
      deviceType: null,
      selectedChannelId: null,
    });
  };

  handleEnroll(deviceType, selectedChannelId) {
    this.props.onChannelEnrollment({ deviceType, selectedChannelId });
    this.hideConfirmModal();
  }

  renderConfirmModal() {
    const { devices } = this.props;
    const { deviceType, selectedChannelId } = this.state;
    const device = devices.find(({ type }) => type === deviceType);

    if (!device) {
      return;
    }

    const isRelease = selectedChannelId === 'release';

    return (
      <ConfirmModal
        {...(isRelease ? unenrollModalProps : enrollModalProps)}
        onCancel={this.hideConfirmModal}
        onConfirm={() =>
          this.handleEnroll(
            deviceType,
            isRelease ? 'release' : selectedChannelId
          )
        }
      />
    );
  }

  render() {
    const { shouldShowModal } = this.state;

    return (
      <div className="PreviewEnrollment">
        <h3>Preview Firmware Enrollment</h3>
        <div>
          Use this page to manage your devices enrollment in firmware channels.
          Any devices that are eligible for preview enrollment will be listed
          below.
        </div>
        <table className="bp3-html-table PreviewEnrollment--table">
          <tbody>{this.renderDeviceRows()}</tbody>
        </table>
        <Overlay
          className={Classes.OVERLAY_SCROLL_CONTAINER}
          isOpen={shouldShowModal}
          onClose={this.hideConfirmModal}
        >
          {this.renderConfirmModal()}
        </Overlay>
      </div>
    );
  }
}

const mapStateToProps = ({ gam }) => ({
  devices: gam.userDeveloperInfo.devices,
});

const mapDispatchToProps = (dispatch) => ({
  onChannelEnrollment: (options) =>
    dispatch(updateDeviceChannelEnrollment(options)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PreviewEnrollment);
