import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual, chain, map, find } from 'lodash';
import { Position, Tooltip } from '@blueprintjs/core';
import { MarginTop } from 'lib/controls/layout';
import { PromiseActionButton } from 'lib/components/ActionStatus';
import { localize } from 'lib/utils';
import { getAppCategories } from 'gam/state-utils';
import { CategoryGrouping } from 'gam/constants';

import { updateAppCategories } from 'gam/actions';

const SelectCategory = ({ categories, selectedCategoryId, disabled, onChange }) => (
  <div className="bp3-select">
    <select
      value={selectedCategoryId}
      disabled={disabled}
      onChange={onChange}
      data-test-type="categories-select-dropdown">
      <option value=""> - No category -</option>
      {map(categories, ({ id, name }) => (
        <option key={id} value={id} name={localize(name)}>
          {localize(name)}
        </option>
      ))}
    </select>
  </div>
);

class CategoryEditor extends Component {
  state = this.getInitialState(this.props);

  componentWillReceiveProps(nextProps) {
    // Check if app categoryIds has changed
    if (!isEqual(nextProps.app.categoryIds, this.props.app.categoryIds)) {
      this.setState(this.getInitialState(nextProps));
    }
  }

  getInitialState(props) {
    const { app, categories } = props;

    // Get first non-sytem category
    const categoryId = find(
      app.categoryIds,
      (id) => categories[id] && categories[id].grouping !== CategoryGrouping.SYSTEM
    );

    return {
      existingCategoryId: categoryId,
      selectedCategoryId: categoryId,
    };
  }

  handleSelectCategory = (evt) => {
    this.setState({
      selectedCategoryId: evt.target.value,
    });
  };

  getSelectedCategory(categories, selectedCategoryId) {
    let res = undefined;
    for (let id in categories) {
      if (id === selectedCategoryId) {
        res = categories[id];
        break;
      }
    }
    return res;
  }

  handleSaveCategory = () => {
    const { app } = this.props;
    const { existingCategoryId, selectedCategoryId } = this.state;

    // Remove old category id, add new one; leave rest alone
    const categoryIds = chain(app.categoryIds)
      .without(existingCategoryId)
      .union(selectedCategoryId && [selectedCategoryId])
      .value();

    const promise = this.props.onUpdateAppCategories(app, categoryIds);
    this.setState({ promise });
  };

  get editable() {
    const { editable } = this.props;
    const { existingCategoryId } = this.state;

    return editable || !existingCategoryId;
  }

  render() {
    const { categories } = this.props;
    const { existingCategoryId, selectedCategoryId, promise } = this.state;

    const categoryUpdated = existingCategoryId !== selectedCategoryId;
    const selectedCategory = this.getSelectedCategory(categories, selectedCategoryId);
    const description = selectedCategory ? localize(selectedCategory.description) : undefined;

    return (
      <div>
        <h3>Categories</h3>
        <div>
          <Tooltip disabled={!description} content={description} position={Position.RIGHT}>
            <SelectCategory
              disabled={!this.editable}
              categories={categories}
              selectedCategoryId={selectedCategoryId}
              onChange={this.handleSelectCategory}
            />
          </Tooltip>
        </div>
        <MarginTop>
          {this.editable && (
            <PromiseActionButton
              onClick={this.handleSaveCategory}
              data-test-type="update-catagory-button"
              promise={promise}
              disabled={!categoryUpdated}>
              Update category
            </PromiseActionButton>
          )}
        </MarginTop>
      </div>
    );
  }
}

const mapStateToProps = (state, { includeRestricted }) => ({
  categories: getAppCategories(state, { includeRestricted }),
});

const mapDispatchToProps = (dispatch) => ({
  onUpdateAppCategories: (app, categoryIds) => dispatch(updateAppCategories(app, categoryIds)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryEditor);
