import { AsyncActionHandler } from 'lib/action-handler';
import { setupNewDeveloperMutation } from 'gam/queries';
import apollo from '../apollo';

import { fetchUserInfo } from 'gam/actions';

export default new AsyncActionHandler({
  name: 'setup_new_developer',
  stateProp: 'new_developer',
  defaultState: {
    developerProfiles: {},
  },
  handler({ name, signedAgreements }) {
    return async (dispatch) => {
      await apollo.mutate({
        mutation: setupNewDeveloperMutation,
        variables: {
          name,
          signedAgreements,
        },
      });

      await dispatch(fetchUserInfo());
    };
  },
});
