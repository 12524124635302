import React from 'react';
import { WideFormGroup } from 'lib/controls/form';
import StateDataManager from 'gam/components/StateDataManager';
import { supportedLanguages, appNameMaxLength } from 'lib/config';
import { hasScreenshotSupport } from 'gam/utils';
import styled from 'styled-components';

import AppScreenshots from 'gam/components/AppScreenshots';

const LearnMoreContainer = styled.div`
  margin-top: 1em;
`;

export default class LocalizedDetailsPanel extends StateDataManager {
  handleChangeName = (event) => {
    this.handleChangeEvent('name', event);
  };

  handleChangeDescription = (event) => {
    this.handleChangeEvent('description', event);
  };

  handleChangeLearnMore = (event) => {
    this.handleChangeEvent('learnMore', event);
  };

  handleChangeLearnMoreDeeplink = (event) => {
    this.handleChangeEvent('learnMoreDeeplink', event);
  };

  handleChangeScreenshots = (assets) => {
    this.handleChangeValue('screenshots', assets);
  };

  renderScreenshotOverview() {
    const { lang, defaultLang } = this.props;
    return (
      <span>
        Screenshots are shown at the top of your app's gallery page. You must include a screenshot
        for each device supported in the latest build.
        {lang !== defaultLang && (
          <span>
            {' '}
            It will use screenshots from the <b>{supportedLanguages[defaultLang]}</b> localization
            if no images are added here.
          </span>
        )}
      </span>
    );
  }

  renderScreenshots() {
    const { app, screenshotsEditDisabled, editable } = this.props;
    const { data } = this.state;

    const isSupported = hasScreenshotSupport(app);

    return (
      <div className="AppDetailsEditor--screenshotSection">
        <h4>Screenshots</h4>
        <p className="AppDetailsEditor--screenshotOverview">
          {isSupported
            ? this.renderScreenshotOverview()
            : 'Upload a version for screenshot support.'}
        </p>
        <AppScreenshots
          app={app}
          editable={!screenshotsEditDisabled && editable}
          images={data.screenshots || []}
          onChange={this.handleChangeScreenshots}
        />
        {screenshotsEditDisabled && (
          <div className="bp3-callout bp3-intent-warning">
            <small>Screenshots in review. They will be changed once our team approves them.</small>
          </div>
        )}
      </div>
    );
  }

  render() {
    const {
      editable,
      nameEditable,
      existingNamesEditable,
      lang,
      descriptionEditDisabled,
      admin,
    } = this.props;
    const { data } = this.state;

    const editWarning = nameEditable && !existingNamesEditable && (
      <span className="AppDetailsEditor--nameEditWarning">
        Once you save, this name will no longer be editable.
      </span>
    );

    return (
      <div className="AppDetailsEditor bp3-card">
        <div className="AppDetailsEditor--formSection">
          <WideFormGroup>
            <label className="bp3-label">Name {editWarning}</label>
            <input
              className="bp3-input"
              lang={lang}
              value={data.name || ''}
              maxLength={appNameMaxLength}
              readOnly={!nameEditable}
              onChange={this.handleChangeName}
              placeholder="Name of app"
              data-test-type="app-name-field"
            />
          </WideFormGroup>
          <div className="AppDetailsEditor--description">
            <label className="bp3-label">Description</label>
            <textarea
              className="bp3-input bp3-fill"
              maxLength={3000}
              readOnly={!editable}
              lang={lang}
              value={data.description || ''}
              onChange={this.handleChangeDescription}
              placeholder="Description of app"
              data-test-type="app-description-field"
              disabled={descriptionEditDisabled}
            />
            {descriptionEditDisabled && (
              <div className="bp3-callout bp3-intent-warning">
                <small>Description in review. It will be applied once our team approves it.</small>
              </div>
            )}
          </div>
          {admin ? (
            <div>
              <LearnMoreContainer>
                <WideFormGroup>
                  <label className="bp3-label">Learn more text</label>
                  <input
                    className="bp3-input"
                    lang={lang}
                    value={data.learnMore || ''}
                    maxLength={2000}
                    readOnly={!editable}
                    onChange={this.handleChangeLearnMore}
                    placeholder="Learn more text"
                    data-test-type="app-learn-more-text-field"
                  />
                </WideFormGroup>
              </LearnMoreContainer>
              <LearnMoreContainer>
                <WideFormGroup>
                  <label className="bp3-label">Learn more deeplink</label>
                  <input
                    className="bp3-input"
                    lang={lang}
                    value={data.learnMoreDeeplink || ''}
                    maxLength={2000}
                    readOnly={!editable}
                    onChange={this.handleChangeLearnMoreDeeplink}
                    placeholder="Please add the deeplink here"
                    data-test-type="app-learn-more-deeplink-field"
                  />
                </WideFormGroup>
              </LearnMoreContainer>
            </div>
          ) : null}
        </div>
        {this.renderScreenshots()}
      </div>
    );
  }
}
