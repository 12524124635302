import React, { useState, useEffect, useCallback } from 'react';
import { Icon, Button, Tabs, Tab, Menu, MenuItem } from '@blueprintjs/core';
import { without, groupBy, noop } from 'lodash';
import styled from 'styled-components';
import { useField, useFormState } from 'react-final-form';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import arrayMove from 'array-move';

import { AssetCategory } from 'gam/constants';
import { SimpleForm } from 'lib/controls/form';
import {
  ImageBox,
  ImagePlaceholderBox,
  ImageUploadDialog,
} from 'gam/components/ImageManager';

import './AppScreenshots.css';
import TabsWrapper from 'lib/ui/tabs';

// Manages screenshots for a single asset type
const TypeScreenshots = ({ app, editable, assetTypeInfo, common }) => {
  const { editorTitle, baseType } = assetTypeInfo;

  return (
    <div>
      {baseType && (
        <p>
          If you have functionality or visuals that are specific to{' '}
          <b>{editorTitle}</b>, add device-specific screenshots here to override
          the ones from the <i>Common</i> tab.
        </p>
      )}
      {common && (
        <p>
          Add screenshots here for <b>{editorTitle}</b>.
        </p>
      )}
      <ScreenshotListFields
        name={`screenshotsByType.${assetTypeInfo.type}`}
        app={app}
        editable={editable}
        assetTypeInfo={assetTypeInfo}
      />
    </div>
  );
};

// Manages screenshots for a base asset type
const BaseTypeScreenshots = ({ app, editable, assetTypeInfo }) => {
  const props = { app, editable };
  const { type, editorTitle } = assetTypeInfo;

  const subTypeInfos = app.supportedAssetTypes.filter(
    ({ baseType }) => baseType === type
  );

  if (subTypeInfos.length > 0) {
    // Show tabs with Common + the sub types
    return (
      <TabsWrapper defaultSelectedTabId="common" animate={false}>
        <Tab
          id="common"
          title="Common"
          panel={
            <TypeScreenshots common assetTypeInfo={assetTypeInfo} {...props} />
          }
        />
        {subTypeInfos.map((subTypeInfo) => (
          <Tab
            id={subTypeInfo.type}
            key={subTypeInfo.type}
            title={subTypeInfo.editorTitle}
            panel={<TypeScreenshots assetTypeInfo={subTypeInfo} {...props} />}
          />
        ))}
      </TabsWrapper>
    );
  } else {
    return (
      <Tabs defaultSelectedTabId="common" animate={false}>
        <Tab
          id="common"
          title={editorTitle}
          panel={
            <TypeScreenshots common assetTypeInfo={assetTypeInfo} {...props} />
          }
        />
      </Tabs>
    );
  }
};

const SortableScreenshotList = SortableContainer(({ children }) => (
  <div className="AppScreenshots--list">{children}</div>
));

const SortableScreenshot = SortableElement(({ children }) => (
  <div className="AppScreenshots--padding">{children}</div>
));

const DragHandle = SortableHandle(() => <Button minimal icon="move" />);

const ScreenshotListFields = ({ app, editable, name, assetTypeInfo }) => {
  const [showingImagePicker, setShowingImagePicker] = useState();
  const {
    input: { value, onChange },
  } = useField(name);

  const images = value || [];

  const showImagePicker = useCallback(() => setShowingImagePicker(true), [
    setShowingImagePicker,
  ]);

  const hideImagePicker = useCallback(() => setShowingImagePicker(false), [
    setShowingImagePicker,
  ]);

  const handleSelectAsset = useCallback(
    (asset) => {
      onChange([...images, asset]);

      hideImagePicker();
    },
    [onChange, images, hideImagePicker]
  );

  const renderImageMenu = useCallback(
    (asset) => {
      const removeImage = () => {
        onChange(without(images, asset));
      };

      return (
        <Menu>
          <MenuItem text="Remove image" icon="cross" onClick={removeImage} />
        </Menu>
      );
    },
    [onChange, images]
  );

  const handleSortEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      const updatedImages = arrayMove(images, oldIndex, newIndex);
      onChange(updatedImages);
    },
    [onChange, images]
  );

  // End of hooks

  const { type: assetType } = assetTypeInfo;

  const maxImages = 5;
  const remaining = maxImages - images.length;
  const canAddImages = editable && remaining > 0;

  return (
    <SortableScreenshotList axis="xy" useDragHandle onSortEnd={handleSortEnd}>
      {images &&
        images.map(
          (image, index) =>
            image.type === assetType && (
              <SortableScreenshot
                key={image.id}
                index={index}
                disabled={!editable}
              >
                <ImageBox
                  className="AppScreenshots--banner"
                  width={assetTypeInfo.width / 2}
                  height={assetTypeInfo.height / 2}
                  image={image}
                  menu={editable && renderImageMenu(image)}
                  dragHandle={editable && <DragHandle />}
                />
              </SortableScreenshot>
            )
        )}
      {canAddImages && (
        <div className="AppScreenshots--padding">
          <ImagePlaceholderBox
            width={assetTypeInfo.width / 2}
            height={assetTypeInfo.height / 2}
            onClick={showImagePicker}
          >
            <Icon icon="plus" />
            {remaining > 0 ? (
              <span>Add up to {remaining} more</span>
            ) : (
              <span>
                Add up to {remaining} {remaining > 1 ? 'images' : 'image'}
              </span>
            )}
          </ImagePlaceholderBox>
        </div>
      )}
      {canAddImages && (
        <ImageUploadDialog
          app={app}
          assetTypeInfo={assetTypeInfo}
          onSelectAsset={handleSelectAsset}
          isOpen={showingImagePicker}
          onClose={hideImagePicker}
        />
      )}
    </SortableScreenshotList>
  );
};

const StyledTabs2 = styled(Tabs)`
  margin: 0 1em;
`;

// Manages the app screenshots for a language
const AppScreenshotsFields = ({ app, editable }) => {
  const assetTypeInfos = app.supportedAssetTypes;

  return (
    <StyledTabs2 large>
      {assetTypeInfos
        .filter(
          ({ category, baseType }) =>
            category === AssetCategory.SCREENSHOT && !baseType
        )
        .map((assetTypeInfo) => {
          const { type, width, height } = assetTypeInfo;

          return (
            <Tab
              id={type}
              key={type}
              title={`${width}x${height}`}
              panel={
                <BaseTypeScreenshots
                  assetTypeInfo={assetTypeInfo}
                  {...{ app, editable }}
                />
              }
            />
          );
        })}
    </StyledTabs2>
  );
};

// Adapter to convert form events into onChange
// Remove this once app editor becomes a proper Form
const AppScreenshotsEditor = ({ onChange, ...props }) => {
  // Subscribe to changes to dirty/values
  const { dirty, values } = useFormState({
    subscription: {
      dirty: true,
      values: true,
    },
  });

  useEffect(() => {
    if (dirty) {
      let allScreenshots = [];
      const { screenshotsByType } = values;

      // Flatten screenshotsByType
      for (const imageAssets of Object.values(screenshotsByType)) {
        allScreenshots = [...allScreenshots, ...imageAssets];
      }

      onChange(allScreenshots);
    }
  }, [dirty, values, onChange]);

  return <AppScreenshotsFields {...props} />;
};

const AppScreenshots = ({ app, images, editable, onChange }) => {
  const initialValues = {
    screenshotsByType: groupBy(images, 'type'),
  };

  return (
    <SimpleForm initialValues={initialValues} onSubmit={noop}>
      <AppScreenshotsEditor {...{ app, editable, onChange }} />
    </SimpleForm>
  );
};

export default AppScreenshots;
