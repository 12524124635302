import { AsyncActionHandler } from 'lib/action-handler';
import { getAppQuery } from 'gam/queries';
import apollo from '../apollo';
import { mergeApps } from 'gam/state-utils';

export default new AsyncActionHandler({
  name: 'fetch_app',
  stateProp: 'currentApp',
  defaultState: {
    apps: {},
    currentApp: {},
  },
  handler({ developerProfileId, refId }) {
    return async () => {
      const { data: response } = await apollo.query({
        query: getAppQuery,
        variables: { developerProfileId, refId },
        fetchPolicy: 'network-only',
      });

      return {
        entries: [{ ...response.app }],
      };
    };
  },
  reduce(state, { entries }) {
    return {
      ...mergeApps(state, entries),
      currentAppRefId: entries[0].refId,
    };
  },
});
