import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { updateAppAccessKey } from 'gam/actions';
import { Button } from '@blueprintjs/core';
import { PromiseActionButton } from 'lib/components/ActionStatus';
import { galleryUrl } from 'lib/config';

const PageSectionWrapper = styled.div`
  margin: 1rem 0;
`;

const ActionButtonsWrapper = styled.div`
  display: flex;
  margin: 0.5rem 0 0;
`;

const buildGalleryAppURL = (id, accessKey) => `${galleryUrl}/details/${id}?key=${accessKey}`;

const AccessKeyEditor = ({ app, app: { id, privateLinkAccessKey }, onUpdateAppAccessKey }) => {
  const [updateAppPromise, setUpdateAppPromise] = useState();

  const handleGenerateAccessKey = () => {
    setUpdateAppPromise(onUpdateAppAccessKey(false));
  };

  const handleClearAccessKey = () => {
    setUpdateAppPromise(onUpdateAppAccessKey(true));
  };

  const handleCopyURLToClipboard = () => {
    navigator.clipboard.writeText(buildGalleryAppURL(id, privateLinkAccessKey));
  };

  const renderNoKey = () => (
    <div>To add additional security to your private GAM link, please generate a security key.</div>
  );

  const renderKeyDetails = () => (
    <div>
      Your access key is: {privateLinkAccessKey}
      <Button icon="duplicate" onClick={handleCopyURLToClipboard}>
        Copy URL
      </Button>
    </div>
  );

  return (
    <PageSectionWrapper>
      <h3>Private link access key</h3>
      {privateLinkAccessKey ? renderKeyDetails() : renderNoKey()}
      <ActionButtonsWrapper>
        <PromiseActionButton onClick={handleGenerateAccessKey} promise={updateAppPromise}>
          {app.privateLinkAccessKey ? 'Regenerate key' : 'Generate key'}
        </PromiseActionButton>
        {app.privateLinkAccessKey && (
          <PromiseActionButton onClick={handleClearAccessKey} promise={updateAppPromise}>
            Clear key
          </PromiseActionButton>
        )}
      </ActionButtonsWrapper>
    </PageSectionWrapper>
  );
};

const mapDispatchToProps = (dispatch, { app }) => ({
  onUpdateAppAccessKey: (clearAccessKey) => dispatch(updateAppAccessKey(app, clearAccessKey)),
});

export default connect(null, mapDispatchToProps)(AccessKeyEditor);
