export const CREATE_APP = 'CREATE_APP';
export const CREATE_SERVICE = 'CREATE_SERVICE';
export const CREATE_TILES = 'CREATE_TILES';
export const SKIP_BUILD_REVIEW = 'SKIP_BUILD_REVIEW';
export const SUBMIT_CHANGE_REQUEST = 'SUBMIT_CHANGE_REQUEST';
export const SUBMIT_FOR_REVIEW = 'SUBMIT_FOR_REVIEW';
export const PUBLISH_TILE = 'PUBLISH_TILE';
export const USE_DUPLICATE_APP_NAME = 'USE_DUPLICATE_APP_NAME';
export const BLOCK_CREATE_APP = 'BLOCK_CREATE_APP';
export const BLOCK_UPLOAD_NEW_VERSION = 'BLOCK_UPLOAD_NEW_VERSION';
export const ALLOW_LIST_APPS_FOR_RHEA_HERA = 'ALLOW_LIST_APPS_FOR_RHEA_HERA';
export const IS_PRIMARY_DEVELOPER = 'IS_PRIMARY_DEVELOPER';
export const IS_SECONDARY_DEVELOPER = 'IS_SECONDARY_DEVELOPER';
