export function createAsyncThunkMiddleware(extraArgument) {
  return ({ dispatch, getState }) => (next) => (action) => {
    if (typeof action === 'function') {
      if (Object.getPrototypeOf(action).constructor.name === 'AsyncFunction') {
        return (async function () {
          await action(dispatch, getState, extraArgument);
        })();
      }

      return action(dispatch, getState, extraArgument);
    }

    return next(action);
  };
}

export default createAsyncThunkMiddleware();
