import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dialog, Icon } from '@blueprintjs/core';
import { readAsDataURL } from 'promise-file-reader';
import { max } from 'lodash';

import { Asset } from 'gam/models/Assets';
import ImagePlaceholderBox from './ImagePlaceholderBox';
import { PromiseActionButton } from 'lib/components/ActionStatus';

import { uploadAppAsset } from 'gam/actions';
import { maxImageBytes } from 'lib/config';

import './ImageManager.css';

export class ImageUploadDialog extends Component {
  state = {
    image: null,
    asset: null,
    uploadPromise: null,
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.isOpen !== nextProps.isOpen) {
      this.setState({
        image: null,
        asset: null,
        uploadPromise: null,
      });
    }

    const { image } = this.state;

    if (image && image.uploadId) {
      const asset = nextProps.uploadedAssets[image.uploadId];
      if (asset) {
        this.setState({ asset: new Asset(asset) });
      }
    }
  }

  get dimensions() {
    return this.props.assetTypeInfo;
  }

  get scaleFactors() {
    return this.dimensions.scaleFactors || [1.0];
  }

  // Load file into memory as "image" object
  async loadImage(file) {
    const dataUri = await readAsDataURL(file);

    return {
      file: file,
      uri: dataUri,

      // Temporary id that we can associate after image is uploaded
      uploadId: Math.random(),
    };
  }

  loadFiles(files) {
    if (files.length < 1) {
      return;
    }

    this.loadImage(files[0]).then((image) => {
      this.setState({
        image: image,
        asset: null,
        uploadPromise: null,
      });
    });
  }

  handleDragOver = (evt) => {
    evt.preventDefault();
  };

  handleDropFiles = (evt) => {
    evt.preventDefault();
    const dt = evt.dataTransfer;

    if (dt) {
      this.loadFiles(dt.files);
    }
  };

  handleSelectFiles = (evt) => {
    this.loadFiles(evt.target.files);
  };

  handleConfirm = () => {
    this.props.onSelectAsset(this.state.asset);
  };

  handlePlaceholderClick = () => {
    // Trigger click event on file input
    this.fileInputRef.click();
  };

  imageLoaded = () => {
    this.forceUpdate();

    if (this.isValidImage) {
      this.startUpload();
    }
  };

  startUpload() {
    const { app, assetTypeInfo } = this.props;
    const { image, uploadPromise } = this.state;

    if (uploadPromise) {
      console.log('already uploading');
      return;
    }

    const promise = this.props.onUploadImage(app, {
      type: assetTypeInfo.type,
      file: image.file,
      uploadId: image.uploadId,
    });

    this.setState({ uploadPromise: promise });
  }

  get isValidImage() {
    return this.selectedImageBytes.valid && this.selectedImageScale.valid;
  }

  get selectedImageBytes() {
    const image = this.state.image;
    const file = image && image.file;

    // Check file size (if available)
    if (file && file.size > maxImageBytes) {
      return {
        valid: false,
        warning:
          `Image is ${Math.ceil(file.size / 1024)} KB ` +
          `but max allowed is ${Math.ceil(maxImageBytes / 1024)} KB`,
      };
    }

    return {
      valid: true,
    };
  }

  get selectedImageScale() {
    if (!this.imageRef) {
      return { valid: false };
    }

    const imageHeight = this.imageRef.naturalHeight;
    const imageWidth = this.imageRef.naturalWidth;
    const { width, height } = this.dimensions;

    for (const factor of this.scaleFactors) {
      if (imageWidth === factor * width && imageHeight === factor * height) {
        return {
          valid: true,
          scaleFactor: factor,
        };
      }
    }

    return { valid: false };
  }

  factorSizeText = (factor) => {
    const { width, height } = this.dimensions;
    return `${width * factor}x${height * factor}`;
  };

  get imageSizeText() {
    return [...this.scaleFactors].reverse().map(this.factorSizeText).join(' or ');
  }

  renderImageBytes() {
    const { valid, warning } = this.selectedImageBytes;

    if (!valid) {
      return (
        <div>
          <Icon className="bp3-intent-danger" icon="cross" />
          {warning}
        </div>
      );
    }

    return null;
  }

  renderImageSize() {
    if (!this.imageRef) {
      return '';
    }

    const imageHeight = this.imageRef.naturalHeight;
    const imageWidth = this.imageRef.naturalWidth;

    const bestScaleFactor = max(this.scaleFactors);

    let icon = <Icon className="bp3-intent-danger" icon="cross" />;

    const { valid, scaleFactor } = this.selectedImageScale;
    let warning;

    if (!valid) {
      icon = <Icon className="bp3-intent-danger" icon="cross" />;
      warning = <div>Expected: {this.imageSizeText}</div>;
    } else if (scaleFactor < bestScaleFactor) {
      icon = <Icon className="bp3-intent-warning" icon="warning-sign" />;
      warning = <div>Please use {this.factorSizeText(bestScaleFactor)} instead</div>;
    } else {
      icon = <Icon className="bp3-intent-success" icon="tick" />;
    }

    return (
      <div>
        <div>
          {icon} Dimensions: {imageWidth}x{imageHeight}
        </div>
        {warning}
      </div>
    );
  }

  render() {
    const { isOpen, onClose, confirmText = 'Use image' } = this.props;
    const { image, asset, uploadPromise } = this.state;

    const dimensions = this.dimensions;

    const imageStyle = {
      maxWidth: dimensions.width,
      maxHeight: dimensions.height,
    };

    return (
      <Dialog className="ImageManager--uploadDialog" isOpen={isOpen} onClose={onClose}>
        <div onDragOver={this.handleDragOver} onDrop={this.handleDropFiles}>
          <label className="bp3-file-input ImageManager--filePicker">
            <input
              id="image-upload-input"
              ref={(ref) => {
                this.fileInputRef = ref;
              }}
              type="file"
              accept=".png,.jpg"
              value={''}
              onChange={this.handleSelectFiles}
            />
            <span className="bp3-file-upload-input">
              {image ? image.file.name : 'Upload PNG or JPEG file...'}
            </span>
          </label>
          <div className="ImageManager--previews">
            {image ? (
              <img
                ref={(ref) => {
                  this.imageRef = ref;
                }}
                onLoad={this.imageLoaded}
                alt="Preview"
                src={image.uri}
                style={imageStyle}
              />
            ) : (
              <ImagePlaceholderBox
                width={dimensions.width}
                height={dimensions.height}
                onClick={this.handlePlaceholderClick}>
                {this.imageSizeText}
              </ImagePlaceholderBox>
            )}
          </div>
          <div className="ImageManager--sizeInfo">
            {this.renderImageSize()}
            {this.renderImageBytes()}
          </div>
          <PromiseActionButton
            id="image-upload-confirm-btn"
            disabled={!asset}
            promise={uploadPromise}
            onClick={this.handleConfirm}>
            {confirmText}
          </PromiseActionButton>
        </div>
      </Dialog>
    );
  }
}

ImageUploadDialog.propTypes = {
  app: PropTypes.object.isRequired,
  assetTypeInfo: PropTypes.any.isRequired,
  onSelectAsset: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

const mapStateToProps = ({ gam }) => ({
  uploadedAssets: gam.uploadedAssets,
});

const mapDispatchToProps = (dispatch) => ({
  onUploadImage: (app, options) => dispatch(uploadAppAsset(app, options)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageUploadDialog);
