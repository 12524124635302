import { ContextSetter, setContext } from 'apollo-link-context';
import { AuthClient } from './auth-client';

// Add authorization to context
const getDeviceContext: ContextSetter = async (request, previousContext) => {
  const accessToken = AuthClient.getInstance().accessToken;

  if (!accessToken) {
    throw new Error('no accessToken');
  }

  return {
    headers: {
      ...previousContext.headers,
      Authorization: `Bearer ${accessToken}`,
    },
  };
};

export const createAuthLink = () => setContext(getDeviceContext);
