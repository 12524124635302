import { mapValues, noop } from 'lodash';
import { compose } from 'redux';

export function getBasename(): string {
  try {
    return new URL(document.baseURI).pathname;
  } catch (e) {
    console.error(e);
    return '/';
  }
}

// Process extended route config, returns routesMap and type names
// Currently supports adding middleware (route level and all routes)
// that gets composed into thunks.
export function prepareRoutes(routes, options = {}) {
  const { middleware: globalMiddleware = [], defaults } = options;
  const types = {};

  const routesMap = mapValues(routes, (route, type) => {
    types[type] = type;

    const { thunk, middleware: routeMiddleware = [], ...rest } = route;

    const middleware = [...globalMiddleware, ...routeMiddleware];
    const composedThunk =
      middleware.length > 0 ? compose(...middleware)(thunk || noop) : thunk;

    return {
      ...defaults,
      ...rest,
      thunk: composedThunk,
    };
  });

  return {
    routesMap,
    types,
  };
}

const ROUTE_LOAD_START = 'ROUTE_LOAD_START';
const ROUTE_LOAD_COMPLETE = 'ROUTE_LOAD_COMPLETE';
const ROUTE_LOAD_ERROR = 'ROUTE_LOAD_ERROR';

// Route thunk middleware that tracks when a route starts/completes
// running its thunk
export function trackRouteStates(thunk) {
  return async (dispatch, getState) => {
    const location = getState().location;

    dispatch({ type: ROUTE_LOAD_START, location });

    try {
      await dispatch(thunk, getState);
    } catch (e) {
      dispatch({ type: ROUTE_LOAD_ERROR, location, error: e });
      return;
    }

    dispatch({ type: ROUTE_LOAD_COMPLETE, location });
  };
}

// Reducer that updates the "routes" state with the status of routes
export function routeStateReducer(state = {}, action) {
  let update;

  switch (action.type) {
    case ROUTE_LOAD_START:
      update = { ready: false, error: null };
      break;
    case ROUTE_LOAD_COMPLETE:
      update = { ready: true, error: null };
      break;
    case ROUTE_LOAD_ERROR:
      update = { ready: false, error: action.error };
      break;
    default:
    // do nothing
  }

  if (!update) {
    return state;
  }

  const locationType = action.location.type;

  return {
    ...state,
    [locationType]: {
      ...state[locationType],
      ...update,
    },
  };
}
