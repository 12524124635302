import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dialog } from '@blueprintjs/core';

import { localize } from 'lib/utils';
import {
  typeNameMap,
  guidelinesUrl,
  distributionAgreementUrl,
} from 'lib/config';

import { MarginTop } from 'lib/controls/layout';
import {
  PromiseActionButton,
  PromiseActionStatusCallout,
} from 'lib/components/ActionStatus';
import { RequirementsPopover } from 'lib/controls/popover';

import { submitBuildForReview } from 'gam/actions';
import { isPaymentStatusLocked } from 'gam/utils';

import PaymentDetailsPanel from 'gam/components/PaymentDetailsPanel';

import './SubmitForReviewDialog.css';
import { isSubmittedAsPaid } from '../../utils';

const PAYMENT_PANEL_REF_ID = 'payment';

class SubmitForReviewDialog extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState(props);
    this.panelRefs = {};
  }

  getInitialState(props) {
    let paymentInvalid = true;
    if (isPaymentStatusLocked(props.app) || isSubmittedAsPaid(props.app)) {
      paymentInvalid = false;
    }
    return {
      paymentInfo: props.app.payment,
      paymentInvalid,
    };
  }

  setPanelRef(refId, panel) {
    this.panelRefs[refId] = panel;
  }

  onChangeDeveloperComments = (evt) => {
    this.setState({
      developerComments: evt.target.value,
    });
  };

  getPaymentUpdateData() {
    const paymentPanel = this.panelRefs[PAYMENT_PANEL_REF_ID];

    if (!paymentPanel) {
      return {};
    }

    return paymentPanel.getData();
  }

  paymentValidityChanged = (valid) => {
    if (this.state.paymentInvalid === valid) {
      this.setState({ paymentInvalid: !valid });
    }
  };

  submitForReview = () => {
    const { app } = this.props;
    const { versions } = app;
    const { developerComments } = this.state;

    const payment = this.getPaymentUpdateData();

    // TODO: probably move this logic to registry
    const latestVersion = versions[0];
    const data = { developerComments };

    const promise = this.props.onSubmitBuildForReview({
      app,
      version: latestVersion,
      data,
      appChanges: payment,
    });

    this.setState({ promise });

    promise.then(() => {
      this.props.onClose();
    });
  };

  renderPaymentDetails() {
    const { app } = this.props;

    if (isPaymentStatusLocked(app)) {
      return (
        <div className="SubmitForReviewDialog--freeAppCallout">
          **Previously submitted as a free app**
        </div>
      );
    }

    return (
      <PaymentDetailsPanel
        editable={true}
        validityChanged={this.paymentValidityChanged}
        data={this.state.paymentInfo}
        ref={(ref) => this.setPanelRef(PAYMENT_PANEL_REF_ID, ref)}
      />
    );
  }

  get isSaveDisabled() {
    if (this.state.paymentInvalid) {
      return true;
    }

    if (!this.state.developerComments) {
      return true;
    }

    if (this.state.developerComments.trim() === '') {
      return true;
    }

    return false;
  }

  get requirements() {
    const requirements = [];
    if (!this.state.developerComments) {
      requirements.push({
        key: 'dev-comments',
        text: 'Must explain changes since last submission',
      });
    }

    return requirements;
  }

  render() {
    const { isOpen, onClose, app } = this.props;
    const { type } = app;
    const { promise, developerComments } = this.state;

    const typeName = typeNameMap[type].toLowerCase();

    return (
      <Dialog
        title="Submit App for Review"
        isOpen={isOpen}
        onClose={onClose}
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
      >
        <div className="SubmitForReviewDialog">
          <p>
            You are submitting your {typeName} <b>{localize(app.name)}</b> for
            review.
          </p>
          <p>
            Once your app is submitted for review, you will not be able to edit
            the metadata or upload new builds. Once published, the name of your
            app is final and cannot be changed.
          </p>
          <p>
            We will review your app and make sure it respects the{' '}
            <a target="_blank" href={guidelinesUrl}>
              Fitbit App Gallery Guidelines
            </a>{' '}
            and the{' '}
            <a target="_blank" href={distributionAgreementUrl}>
              Fitbit Distribution Agreement
            </a>
            . If we approve your app, you will be able to decide when it goes
            live. If we reject your app, we will contact you with more
            information.
          </p>
          <p>
            When you mark your application as paid we will show users in the
            Gallery a banner to notify them that your application is paid.
          </p>
          <p>
            Please fill out all the information as accurately as possible. The
            additional payment information is requested to better allow us to
            understand how the various paid apps are performing, but we have no
            current plans to expose this information to users.
          </p>
          <p>
            <b>
              Once you submit an app as free, you will not be able to make it a
              paid app.
            </b>{' '}
            Paid apps, however, may have their details changed at a later time
            and be changed into free apps.
          </p>
          {this.renderPaymentDetails()}
          <p>
            If there is anything you would like the App Review team to know (for
            example if an account is required to test your app) please let us
            know now:
          </p>
          <div className="SubmitForReviewDialog--form bp3-form">
            <textarea
              className="SubmitForReviewDialog--notesInput bp3-input"
              value={developerComments || ''}
              onChange={this.onChangeDeveloperComments}
            />
          </div>
          <MarginTop>
            <RequirementsPopover
              requirements={this.requirements}
              disabled={!this.isSaveDisabled}
            >
              <PromiseActionButton
                disabled={this.isSaveDisabled}
                promise={promise}
                onClick={this.submitForReview}
              >
                Let's Go!
              </PromiseActionButton>
            </RequirementsPopover>
            <PromiseActionStatusCallout promise={promise} />
          </MarginTop>
        </div>
      </Dialog>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onSubmitBuildForReview: ({ app, version, data, appChanges }) =>
    dispatch(submitBuildForReview({ app, version, data, appChanges })),
});

export default connect(null, mapDispatchToProps)(SubmitForReviewDialog);
