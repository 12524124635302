import React from 'react';
import { connect } from 'react-redux';
import StateDataManager from 'gam/components/StateDataManager';
import { Collapse, Switch } from '@blueprintjs/core';
import { isEmpty } from 'lodash';
import { PaymentStatus, RecurrenceFrequency } from 'gam/constants';

import './PaymentDetailsPanel.css';
import { SpecialPaymentProvider } from '../../constants';

const parseOptionalInt = (str, defaultValue) =>
  str ? parseInt(str, 10) : defaultValue;
const parseOptionalFloat = (str, defaultValue) =>
  str ? parseFloat(str) : defaultValue;

const Select = ({ items, ...props }) => (
  <select {...props}>
    {items.map(([key, displayName]) => (
      <option key={key} value={key}>
        {displayName}
      </option>
    ))}
  </select>
);

const STATUS_OPTIONS = [
  [PaymentStatus.FREE, 'Free'],
  [PaymentStatus.PAID, 'Paid'],
];

const UNSET_STATUS_OPTIONS = [
  [PaymentStatus.UNSET, 'Paid or Free...'],
  ...STATUS_OPTIONS,
];

const RECURRENCE_OPTIONS = Object.entries(RecurrenceFrequency);

class PaymentDetailsPanel extends StateDataManager {
  /* Setup */
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
  }

  getInitialState = () => {
    const { data } = this.state;

    const newState = {
      hasTrial: false,
      status: PaymentStatus.PAID,
    };

    const newData = {
      otherProvider: '',
      price: 0,
      trialPeriodHours: 0,
      recurring: RecurrenceFrequency.Once,
      ...data, // Overrides defaults
    };

    if (isEmpty(data)) {
      newData.provider = SpecialPaymentProvider.Unselected;
      newState.status = PaymentStatus.UNSET;
    } else if (data.provider === SpecialPaymentProvider.None) {
      newState.status = PaymentStatus.FREE;
    }

    if (data.trialPeriodHours && data.trialPeriodHours > 0) {
      newState.hasTrial = true;
    }

    return {
      ...newState,
      data: newData,
    };
  };

  /* External interface */

  getData() {
    const { data } = this.state;

    // If Provider is none, then the app is free, trash other values
    if (data.provider === SpecialPaymentProvider.None) {
      return {
        payment: {
          provider: SpecialPaymentProvider.None,
        },
      };
    }

    let otherProvider;
    if (data.provider === SpecialPaymentProvider.Other) {
      otherProvider = data.otherProvider;
    }

    return {
      payment: {
        provider: data.provider,
        price: data.price,
        trialPeriodHours: data.trialPeriodHours || 0,
        recurring: data.recurring,
        otherProvider,
      },
    };
  }

  hasValidInput() {
    const errors = this.validateInput();

    return !Object.values(errors).includes(true);
  }

  /* Change Handlers */

  handleChangePaidStatus = (event) => {
    let updatedData = {
      provider: SpecialPaymentProvider.None,
    };

    if (event.target.value === PaymentStatus.PAID) {
      // Reset/initialize some of the fields
      updatedData = {
        provider: SpecialPaymentProvider.Unselected,
        recurring: RecurrenceFrequency.Once,
      };
    }

    this.setState({
      status: event.target.value,
      data: {
        ...this.state.data,
        ...updatedData,
      },
    });
  };

  handlePaymentDueChange = () => {
    this.setState({
      hasTrial: !this.state.hasTrial,
      data: {
        ...this.state.data,
        trialPeriodHours: 0,
      },
    });
  };

  handleProviderChange = (event) => {
    this.handleChangeEvent('provider', event);
  };

  handleOtherProviderChange = (event) => {
    this.handleChangeEvent('otherProvider', event);
  };

  handlePriceChange = (event) => {
    this.handleChangeValue(
      'price',
      parseOptionalFloat(event.target.value, null)
    );
  };

  handleTrialLengthChange = (event) => {
    this.handleChangeValue(
      'trialPeriodHours',
      parseOptionalInt(event.target.value, null)
    );
  };

  handleRecurringFrequencyChange = (event) => {
    this.handleChangeEvent('recurring', event);
  };

  /**
   * Returns an object with boolean values representing whether the given data key has an invalid input
   */
  validateInput() {
    const {
      data: { provider, otherProvider, trialPeriodHours, price },
      status,
      hasTrial,
    } = this.state;

    return {
      status: status === PaymentStatus.UNSET,
      provider: provider === SpecialPaymentProvider.Unselected,
      otherProvider:
        status === PaymentStatus.PAID &&
        provider === SpecialPaymentProvider.Other &&
        otherProvider === '',
      price: status === PaymentStatus.PAID && (price <= 0 || price === ''),
      trialPeriodHours:
        status === PaymentStatus.PAID &&
        hasTrial &&
        (trialPeriodHours === '' || trialPeriodHours <= 0),
    };
  }

  componentDidUpdate() {
    this.props.validityChanged(this.hasValidInput());
  }

  render() {
    const { editable, paymentProviders, admin } = this.props;

    const { data, status, hasTrial } = this.state;

    const errors = this.validateInput();

    let isPaid = status === PaymentStatus.PAID;

    return (
      <div className="PaymentDetailsPanel--formSection PaymentDetailsPanel--paymentSection">
        <h3 className="PaymentDetailsPanel--heading">Payment Details</h3>
        <label className="bp3-label .modifier">
          <div className="bp3-select">
            <Select
              disabled={!editable}
              value={status}
              onChange={this.handleChangePaidStatus}
              items={
                status === PaymentStatus.UNSET
                  ? UNSET_STATUS_OPTIONS
                  : STATUS_OPTIONS
              }
            ></Select>
            {status === PaymentStatus.FREE && !admin && (
              <div className="bp3-callout bp3-intent-danger PaymentDetailPanel--irreversibleActionWarning">
                <h6 className="bp3-callout-title">Permanent Change</h6>
                Once saved as free, you will not be able to revert this to a
                paid app
              </div>
            )}
          </div>
        </label>
        <Collapse isOpen={isPaid}>
          <div className="bp3-form-group PaymentDetailsPanel--paymentDetails">
            <label className="bp3-label .modifier">
              Payment Provider
              <div className="bp3-select">
                <Select
                  className={errors.provider ? 'bp3-select-danger' : ''}
                  disabled={!editable}
                  value={data.provider || ''}
                  onChange={this.handleProviderChange}
                  items={paymentProviders}
                ></Select>
                <Collapse
                  isOpen={data.provider === SpecialPaymentProvider.Other}
                  className="PaymentDetailsPanel--paymentProviderOther"
                >
                  Provider Name:
                  <input
                    className={
                      errors.otherProvider
                        ? 'bp3-input bp3-intent-danger'
                        : 'bp3-input'
                    }
                    readOnly={!editable}
                    value={data.otherProvider || ''}
                    type="text"
                    onChange={this.handleOtherProviderChange}
                  />
                </Collapse>
              </div>
            </label>
          </div>
          <div className="bp3-form-group PaymentDetailsPanel--paymentDetails">
            <label className="bp3-form-label">Cost (in US Dollars)</label>
            <input
              className={
                errors.price ? 'bp3-input bp3-intent-danger' : 'bp3-input'
              }
              readOnly={!editable}
              value={data.price || ''}
              onChange={this.handlePriceChange}
              type="number"
            />
          </div>
          <div className="PaymentDetailsPanel--leftSwitchLabel">
            Pay upfront
            <Switch
              className="PaymentDetailsPanel--leftSwitchMargin"
              checked={hasTrial}
              label="Pay after trial"
              onChange={this.handlePaymentDueChange}
              disabled={!editable}
            />
          </div>
          <Collapse isOpen={hasTrial}>
            <div className="bp3-form-group PaymentDetailsPanel--paymentDetails PaymentDetailsPanel--trialPanel">
              <label className="bp3-form-label">
                Trial Length (in hours)
                <input
                  className={
                    errors.trialPeriodHours
                      ? 'bp3-input bp3-intent-danger'
                      : 'bp3-input'
                  }
                  type="number"
                  readOnly={!editable}
                  value={data.trialPeriodHours || ''}
                  onChange={this.handleTrialLengthChange}
                />
              </label>
            </div>
          </Collapse>
          <div className="bp3-form-group PaymentDetailsPanel--paymentDetails">
            <label className="bp3-label .modifier">
              Recurring Frequency
              <div className="bp3-select">
                <Select
                  disabled={!editable}
                  value={data.recurring || ''}
                  onChange={this.handleRecurringFrequencyChange}
                  items={RECURRENCE_OPTIONS}
                ></Select>
              </div>
            </label>
          </div>
        </Collapse>
      </div>
    );
  }
}

function augmentProviders(providers) {
  return providers.map((provider) => {
    if (provider === SpecialPaymentProvider.None) {
      return [SpecialPaymentProvider.Unselected, 'Choose a Provider...'];
    }

    return [provider, provider];
  });
}

const mapStateToProps = ({ gam }) => ({
  paymentProviders: augmentProviders(gam.paymentProviders),
});

export default connect(mapStateToProps, undefined, undefined, {
  forwardRef: true,
})(PaymentDetailsPanel);
