import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@blueprintjs/core';

import { PromiseActionButton } from 'lib/components/ActionStatus';

import { RequirementsPopover } from 'lib/controls/popover';
import { SubmitBuildForReviewDialog } from 'gam/components/SubmitBuildForReview';
import { ConfirmationDialogOpener } from '../ConfirmationDialog';

import { AppType } from 'gam/constants';
import { publishAppVersion, cancelBuildInReview } from 'gam/actions';

import {
  getActionValidity,
  getRequirementsFromActionValidity,
  checkShouldSkipBuildReview,
} from 'gam/utils';
import { SUBMIT_FOR_REVIEW } from '../../../lib/types';

const ActionButton = ({ children, ...props }) => (
  <PromiseActionButton className="VersionCard--button" {...props}>
    {children}
  </PromiseActionButton>
);

class PublishActions extends Component {
  state = {
    submitForReviewDialogOpen: false,
  };

  publishBuild = () => {
    const { app, version } = this.props;
    this.props.onPublishAppVersion(app, version);
  };

  cancelReview = () => {
    const { app, version } = this.props;
    this.props.onCancelReview(app, version);
  };

  openSubmitForReviewDialog = () => {
    this.setState({ submitForReviewDialogOpen: true });
  };

  closeSubmitForReviewDialog = () => {
    this.setState({ submitForReviewDialogOpen: false });
  };

  submitBuildForReview = () => {
    const { app, version } = this.props;
    this.props.onSubmitBuildForReview(app, version);
  };

  render() {
    const {
      app,
      app: { latestVersion, publishStatus: appPublishStatus, type: appType },
      version,
      version: { buildId, buildReview, publishStatus: versionPublishStatus },
    } = this.props;

    if (appType === AppType.TILE) {
      return null;
    }

    let shouldSkipReview = checkShouldSkipBuildReview(this.props.developerProfile);

    if (!buildReview) {
      // If this version is eligible for submitting for review, show the
      // submit for review button (possibly disabled if other reqs are not
      // met)
      if (
        latestVersion &&
        latestVersion.buildId === buildId &&
        versionPublishStatus === 'PENDING' &&
        appPublishStatus === 'PUBLISHED'
      ) {
        // NOTE: version doesn't have validity info; only latestVersion
        const validity = getActionValidity(latestVersion, SUBMIT_FOR_REVIEW) || {};
        const requirements = getRequirementsFromActionValidity(validity);
        const { valid } = validity;

        let canPublishBuild =
          versionPublishStatus === 'PENDING' || versionPublishStatus === 'STAGED';

        if (!shouldSkipReview) {
          return (
            <React.Fragment>
              <SubmitBuildForReviewDialog
                isOpen={this.state.submitForReviewDialogOpen}
                onClose={this.closeSubmitForReviewDialog}
                app={app}
                version={version}
              />
              <RequirementsPopover disabled={valid} requirements={requirements}>
                <ActionButton disabled={!valid} onClick={this.openSubmitForReviewDialog}>
                  Submit for Review ...
                </ActionButton>
              </RequirementsPopover>
            </React.Fragment>
          );
        } else {
          // if we should skip review, display Publish button instead of Submit for review
          if (canPublishBuild) {
            return (
              <React.Fragment>
                <RequirementsPopover disabled={valid} requirements={requirements}>
                  <ActionButton disabled={!valid} onClick={this.publishBuild}>
                    Publish Build
                  </ActionButton>
                </RequirementsPopover>
              </React.Fragment>
            );
          }
        }
      }

      return null;
    }

    let cancelText, publishText;
    if (buildReview) {
      switch (buildReview.reviewStatus) {
        case 'APPROVED':
          if (versionPublishStatus === 'PENDING' || versionPublishStatus === 'STAGED') {
            cancelText = 'Cancel';
            publishText = 'Publish Build';
          } else if (versionPublishStatus === 'PUBLISHED' && appPublishStatus !== 'PUBLISHED') {
            cancelText = 'Cancel Build';
          }
          break;
        case 'WAITING_FOR_REVIEW':
          cancelText = 'Cancel Review';
          break;
        default:
          return null;
      }
    }

    if (cancelText || publishText) {
      return (
        <div>
          {cancelText && (
            <ConfirmationDialogOpener
              button={<Button>{cancelText}</Button>}
              onConfirm={this.cancelReview}
              confirmText="Do you want to cancel the review?"
            />
          )}
          {publishText && <ActionButton onClick={this.publishBuild}>{publishText}</ActionButton>}
        </div>
      );
    } else {
      return null;
    }
  }
}

PublishActions.propTypes = {
  app: PropTypes.object.isRequired,
  version: PropTypes.object.isRequired,
};

const mapStateToProps = ({ gam }, { app: { developerProfileId } }) => ({
  developerProfile: developerProfileId ? gam.developerProfiles[developerProfileId] : null,
});

const mapDispatchToProps = (dispatch) => ({
  onPublishAppVersion: (app, version) => dispatch(publishAppVersion(app, version)),
  onCancelReview: (app, version) => dispatch(cancelBuildInReview(app, version)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PublishActions);
