import React from 'react';
import styled from 'styled-components';

import { Icon } from '@blueprintjs/core';
import { updateAllowlistDeveloper } from 'gam/queries';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useMutation } from '@apollo/react-hooks';
import { getExternalPlatformNames } from 'gam/utils';

const SupportedPlatformDiv = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #206276;
  margin: 5px;
  padding: 4px;
  border-radius: 4px;
`;

const SupportedPlatformName = styled.span`
  color: white;
  padding: 0px 4px 0px 0px;
`;

const RemoveFromAllowlistButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 0px 2px;
  background-color: Transparent;
  color: white;
  outline: none;
  border: none;
  width: 16px;
  border-left: 1px solid;
  margin-left: 4px;
`;

const RemoveFromAllowlistButtonIcon = styled(Icon)`
  width: 100%;
`;

const RemoveFromWhitelistButtonSpinner = styled(CircularProgress)`
  color: white !important;
`;

const Platform = (props) => {
  const [removeFromAllowlistMutation] = useMutation(updateAllowlistDeveloper, {
    onCompleted: ({ app }) => {
      props.updateState({
        platforms: app.platforms,
        extraPlatforms: app.extraPlatforms,
        buildPlatforms: app.buildPlatforms,
        updatingPlatforms: false,
      });
    },
  });

  const removeFromAllowlist = (name, externalName) => {
    if (!window.confirm(`Are you sure you want to NOT support ${externalName}`)) {
      return;
    }

    props.updateState({ ...props.state, updatingPlatforms: true });
    removeFromAllowlistMutation({ variables: { appId: props.app.id, platforms: [name] } });
  };

  const { extraPlatforms, buildPlatforms } = props.state;
  // To be removable it should be in extraPlatforms but not in buildPlatforms
  const removableFromAllowlist = (name) =>
    extraPlatforms.includes(name) && !buildPlatforms.includes(name);

  const externalNames = getExternalPlatformNames([props.name]);
  return (
    <SupportedPlatformDiv>
      <SupportedPlatformName>{externalNames}</SupportedPlatformName>
      {removableFromAllowlist(props.name) &&
        (props.state.updatingPlatforms ? (
          <RemoveFromAllowlistButton disabled={true}>
            <RemoveFromWhitelistButtonSpinner size={15} />
          </RemoveFromAllowlistButton>
        ) : (
          <RemoveFromAllowlistButton
            onClick={() => removeFromAllowlist(props.name, externalNames[0])}>
            <RemoveFromAllowlistButtonIcon icon="cross" />
          </RemoveFromAllowlistButton>
        ))}
    </SupportedPlatformDiv>
  );
};

export default Platform;
