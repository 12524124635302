import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Callout, Intent } from '@blueprintjs/core';
import styled from 'styled-components';
import { useForm, useFormState, Field } from 'react-final-form';

import { typeNameMap } from 'lib/config';
import {
  PromiseActionButton,
  PromiseActionStatus,
  ActionStatusCallout,
} from 'lib/components/ActionStatus';
import { MarginTop } from 'lib/controls/layout';
import { SimpleForm, FormRow, TextField, CheckboxField } from 'lib/controls/form';

import { nonEmpty, expectValue } from 'lib/form/validation';

import VersionCardShell from './VersionCardShell';
import { RequirementsPopover } from 'lib/controls/popover';
import { NotesForReviewersField } from 'gam/components/SubmitBuildForReview';
import { getFormRequirements, checkShouldSkipBuildReview } from '../../utils';
import FbaUploadDropzone from './FbaUploadDropzone';
import { useSelector } from 'react-redux';

const UploadElements = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const RightsText = () => (
  <div>
    By clicking this box, you represent and warrant that you have all necessary rights, including
    all patent, trademark, trade secret, copyright or other intellectual property, publicity, or
    other proprietary rights, in and to your App. Do not check this box to submit material to Fitbit
    unless you own all such rights or have sufficient permission from their rightful owner(s) to
    submit the material.
  </div>
);

const DetailFields = ({ app }) => (
  <div className="VersionCard--form">
    <FormRow label="Version">
      <TextField
        name="versionString"
        data-test-type="version-input-field"
        validate={nonEmpty('Version must not be empty')}
        maxLength={20}
        placeholder="e.g. 1.0.0"
      />
    </FormRow>
    {app.publishStatus === 'PUBLISHED' && (
      <FormRow label="Notes for reviewers">
        <NotesForReviewersField name="developerComments" />
      </FormRow>
    )}
    <CheckboxField
      name="acknowledgeRights"
      data-test-type="acknowledge-rights-field"
      label={<RightsText />}
      validate={expectValue(true, 'Must acknowledge rights notice')}
    />
  </div>
);

const UploadComment = styled.p`
  margin-top: 1em;
  font-style: italic;
`;

const UploadStatus = ({ app, status }) => {
  const { publishStatus, latestVersion } = app;

  const developerProfile = useSelector(({ gam }) =>
    app.developerProfileId ? gam.developerProfiles[app.developerProfileId] : null
  );

  let shouldSkipReview = checkShouldSkipBuildReview(developerProfile);

  if (status.complete && !status.error) {
    let message;
    let intent = Intent.SUCCESS;

    if (!shouldSkipReview) {
      switch (publishStatus) {
        case 'PUBLISHED':
          if (latestVersion.buildReviewId) {
            message = 'Build successfully uploaded and submitted for review.';
          } else {
            intent = Intent.WARNING;
            message = (
              <div>
                Upload successful, but additional details/screenshots are required before the build
                can be submitted for review.
              </div>
            );
          }
          break;
        default:
          message = 'Upload successful';
      }
    } else {
      switch (publishStatus) {
        case 'PUBLISHED':
          if (
            shouldSkipReview &&
            latestVersion &&
            (latestVersion.publishStatus === 'PENDING' ||
              latestVersion.publishStatus === 'PUBLISHED')
          ) {
            message = 'Build successfully uploaded';
          }
          break;
        default:
          message = 'Upload successful';
      }
    }

    return (
      <MarginTop>
        <Callout intent={intent}>{message}</Callout>
      </MarginTop>
    );
  }

  return <ActionStatusCallout status={status} />;
};

const PublishActions = ({ app, uploadPromise }) => {
  const formState = useFormState();
  const { submit } = useForm();

  const { type } = app;

  let actionText;
  let comment;
  const typeName = typeNameMap[type];

  const developerProfile = useSelector(({ gam }) =>
    app.developerProfileId ? gam.developerProfiles[app.developerProfileId] : null
  );

  let shouldSkipReview = checkShouldSkipBuildReview(developerProfile);

  switch (app.publishStatus) {
    case 'PUBLISHED':
      actionText = shouldSkipReview ? 'Upload app version' : 'Submit Update for Review';
      comment = 'Build will be reviewed by Fitbit.';
      break;
    case 'PRIVATE':
    case 'RESTRICTED':
      actionText = 'Publish Update';
      comment = `Build will be available to anyone with a private link to this ${typeName.toLowerCase()}.`;
      break;
    case 'DRAFT':
      actionText = `Publish ${typeName}`;
      comment = `${typeName} will be available via a private link.`;
      break;
    default:
      break;
  }

  const { valid } = formState;
  const requirements = getFormRequirements(formState);

  return (
    <div>
      <RequirementsPopover requirements={requirements} disabled={valid}>
        <PromiseActionButton
          promise={uploadPromise}
          disabled={!valid}
          data-test-type="publish-app-button"
          onClick={submit}>
          {actionText}
        </PromiseActionButton>
      </RequirementsPopover>
      <PromiseActionStatus promise={uploadPromise} component={<UploadStatus app={app} />} />
      <UploadComment>{comment}</UploadComment>
    </div>
  );
};

const UploadVersionCard = ({ app, onUploadVersion }) => {
  const [uploadPromise, setUploadPromise] = useState();
  const [state, updateState] = useState({
    storageFbaId: '',
    errorOnUpload: false,
    resetingForm: false,
  });

  const handleSubmit = useCallback(
    (values, form) => {
      let unmounted = false;

      if (!state.storageFbaId) {
        return;
      }

      const promise = onUploadVersion(app, { ...values, storageFbaId: state.storageFbaId });
      setUploadPromise(promise);

      promise.then(() => {
        if (unmounted) {
          return;
        }

        // Clear form on success
        updateState({ ...state, storageFbaId: '', resetingForm: true });
        form.reset();
        updateState({ ...state, storageFbaId: '', resetingForm: false });
      });

      return () => {
        unmounted = true;
      };
    },
    [app, onUploadVersion, state]
  );

  const FbaUploadDropzoneComponent = () => (
    <UploadElements>
      <Field name="file" validate={nonEmpty('Upload a .fba')}>
        {(rffProps) => (
          <FbaUploadDropzone
            versionState={state}
            updateVersionState={updateState}
            rffProps={rffProps}
          />
        )}
      </Field>
      {state.errorOnUpload && (
        <div className="bp3-callout bp3-intent-warning">
          <small>Error Uploading, please try again</small>
        </div>
      )}
    </UploadElements>
  );

  const left = <FbaUploadDropzoneComponent />;
  const middle = <DetailFields app={app} />;
  const right = <PublishActions app={app} uploadPromise={uploadPromise} />;

  const initialValues = {
    versionString: '',
    developerComments: '',
    acknowledgeRights: false,
  };

  return (
    <SimpleForm initialValues={initialValues} onSubmit={handleSubmit}>
      <VersionCardShell
        left={left}
        middle={middle}
        right={right}
        fbaUploaded={!!state.storageFbaId}
      />
    </SimpleForm>
  );
};

UploadVersionCard.propTypes = {
  onUploadVersion: PropTypes.func.isRequired,
};

export default UploadVersionCard;
