import React, { Component } from 'react';

import './MaintenanceMode.css';

class MaintenanceMode extends Component {
  render() {
    return (
      <h3 className="MaintenanceMode">
        Gallery App Manager is currently unavailable due to maintenance.
      </h3>
    );
  }
}

export default MaintenanceMode;
