import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ExistingVersionCard, UploadVersionCard } from 'gam/components/VersionCard';

import { AppType } from 'gam/constants';
import { uploadNewAppVersion, updateAppVersion } from 'gam/actions';

class VersionManager extends Component {
  renderVersionCard = ({ app, version }) => (
    <ExistingVersionCard
      key={version.buildId}
      app={app}
      version={version}
      onUpdateVersion={this.props.onUpdateVersion}
    />
  );

  render() {
    const { app, renderVersionCard = this.renderVersionCard, editable } = this.props;
    const { versions } = app;
    const { reviewStatus } = versions[0] || {};
    const publishStatusRemoved = app.publishStatus === 'REMOVED';
    const hasPendingReview =
      app.currentReviewStatus || ['WAITING_FOR_REVIEW', 'IN_REVIEW'].includes(reviewStatus);
    const hasOwnVersions = app.type !== AppType.TILE;

    return (
      <div>
        {!hasPendingReview && !publishStatusRemoved && editable && hasOwnVersions && (
          <UploadVersionCard app={app} onUploadVersion={this.props.onUploadVersion} />
        )}
        {versions.map((version) => renderVersionCard({ app, version }))}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onUploadVersion: (app, data) => dispatch(uploadNewAppVersion(app, data)),
  onUpdateVersion: (app, version, data) => dispatch(updateAppVersion(app, version, data)),
});

export default connect(null, mapDispatchToProps)(VersionManager);
