import React from 'react';
import { DivClassNameFactory } from './utils';
import styled from 'styled-components';

import './controls.css';

export const Card = DivClassNameFactory('bp3-card');

export const Margin = styled.div`
  margin: 1em;
`;

export const MarginTop = styled.div`
  margin-top: 1em;
`;

export const VerticalMargin = styled.div`
  margin: 1em 0;
`;

export const Padded = styled.div`
  padding: 1em;
`;

export const HalfSpacer = styled.div`
  margin-top: 0.5em;
`;

export const Spacer = styled.div`
  margin-top: 1em;
`;

export const SectionSpacer = styled.div`
  margin-top: 1em;
`;

// Named section
export interface NamedSectionProps {
  name: string;
  subname: string;
  children: any;
}
export const NamedSection = ({ name, subname, children }: NamedSectionProps) => (
  <section className="controls--NamedSection">
    <h1>
      {name} {subname && <div className="NamedSection--subname">{subname}</div>}
    </h1>
    {children}
  </section>
);
