import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NonIdealState } from '@blueprintjs/core';

import { LinkButton } from 'lib/controls/button';
import { DelayedSpinner } from 'lib/controls/placeholder';

import './Home.css';

class Home extends Component {
  render() {
    const { loggedIn, hasDeveloperProfile } = this.props;

    if (!loggedIn) {
      return (
        <NonIdealState
          className="developer-login-wrapper"
          description="Log in to your Fitbit account to continue"
          icon="log-in"
          action={
            <LinkButton to="/login" className="bp3-intent-primary developer-login-button">
              Log in
            </LinkButton>
          }
        />
      );
    } else if (!hasDeveloperProfile) {
      return <NonIdealState icon={<DelayedSpinner />} />;
    } else {
      return (
        <div className="Home">
          <LinkButton to="/apps">Manage apps</LinkButton>
        </div>
      );
    }
  }
}

const mapStateToProps = ({ gam }) => ({
  loggedIn: gam.session.isLoggedIn,
  hasDeveloperProfile: gam.userDeveloperInfo.developerProfileIds,
});

const mapDispatchToProps = (dispatch) => ({
  onRedirect: (action) => dispatch(action),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
