import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { HTMLTable, Popover, Button, Classes } from '@blueprintjs/core';
import Moment from 'react-moment';
import styled from 'styled-components';

import { cancelChangeRequest } from 'gam/actions';

import { changeRequestStatusName } from 'lib/config';
import ExpandableTextView from 'lib/components/ExpandableTextView';

const Table = styled(HTMLTable)`
  && > tbody > tr > td {
    vertical-align: middle;
  }
`;

const VerticalCenter = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LeftPaddedButton = styled(Button)`
  margin-left: 1em;
`;

const CommentInfo = styled.div`
  padding: 1em;
`;

const CommentLabel = styled.label`
  font-weight: bold;
`;

const ConfirmContent = styled.div`
  padding: 1em;
`;

const ConfirmMessage = styled.div`
  margin-bottom: 1em;
  font-weight: bold;
`;

const ShowCommentIndicator = ({ label, comment }) =>
  comment ? (
    <Popover
      interactionKind="hover"
      target={<LeftPaddedButton icon="label" minimal />}
      content={
        <CommentInfo>
          <CommentLabel>{label}</CommentLabel>
          <ExpandableTextView>{comment}</ExpandableTextView>
        </CommentInfo>
      }
    />
  ) : (
    ''
  );

const CancelButton = ({ app, changeRequest }) => {
  const dispatch = useDispatch();

  const confirmCancel = useCallback(() => {
    dispatch(cancelChangeRequest(app, changeRequest.id));
  }, [dispatch, app, changeRequest]);

  // END OF HOOKS

  return (
    <Popover
      target={<LeftPaddedButton icon="delete" minimal intent="DANGER" />}
      content={
        <ConfirmContent>
          <ConfirmMessage>
            Are you sure you want to cancel this request?
          </ConfirmMessage>
          <Button
            onClick={confirmCancel}
            className={Classes.POPOVER_DISMISS}
            intent="DANGER"
          >
            Cancel Request
          </Button>
        </ConfirmContent>
      }
    />
  );
};

export const ChangeRequests = ({ app }) => {
  const pages = app.pagedChangeRequests;
  const requests = pages.changeRequests;

  // TODO check permissions
  const editable = true;

  return (
    <div>
      <h3>Recent change requests</h3>
      <Table>
        <thead>
          <tr>
            <td>Submitted</td>
            <td>Description</td>
            <td>Status</td>
          </tr>
        </thead>
        <tbody>
          {requests.map((request) => (
            <tr key={request.id}>
              <td>
                <VerticalCenter>
                  <Moment format="L LT">{request.createdAt}</Moment>
                </VerticalCenter>
              </td>
              <td>
                <VerticalCenter>
                  {request.generatedDescription}
                  <ShowCommentIndicator
                    label="Submitter Comments"
                    comment={request.developerComments}
                  />
                </VerticalCenter>
              </td>
              <td>
                <VerticalCenter>
                  {changeRequestStatusName[request.status]}
                  {request.status === 'SUBMITTED' && (
                    <CancelButton
                      disabled={!editable}
                      app={app}
                      changeRequest={request}
                    />
                  )}
                  <ShowCommentIndicator
                    label="Reviewer Comments"
                    comment={request.reviewerComments}
                  />
                </VerticalCenter>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ChangeRequests;
