import { AsyncActionHandler } from 'lib/action-handler';
import apollo from '../apollo';
import { getEnrollmentQuery } from 'gam/queries';

export default new AsyncActionHandler({
  name: 'fetchEnrollment',
  defaultState: {
    enrollment: {},
  },
  handler() {
    return async () => {
      const { data: enrollment } = await apollo.query({
        query: getEnrollmentQuery,
        fetchPolicy: 'network-only',
      });

      return enrollment;
    };
  },
  reduce(state, { enrollment }) {
    return {
      enrollment,
    };
  },
});
