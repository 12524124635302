import { AsyncActionHandler } from 'lib/action-handler';
import apollo from '../apollo';
import { getPaymentProvidersQuery } from 'gam/queries';

export default new AsyncActionHandler({
  name: 'fetch_payment_providers',
  defaultState: {
    paymentProviders: {},
  },
  handler() {
    return async () => {
      const { data: paymentProviders } = await apollo.query({
        query: getPaymentProvidersQuery,
        fetchPolicy: 'network-only',
      });
      return paymentProviders;
    };
  },
  reduce(state, { paymentProviders }) {
    return {
      paymentProviders,
    };
  },
});
