import { isEmpty } from 'lodash';
import React, { Component } from 'react';
import { Tooltip } from '@blueprintjs/core';

import { getExternalPlatformNames } from 'gam/utils';

const CompatDetails = ({
  version: {
    // note: these may be null for older uploads
    deviceApiVersion,
    companionApiVersion,
    cPlatformDescriptors,
  },
}) => (
  <React.Fragment>
    <div>Minimum device API: {deviceApiVersion || '1.0.0'}</div>
    <div>Minimum companion API: {companionApiVersion || '1.0.0'}</div>
    {cPlatformDescriptors.length != 0 ? (
      <div>cPlatformDescriptors: {cPlatformDescriptors.join('\r\n') || null}</div>
    ) : null}
  </React.Fragment>
);

export default class BuildActions extends Component {
  render() {
    const {
      version,
      version: { buildId, platformDescriptors, clusterStorageIds, tags },
      extraBuildInfo,
    } = this.props;

    return (
      <ul className="VersionCard--buildInfo">
        <li>ID: {buildId}</li>
        <li>
          <Tooltip content={<CompatDetails version={version} />}>
            <span>Supports: {getExternalPlatformNames(platformDescriptors).join(', ')}</span>
          </Tooltip>
        </li>
        {!isEmpty(clusterStorageIds) && <li>App Cluster ID: {clusterStorageIds[0]}</li>}
        {!isEmpty(tags) && <li>Tags: {tags.join(', ')}</li>}
        {extraBuildInfo}
      </ul>
    );
  }
}
