import React, { useState } from 'react';
import styled from 'styled-components';
import Platform from '../Platform';

const SupportedPlatformContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Row = (props) => (
  <tr>
    <td style={{ minWidth: '150px' }}>{props.name}</td>
    <td>{props.children}</td>
  </tr>
);

const PlatformList = (props) => {
  const app = props.app || {};
  const [state, updateState] = useState({
    platforms: app.platforms || [],
    extraPlatforms: app.extraPlatforms || [],
    buildPlatforms: app.buildPlatforms || [],
    updatingPlatforms: false,
  });

  return (
    <Row name="Supported Devices">
      <SupportedPlatformContainer>
        {state.platforms.map((name) => (
          <Platform
            key={`platform_${name}`}
            name={name}
            state={state}
            updateState={updateState}
            app={props.app}
          />
        ))}
      </SupportedPlatformContainer>
    </Row>
  );
};

export default PlatformList;
