import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Tabs,
  Tab,
  Button,
  Popover,
  Menu,
  MenuItem,
  Position,
} from '@blueprintjs/core';
import './style.css';

import { supportedLanguages } from 'lib/config';

export default class LocalizedEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTabId: props.defaultLanguage,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { selectedTabId } = this.state;

    if (selectedTabId && !nextProps.languages.includes(selectedTabId)) {
      this.setState({ selectedTabId: nextProps.defaultLanguage });
    }
  }

  onSelectTab = (newTabId) => {
    this.setState({ selectedTabId: newTabId });
  };

  renderAddMenu() {
    const {
      defaultLanguage,
      languages,
      onAddLanguage,
      onRemoveLanguage,
    } = this.props;

    const enabledLanguages = new Set(languages);

    return (
      <Menu>
        {Object.keys(supportedLanguages)
          // Sort languages by name.
          .sort((a, b) =>
            supportedLanguages[a].localeCompare(supportedLanguages[b])
          )
          .map((lang) => {
            const enabled = enabledLanguages.has(lang);
            const name = supportedLanguages[lang];
            const isDefault = lang === defaultLanguage;

            return (
              <MenuItem
                key={lang}
                icon={enabled ? 'tick' : 'blank'}
                text={name}
                label={isDefault ? <b>default</b> : ''}
                onClick={() =>
                  enabled ? onRemoveLanguage(lang) : onAddLanguage(lang)
                }
              />
            );
          })}
      </Menu>
    );
  }

  setSelectedLanguage(lang) {
    this.setState({ selectedTabId: lang });
  }

  renderPanel(lang) {
    // const onFocusOnErrorBegin = () => {
    //   this.setState({ selectedTabId: lang });
    // };
    return this.props.renderPanel(lang); //, onFocusOnErrorBegin);
  }

  render() {
    const { editable, languages } = this.props;
    const { selectedTabId } = this.state;

    return (
      <div>
        <Tabs
          selectedTabId={selectedTabId}
          onChange={this.onSelectTab}
          className="lang-tab-list"
        >
          {languages.map((lang) => (
            <Tab
              key={lang}
              id={lang}
              title={supportedLanguages[lang]}
              panel={this.renderPanel(lang)}
            />
          ))}
          {editable && (
            <Popover
              content={this.renderAddMenu()}
              position={Position.RIGHT_TOP}
            >
              <Button className="bp3-minimal" icon="more" />
            </Popover>
          )}
        </Tabs>
      </div>
    );
  }
}

LocalizedEditor.propTypes = {
  languages: PropTypes.arrayOf(PropTypes.string).isRequired,
  onAddLanguage: PropTypes.func.isRequired,
  onRemoveLanguage: PropTypes.func.isRequired,
  renderPanel: PropTypes.func.isRequired,
};
