import React from 'react';
import Moment from 'react-moment';
import styled from 'styled-components';

import ExpandableTextView from 'lib/components/ExpandableTextView';
import { reviewStatusName, reviewCompleteStatuses } from 'lib/config';

const ReviewerFeedback = styled.div`
  margin-top: 1em;
`;

export default ({ version }) => {
  const { publishStatus, createdAt: uploadedAt, publishedAt, buildReview } = version;
  const { reviewedAt, reviewStatus, reviewerComments, cancelledAt, createdAt: submittedAt } =
    buildReview || {};
  const format = 'L LT';

  let uploadStatus = 'Uploaded';

  if (!buildReview && publishStatus === 'PUBLISHED') {
    uploadStatus = 'Uploaded & Published';
  }

  const outcome = reviewCompleteStatuses.has(reviewStatus) && reviewStatusName[reviewStatus];

  return (
    <div>
      {publishedAt && (
        <div>
          Published <Moment format={format}>{publishedAt}</Moment>
        </div>
      )}
      {outcome && (
        <div>
          {outcome} <Moment format={format}>{reviewedAt || cancelledAt}</Moment>
        </div>
      )}
      {!outcome && submittedAt && (
        <div>
          Submitted <Moment format={format}>{submittedAt}</Moment>
        </div>
      )}
      {!publishedAt && !submittedAt && <div>Not submitted for review</div>}
      <div>
        {uploadStatus} <Moment format={format}>{uploadedAt}</Moment>
      </div>
      {reviewerComments && (
        <ReviewerFeedback>
          <b>Reviewer Feedback</b>
          <ExpandableTextView>{reviewerComments}</ExpandableTextView>
        </ReviewerFeedback>
      )}
    </div>
  );
};
