import React from 'react';
import { Spinner, ProgressBar } from '@blueprintjs/core';
import Delay from 'react-delay';

export const DelayedSpinner = ({ wait = 400, ...rest }) => (
  <Delay wait={wait}>
    <Spinner {...rest} />
  </Delay>
);

export const DelayedProgress = ({ wait = 400, ...rest }) => (
  <Delay wait={wait}>
    <ProgressBar {...rest} />
  </Delay>
);
