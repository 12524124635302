import { Component } from 'react';
import { isEqual } from 'lodash';

// Abstract class
export default class StateDataManager extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        ...props.data, // copy data
      },
    };
  }

  // FIXME: allows direct introspection
  // Not ideal but avoids needing additional state propagation
  getData() {
    return this.state.data;
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.data, nextProps.data)) {
      // If props have changed (usually from server),
      // replace current data
      this.setState({
        data: nextProps.data,
      });
    }
  }

  handleChangeEvent(prop, event) {
    this.handleChangeValue(prop, event.target.value);
  }

  handleChangeValue(prop, value) {
    this.setState({
      data: {
        ...this.state.data,
        [prop]: value,
      },
    });
  }
}
