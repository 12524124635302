import { ApolloLink, Observable } from 'apollo-link';
import {
  selectHttpOptionsAndBody,
  fallbackHttpConfig,
  parseAndCheckHttpResponse,
} from 'apollo-link-http-common';
import { cloneDeepWith, omitBy, CloneDeepWithCustomizer } from 'lodash';

// Custom upload link; eventually we should replace with
// apollo's built-in file handling support once registry
// supports it
export function createMultipartUploadLink({ uri }: { uri: string }) {
  return new ApolloLink((operation) => {
    const context = operation.getContext();
    const files: any = [];

    const httpConfig = {
      credentials: context.credentials,
      headers: context.headers || {},
      http: context.http || {},
      options: context.fetchOptions || {},
    };

    // Serialize request variables, tracking files and replacing with ids
    const serializer: CloneDeepWithCustomizer<Record<string, any>> = (value) => {
      if (value instanceof File) {
        const id = Math.random().toString(16);
        files.push([id, value]);
        return id;
      } else if (value && typeof value.toJSON === 'function') {
        const newValue = value.toJSON();
        if (newValue === value) {
          return undefined;
        }

        return cloneDeepWith(newValue, serializer);
      }

      return undefined;
    };

    const variables = cloneDeepWith(operation.variables, serializer);

    const { body, options } = selectHttpOptionsAndBody(operation, fallbackHttpConfig, httpConfig);

    const overrideOptions = {};

    if (files.length > 0) {
      const formData = new FormData();

      // Query/variables must go first
      formData.append(
        'operations',
        JSON.stringify({
          ...body,
          variables,
        })
      );

      // Add files
      for (const [id, file] of files) {
        formData.append(id, file);
      }

      // @ts-ignore
      // Strip content-type header
      overrideOptions.headers = omitBy(
        options.headers,
        (_, key) => key.toLowerCase() === 'content-type'
      );
      // @ts-ignore
      overrideOptions.body = formData;
    } else {
      // @ts-ignore
      overrideOptions.body = JSON.stringify(body);
    }

    // NOTE: currently ignoring extensions
    const fetchOptions = {
      ...options,
      ...overrideOptions,
    };

    return new Observable((observer) => {
      fetch(uri, fetchOptions)
        .then((response) => {
          operation.setContext({ response });
          return response;
        })
        .then(parseAndCheckHttpResponse(operation))
        .then((result) => {
          observer.next(result);
          observer.complete();
          return result;
        })
        .catch((err) => {
          if (err.result && err.result.errors && err.result.data) {
            observer.next(err.result);
          }

          observer.error(err);
        });
    });
  });
}
