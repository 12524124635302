import React, { Component } from 'react';
import { Button, Dialog, Classes, Radio, RadioGroup, Spinner } from '@blueprintjs/core';
import styled from 'styled-components';
import { appNameMaxLength } from 'lib/config';
import { getActionValidity, hasInvalidCharacters } from 'gam/utils';
import { CREATE_SERVICE } from 'lib/types';

const StyledButton = styled(Button)`
  width: 60px;
`;

export default class CreateAppDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      name: '',
      appType: 'APP',
      errorMessage: '',
      creating: false,
    };
  }

  show() {
    this.setState({
      isOpen: true,
      errorMessage: '',
      creating: false,
    });
  }

  close() {
    this.setState({ isOpen: false });
  }

  setErrorMessage(errorMessage) {
    this.setState({ errorMessage });
  }

  setCreating(value) {
    this.setState({ creating: value });
  }

  handleChangeName = (event) => {
    this.setState({
      name: event.target.value,
      errorMessage: '',
    });
  };

  handleChangeType = (event) => {
    this.setState({ appType: event.target.value });
  };

  renderInvalidAppNameWarning() {
    return (
      <div className="bp3-callout bp3-intent-warning">
        <div>Only letters, numbers, spaces, and limited punctuation allowed in app name.</div>
      </div>
    );
  }

  isInvalidAppName() {
    return hasInvalidCharacters(this.state.name);
  }

  isValid() {
    return !this.isInvalidAppName() && this.state.appType;
  }

  handleClickCreate = () => {
    const data = {
      name: this.state.name,
      appType: this.state.appType,
    };

    this.props.onSubmit(data);
  };

  render() {
    const createServiceAction = getActionValidity(this.props.developerProfile, CREATE_SERVICE);

    return (
      <Dialog
        isOpen={this.state.isOpen}
        canOutsideClickClose={false}
        onClose={() => this.close()}
        title="Create App">
        <div className={Classes.DIALOG_BODY}>
          <div className="bp3-form-group bp3-inline">
            <label className="bp3-label" htmlFor="name">
              Name
            </label>
            <div className="bp3-form-content">
              <input
                data-test-type="create-app-name-field"
                type="text"
                maxLength={appNameMaxLength}
                name="name"
                className="bp3-input"
                value={this.state.name}
                onChange={this.handleChangeName}
              />
              {this.isInvalidAppName() ? this.renderInvalidAppNameWarning() : ''}
            </div>
          </div>
          <div className="bp3-form-group bp3-inline">
            <label className="bp3-label" htmlFor="appType">
              Type
            </label>
            <div className="bp3-form-content">
              <RadioGroup selectedValue={this.state.appType} onChange={this.handleChangeType}>
                <Radio label="App" value="APP" data-test-type="app-radio" />
                <Radio label="Clockface" value="CLOCK" data-test-type="clock-radio" />
                {createServiceAction.valid && (
                  <Radio label="Service" value="SERVICE" data-test-type="service-radio" />
                )}
              </RadioGroup>
            </div>
          </div>
          {this.state.errorMessage && (
            <div className="bp3-callout bp3-intent-warning">
              <small>{this.state.errorMessage}</small>
            </div>
          )}
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            {this.state.creating ? (
              <StyledButton
                className="bp3-intent-primary"
                disabled={true}
                data-test-type="create-button">
                <Spinner size={Spinner.SIZE_SMALL} />
              </StyledButton>
            ) : (
              <StyledButton
                className="bp3-intent-primary"
                disabled={!this.isValid()}
                data-test-type="create-button"
                onClick={this.handleClickCreate}>
                Create
              </StyledButton>
            )}
          </div>
        </div>
      </Dialog>
    );
  }
}
