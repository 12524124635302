import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@blueprintjs/core';

import './ImageManager.css';

export default class ImagePlaceholderBox extends Component {
  render() {
    const { width, height, onClick, children } = this.props;

    return (
      <div
        onClick={onClick}
        className={`ImageManager--placeholderBox`}
        style={{ width, height }}
      >
        <div
          className="ImageManager--placeholderVisual"
          data-test-type="icon-button"
        >
          {children || <Icon icon="plus" />}
        </div>
      </div>
    );
  }
}

ImagePlaceholderBox.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  onClick: PropTypes.func,
};
