import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormGroup } from '@blueprintjs/core';

import {
  PromiseActionButton,
  PromiseActionStatusCallout,
} from 'lib/components/ActionStatus';
import { setupNewDeveloper } from 'gam/actions';

import { developerProfileNameMaxLength } from 'lib/config';

class CreateProfile extends Component {
  constructor(props) {
    super(props);
    const {
      enrollment: { userProfile },
    } = props;

    this.state = {
      developerName: userProfile.fullName || '',
    };
  }

  createProfile = () => {
    const { developerName } = this.state;
    const {
      enrollment: { requiredAgreements },
    } = this.props;

    const promise = this.props.onSetupNewDeveloper({
      name: developerName,
      signedAgreements: requiredAgreements,
    });

    this.setState({ setupPromise: promise });
  };

  onChangeDeveloperName = (evt) => {
    this.setState({
      developerName: evt.target.value,
    });
  };

  render() {
    const { developerName, setupPromise } = this.state;

    return (
      <div className="SetupDeveloper--center">
        <div>
          <p id="developer-enroll-note">
            Please provide a "Developer Name". Your apps will be published under
            this name and this will be visible to users of the Fitbit App
            Gallery.
          </p>
          <p id="developer-enroll-warning">
            Note that you cannot change your developer name later.
          </p>
          <FormGroup label="Developer name" inline required>
            <input
              id="developer-enroll-name-input"
              className="bp3-input"
              value={developerName}
              maxLength={developerProfileNameMaxLength}
              onChange={this.onChangeDeveloperName}
            />
          </FormGroup>
          <PromiseActionButton
            id="developer-enroll-create-profile"
            disabled={!developerName}
            onClick={this.createProfile}
            promise={setupPromise}
          >
            Create Profile
          </PromiseActionButton>
          <PromiseActionStatusCallout promise={setupPromise} />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onSetupNewDeveloper: (data) => dispatch(setupNewDeveloper(data)),
});

export default connect(null, mapDispatchToProps)(CreateProfile);
