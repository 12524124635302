import React, { Component } from 'react';

import VersionCardShell from './VersionCardShell';
import BuildInfo from './BuildInfo';
import VersionInfo from './VersionInfo';
import ReviewInfo from './ReviewInfo';
import PublishActions from './PublishActions';

export default class ExistingVersionCard extends Component {
  render() {
    const { app, version } = this.props;

    const left = <BuildInfo app={app} version={version} />;
    const middle = <VersionInfo app={app} version={version} />;

    const right = (
      <div>
        <ReviewInfo app={app} version={version} />
        <PublishActions app={app} version={version} />
      </div>
    );

    return <VersionCardShell left={left} middle={middle} right={right} />;
  }
}
