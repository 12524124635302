import React from 'react';
import { WideFormGroup } from 'lib/controls/form';
import { MarginTop } from 'lib/controls/layout';
import StateDataManager from 'gam/components/StateDataManager';
import { emailMaxLength, urlMaxLength } from 'lib/config';

export default class GeneralDetailsPanel extends StateDataManager {
  handleChangeContactEmail = (event) =>
    this.handleChangeEvent('contactEmail', event);

  handleChangeContactUrl = (event) =>
    this.handleChangeEvent('contactUrl', event);

  render() {
    const { editable } = this.props;
    const { data } = this.state;
    const hasSupportUrlOnly = data.contactUrl && !data.contactEmail;
    const hasSupportEmailOnly = data.contactEmail && !data.contactUrl;

    return (
      <div className="AppDetailsEditor--formSection">
        <h3 className="AppDetailsEditor--heading">General details</h3>
        <WideFormGroup>
          <label className="bp3-label">Support Email Address</label>
          <input
            className="bp3-input"
            value={data.contactEmail || ''}
            maxLength={emailMaxLength}
            readOnly={!editable}
            disabled={hasSupportUrlOnly}
            onChange={this.handleChangeContactEmail}
            data-test-type="support-email-address-field"
          />
        </WideFormGroup>
        <WideFormGroup>
          <label className="bp3-label">Support URL</label>
          <input
            className="bp3-input"
            value={data.contactUrl || ''}
            maxLength={urlMaxLength}
            readOnly={!editable}
            disabled={hasSupportEmailOnly}
            onChange={this.handleChangeContactUrl}
            data-test-type="support-url-field"
          />
        </WideFormGroup>
        <MarginTop></MarginTop>
      </div>
    );
  }
}
