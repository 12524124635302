import React, { Component } from 'react';
import { connect } from 'react-redux';
import { redirect } from 'redux-first-router';
import Link from 'redux-first-router-link';
import { Button, Popover, Position, Menu, MenuItem } from '@blueprintjs/core';
import styled from 'styled-components';

const LinkSection = styled.div`
  margin-right: 2em;
`;

export class NavBar extends Component {
  renderUserMenu() {
    const { logout } = this.props;

    return (
      <Menu className="bp3-light">
        <MenuItem onClick={logout} icon="log-out" text="Log out" />
      </Menu>
    );
  }

  render() {
    const { title, className, loggedIn, showFAQLink } = this.props;

    return (
      <nav
        className={`NavContainer--navbar bp3-navbar bp3-dark ${
          className || ''
        }`}
      >
        <div className="bp3-navbar-group bp3-align-left">
          <div className="bp3-navbar-heading">{title}</div>
        </div>
        <div className="bp3-navbar-group bp3-align-right">
          <LinkSection>
            {showFAQLink && <Link to={{ type: 'FAQ' }}>FAQ</Link>}
          </LinkSection>
          {loggedIn && (
            <div>
              <Popover
                content={this.renderUserMenu()}
                position={Position.BOTTOM_RIGHT}
              >
                <Button className="bp3-button bp3-minimal bp3-icon-user">
                  Logged In
                </Button>
              </Popover>
            </div>
          )}
        </div>
      </nav>
    );
  }
}

const mapStateToProps = ({ gam }) => ({
  loggedIn: gam.session.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({
  logout() {
    return dispatch(redirect({ type: 'LOGOUT' }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
