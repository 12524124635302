import React from 'react';
import Link from 'redux-first-router-link';
import Moment from 'react-moment';
import { sortBy } from 'lodash';

import { localize, shortenRefId } from 'lib/utils';
import { typeNameMap, appPublishStatusDescriptions } from 'lib/config';
import SortableTable from 'lib/controls/sortable-table';

import './AppList.css';

function makeAppRefPath(refId) {
  // Shorten ref in url to avoid confusion with app uuid
  return `/apps/${shortenRefId(refId)}`;
}

const NameCell = ({ row: { original: app } }) => (
  <Link className="bp3-ui-text-large" to={makeAppRefPath(app.refId)}>
    {localize(app.name) || 'UNNAMED APP'}
  </Link>
);

const PublishStatusCell = ({ cell: { value } }) => appPublishStatusDescriptions[value] || null;

const DateCell = ({ cell: { value } }) => (value ? <Moment format="L">{value}</Moment> : '');

// Note: not all states are available here
const reviewStatusNames = {
  APPROVED: 'Approved',
  IN_REVIEW: 'Waiting',
  WAITING_FOR_REVIEW: 'Waiting',
};

const DEFAULT_SORT_BY = [
  {
    id: 'name',
    desc: false,
  },
];

const TABLE_COLUMNS = [
  {
    Header: 'Name',
    Cell: NameCell,
    id: 'name',
    accessor: (app) => localize(app.name),
  },
  {
    Header: 'Type',
    id: 'type',
    accessor: (app) => typeNameMap[app.type],
  },
  {
    Header: 'Created',
    Cell: DateCell,
    accessor: 'createdAt',
  },
  {
    Header: 'Publish Status',
    Cell: PublishStatusCell,
    accessor: 'publishStatus',
  },
  {
    Header: 'Review Approval',
    id: 'currentReviewStatus',
    accessor: (app) => reviewStatusNames[app.currentReviewStatus] || '',
  },
  {
    Header: 'Last Published',
    Cell: DateCell,
    accessor: 'lastPublishedAt',
  },
];

const AppList = ({ apps }) => {
  const initialState = {
    sortBy: DEFAULT_SORT_BY,
  };

  // Ensure apps are sorted even if sort is disabled
  apps = sortBy(apps, (app) => localize(app.name));

  return (
    <SortableTable
      testId="developer-profile-apps"
      initialState={initialState}
      autoResetSortBy={false}
      sortStorageName="dev-app-list"
      columns={TABLE_COLUMNS}
      data={apps}
    />
  );
};

export default AppList;
