import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, NonIdealState } from '@blueprintjs/core';
import { LinkButton } from 'lib/controls/button';

import { supportEmail } from 'lib/config';

import DistributionAgreement from './Agreement';
import TermsOfService from './TermsOfService';
import EmailVerification from './EmailVerification';

import CreateProfile from './CreateProfile';

import './SetupDeveloper.css';

const EnrollLink = ({ enrollButtonPressed }) => (
  <NonIdealState
    className="developer-enroll-wrapper"
    title="Enroll as a developer"
    description="Click this button to go to the Fitbit development enrollment page."
    action={
      <a id="developer-enroll-link">
        <Button onClick={enrollButtonPressed}>Enroll</Button>
      </a>
    }
  />
);

const DeviceRegistration = ({ enrollment }) => {
  const { hasAvailableDevices, hasUnavailableDevices } = enrollment;

  if (hasAvailableDevices) {
    // Shouldn't really even be in this stage unless we're testing
    return <p>Good to go</p>;
  } else if (hasUnavailableDevices) {
    return (
      <div>
        <p>
          It looks like your watch is already associated to another developer account. One watch can
          only be associated to one Fitbit developer account at a time.
        </p>
        <p>
          Contact <a href={`mailto:${supportEmail}`}>{supportEmail}</a> if you need assistance.
        </p>
      </div>
    );
  } else {
    return (
      <div>
        <p>
          You need to have a Fitbit watch paired to your Fitbit account before you can submit your
          app for distribution. Please associate your watch to this account and try again.
        </p>
      </div>
    );
  }
};

const SetupComplete = () => (
  <NonIdealState
    className="developer-enroll-setup-complete"
    title="Setup Complete"
    description="You're now ready to upload and manage apps."
    icon="person"
    action={
      <LinkButton id="developer-enrol-manage-apps" to="/apps">
        Manage apps
      </LinkButton>
    }
  />
);

class SetupDeveloper extends Component {
  state = {
    signedDistributionAgreement: false,
    signedTermsOfService: false,
    enrollButtonPressed: false,
    testEmail: false,
  };

  agreeTerms = () => {
    this.setState({ signedDistributionAgreement: true });
  };

  agreeTermsOfService = () => {
    this.setState({ signedTermsOfService: true });
  };

  pressEnrollButton = () => {
    this.setState({ enrollButtonPressed: true });
  };

  // Figure out what stage of the setup process we're in
  getStage() {
    const {
      setupIsComplete,
      enrollment: { isDeveloper, needDeviceRegistration, hasAvailableDevices, hasEmailVerified },
    } = this.props;

    if (setupIsComplete) {
      return 'complete';
    } else if (!isDeveloper && !this.state.enrollButtonPressed) {
      return 'need_enroll';
    } else if (!hasEmailVerified) {
      return 'email_verification';
    } else if (!isDeveloper) {
      return 'need_terms_of_service';
    } else if (!this.state.signedDistributionAgreement) {
      return 'need_distribution_terms';
    } else if (needDeviceRegistration && !hasAvailableDevices) {
      return 'need_device_registration';
    } else {
      return 'create_profile';
    }
  }

  render() {
    const { setupNewDeveloper, enrollment } = this.props;

    // Make things easier to test
    const forceStage = '';
    const stage = forceStage || this.getStage();

    switch (stage) {
      case 'complete':
        return <SetupComplete />;
      case 'need_enroll':
        return <EnrollLink enrollButtonPressed={this.pressEnrollButton} />;
      case 'email_verification':
        return <EmailVerification />;
      case 'need_terms_of_service':
        return <TermsOfService onAgreeTermsOfService={this.agreeTermsOfService} />;
      case 'need_distribution_terms':
        return <DistributionAgreement onAgreeTerms={this.agreeTerms} />;
      case 'need_device_registration':
        return (
          <div className="SetupDeveloper--center">
            <DeviceRegistration enrollment={enrollment} />
          </div>
        );
      case 'create_profile':
        return <CreateProfile enrollment={enrollment} setupNewDeveloper={setupNewDeveloper} />;
      default:
        return <NonIdealState icon="error" description="Something went wrong" />;
    }
  }
}

const mapStateToProps = ({ gam }) => ({
  enrollment: gam.enrollment,
  setupIsComplete: !!(gam.userDeveloperInfo && gam.userDeveloperInfo.developerProfileIds),
});

export default connect(mapStateToProps, null)(SetupDeveloper);
