import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NOT_FOUND } from 'redux-first-router';
import Link from 'redux-first-router-link';

import { PAGE } from './routes';
import NavContainer from 'lib/components/NavContainer';
import Navbar from 'lib/components/NavContainer/Navbar';
import { gamMaintenanceMode } from 'lib/config.js';
import { NonIdealState } from '@blueprintjs/core';
import { DelayedSpinner } from 'lib/controls/placeholder';

// Page components
import Home from 'gam/pages/Home';
import SetupDeveloper from 'gam/pages/SetupDeveloper';
import MaintenanceMode from 'gam/pages/MaintenanceMode';
import Apps from 'gam/pages/Apps';
import EditApp from 'gam/pages/EditApp';
import PreviewEnrollment from 'gam/pages/PreviewEnrollment';
import FAQ from 'gam/pages/FAQ';

import { AuthClient } from '../lib/network/auth-client';
import { saveSession } from 'gam/actions';

class GAM extends Component {
  constructor(props) {
    super(props);
    if (props.saveSession) {
      AuthClient.getInstance().sessionAction = props.saveSession;
    }
  }

  getPageComponent(location) {
    switch (location.type) {
      case PAGE.HOME:
        return <Home />;
      case PAGE.LOGIN:
        return <NonIdealState title="Logging in" icon={<DelayedSpinner />} />;
      case PAGE.LOGOUT:
        return <NonIdealState title="Logging out" icon={<DelayedSpinner />} />;
      case PAGE.APPS:
        return <Apps />;
      case PAGE.PREVIEW_ENROLLMENT:
        return <PreviewEnrollment />;
      case PAGE.EDIT_APP:
        return <EditApp />;
      case PAGE.SETUP_DEVELOPER:
        return <SetupDeveloper />;
      case PAGE.FAQ:
        return <FAQ />;
      case PAGE.GALLERY_LINK_APP:
      case PAGE.GALLERY_LINK_DISCOVERY:
      case PAGE.GALLERY_LINK_COLLECTION:
        return <NonIdealState title="Redirecting" icon={<DelayedSpinner />} />;
      default:
        return <div>Page not found</div>;
    }
  }

  render() {
    const { location, routeState } = this.props;
    const { ready, error } = routeState || {};
    const navbar = (
      <Navbar title={<Link to="/apps">Gallery App Manager</Link>} showFAQLink={true} />
    );

    // Don't add nav wrapper to gallery private link handler
    if (location.type === PAGE.GALLERY_PRIVATE_LINK) {
      return <div>Directing you to Gallery in Fitbit mobile application</div>;
    }

    if (gamMaintenanceMode) {
      return <MaintenanceMode />;
    }

    let page;

    if (error) {
      page = <NonIdealState icon="error" title="An error occurred loading this page." />;
    } else if (!ready && location.type !== NOT_FOUND) {
      page = <NonIdealState icon={<DelayedSpinner />} />;
    } else {
      page = this.getPageComponent(location);
    }

    return <NavContainer navbar={navbar}>{page}</NavContainer>;
  }
}

const mapStateToProps = ({ location, routes }) => ({
  location,
  routeState: routes[location.type],
});

const mapDispatchToProps = (dispatch) => ({
  saveSession: (session) => dispatch(saveSession(session)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GAM);
