import React, { Component } from 'react';
import { MarginTop } from '../../controls/layout';
import { Button } from '@blueprintjs/core';

export interface PromiseActionStatusProps {
  promise?: Promise<any> | undefined;
  component?: any;
  children?: any;
}

export interface PromiseActionStatusState {
  promise?: Promise<any> | undefined;
  status?: {
    error?: any;
    started?: boolean;
    complete?: boolean;
  };
}

export class PromiseActionStatus extends Component<
  PromiseActionStatusProps,
  PromiseActionStatusState
> {
  _mounted: any;

  constructor(props: any) {
    super(props);
    this.state = {};
  }

  promiseChanged(promise: any) {
    if (!promise) {
      this.setState({
        promise: undefined,
        status: {
          error: null,
          started: false,
          complete: false,
        },
      });
    }

    if (!promise || promise === this.state.promise) {
      return;
    }

    this.setState({
      promise,
      status: {
        error: null,
        started: true,
        complete: false,
      },
    });

    promise.then(
      () => {
        // Only if watched promise hasn't changed
        if (this._mounted && promise === this.state.promise) {
          this.setState({
            status: {
              error: null,
              started: true,
              complete: true,
            },
          });
        }
      },
      (err: any) => {
        // Only if watched promise hasn't changed
        if (this._mounted && promise === this.state.promise) {
          this.setState({
            status: {
              error: err,
              started: true,
              complete: true,
            },
          });
        }
      }
    );
  }

  componentWillMount() {
    this._mounted = true;
    this.promiseChanged(this.props.promise);
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  componentWillReceiveProps(nextProps: PromiseActionStatusProps) {
    this.promiseChanged(nextProps.promise);
  }

  render() {
    // eslint-disable-next-line
    const { promise, component } = this.props;
    const { status } = this.state;

    return React.cloneElement(component, { status });
  }
}

// Button that
export interface ActionButtonProps {
  status?: {
    started: boolean;
    complete: boolean;
  };
}
export interface ActionButtonState {}
export class ActionButton extends Component<ActionButtonProps, ActionButtonState> {
  render() {
    const { status, ...rest } = this.props;
    const { started, complete } = status || {};
    const buttonProps: any = { ...rest }; // clone

    if (started && !complete) {
      buttonProps.loading = true;
    }

    return <Button {...buttonProps} />;
  }
}

export interface ActionStatusCalloutProps {
  status?: {
    error: any;
    started: boolean;
    complete: boolean;
  };
  progressText?: string;
  successText?: string;
  errorText?: string;
  children?: any;
}
export interface ActionStatusCalloutState {}
export class ActionStatusCallout extends Component<
  ActionStatusCalloutProps,
  ActionStatusCalloutState
> {
  render() {
    const { status, progressText, successText, errorText } = this.props;
    const { error, started, complete } = status || {};

    if (error) {
      let message = error.message;

      // HACK
      message = message.replace(/^GraphQL error: /, '');

      return (
        <MarginTop className="bp3-callout bp3-intent-warning">
          <h3>{errorText || 'Error'}</h3>
          <small>{message}</small>
        </MarginTop>
      );
    } else if (complete && successText) {
      return (
        <MarginTop className="bp3-callout bp3-intent-success">
          <small>{successText}</small>
        </MarginTop>
      );
    } else if (!complete && started && progressText) {
      return (
        <MarginTop className="bp3-callout bp3-intent-primary">
          <small>{progressText}</small>
        </MarginTop>
      );
    } else {
      return <div></div>;
    }
  }
}

export interface PromiseActionButtonProps extends ActionButtonProps {
  promise?: Promise<any> | undefined;
  children?: any;
  className?: string;
  icon?: string;
  id?: string;
  onClick?: any;
  disabled?: any;
}
export const PromiseActionButton = ({ promise, ...rest }: PromiseActionButtonProps) => (
  <PromiseActionStatus promise={promise} component={<ActionButton {...rest} />} />
);

export interface PromiseActionStatusCalloutProps extends ActionStatusCalloutProps {
  promise?: Promise<any> | undefined;
  children?: any;
}
export const PromiseActionStatusCallout = ({
  promise,
  ...rest
}: PromiseActionStatusCalloutProps) => (
  <PromiseActionStatus promise={promise} component={<ActionStatusCallout {...rest} />} />
);
