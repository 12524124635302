import { AsyncActionHandler } from 'lib/action-handler';
import apollo from '../apollo';

import { updateDeviceChannelEnrollmentMutation } from 'gam/queries';

export default new AsyncActionHandler({
  name: 'update_device_channel_enrollment',
  handler({ deviceType, selectedChannelId } = {}) {
    return async (dispatch, getState) => {
      const {
        userDeveloperInfo: { devices },
      } = getState().gam;
      const { data: { updatedDevice } = {} } = await apollo.mutate({
        mutation: updateDeviceChannelEnrollmentMutation,
        variables: {
          deviceType,
          channelId: selectedChannelId,
        },
      });

      return {
        devices: [
          ...devices.map((device) => {
            if (device.type !== updatedDevice.type) {
              return device;
            }
            return {
              ...updatedDevice,
            };
          }),
        ],
      };
    };
  },
  reduce(state, { devices }) {
    return {
      userDeveloperInfo: {
        ...state.userDeveloperInfo,
        devices,
      },
    };
  },
});
