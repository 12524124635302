import React, { useState, useCallback } from 'react';
import apollo from '../../apollo';
import { getFbaSignedUploadUrl } from 'gam/queries';
import { useDropzone } from 'react-dropzone';
import ProgressBar from '../ProgressBar';
import styled from 'styled-components';

const Dropzone = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  border-color: #e3e3e3;
  flex-direction: column;
  border-width: 3px;
  border-style: dashed;
  border-radius: 3px;
`;

const TextZone = styled.p`
  text-align: center;
  padding: 0 5px 0 5px;
  text-overflow: ellipsis;
`;

const StyledProgressBar = styled(ProgressBar)`
  width: 100%;
`;

const FbaUploadDropzone = (props) => {
  const [uploadProgressState, updateUploadProgressState] = useState(0);
  const [state, updateState] = useState({ fileName: '' });

  // Needed because react final form causes this component to unmount and remount after upload is complete.
  // This makes us lose the fileName, with these rows we put the fileName back
  const uploadedFiles = props.rffProps.input.value;
  if (uploadedFiles && !state.fileName && !props.versionState.resetingForm) {
    updateState({ ...state, fileName: uploadedFiles[0].name });
  }
  if (props.versionState.resetingForm && state.fileName) {
    updateState({ ...state, fileName: '' });
  }

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (props.rffProps) {
        props.rffProps.input.onChange(acceptedFiles);
      }
      const file = acceptedFiles[0];
      updateState({ ...state, fileName: file.name });
      const blob = new Blob([file], { type: 'application/fba' });

      const { data: response } = await apollo.query({
        query: getFbaSignedUploadUrl,
        fetchPolicy: 'network-only',
      });

      const xhr = new XMLHttpRequest();
      xhr.upload.onprogress = (event) => {
        const percentage = parseInt((event.loaded / event.total) * 100, 10);
        updateUploadProgressState(percentage);
      };
      xhr.onreadystatechange = () => {
        if (xhr.readyState !== 4) {
          return;
        }
        if (xhr.status !== 200) {
          props.updateVersionState({
            ...props.versionState,
            errorOnUpload: true,
          });
          return;
        }
        props.updateVersionState({
          ...props.versionState,
          storageFbaId: response.signedUrl.storageFbaId,
        });
      };
      xhr.open('PUT', response.signedUrl.fbaLink, true);
      xhr.setRequestHeader('Content-Type', 'application/fba');
      xhr.send(blob);
    },
    [props, state]
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop, maxFiles: 1, accept: '.fba' });

  return (
    <React.Fragment>
      <Dropzone id="fba-upload-dropzone" {...getRootProps()}>
        <input {...getInputProps()} />
        {state.fileName ? (
          <TextZone> {state.fileName} </TextZone>
        ) : (
          <TextZone> Drag 'n' drop some files here, or click to select files </TextZone>
        )}
        {uploadProgressState > 0 && uploadProgressState < 100 && (
          <StyledProgressBar id="fbaUploadPB" bgcolor="#206276" completed={uploadProgressState} />
        )}
      </Dropzone>
    </React.Fragment>
  );
};

export default FbaUploadDropzone;
