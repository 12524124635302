import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Checkbox, Button, Position, Tooltip } from '@blueprintjs/core';
import { isEqual, chain, map, xor } from 'lodash';

import { MarginTop } from 'lib/controls/layout';
import { PromiseActionButton } from 'lib/components/ActionStatus';
import { localize } from 'lib/utils';
import { SUBMIT_CHANGE_REQUEST } from 'lib/types';
import { getCategories } from 'gam/state-utils';
import { CategoryGrouping } from 'gam/constants';
import DialogOpener from 'lib/components/DialogOpener';
import CategoryChangeDialog from 'gam/components/ChangeRequest/CategoryChangeDialog';

import { updateAppCategories } from 'gam/actions';
import { getActionValidity } from 'gam/utils';

const SelectCategories = ({ categories, selectedCategoryIds, onChange, disabled }) => (
  <div className="MiscDetailsEditor--tags flex-col">
    {map(categories, ({ id, name, description }) => (
      <Tooltip
        key={`tooltip-${id}`}
        disabled={!localize(description)}
        content={localize(description)}
        position={Position.TOP}
        data-category-id={`${localize(name)}_container`}>
        <Checkbox
          key={id}
          checked={selectedCategoryIds.has(id)}
          label={localize(name)}
          disabled={disabled}
          data-category-id={id}
          name={localize(name)}
          onChange={onChange}
        />
      </Tooltip>
    ))}
  </div>
);

// Edit clock tags, which are internally stored as categories
class TagEditor extends Component {
  state = this.getInitialState(this.props);

  componentWillReceiveProps(nextProps) {
    // Check if app categoryIds has changed
    if (!isEqual(nextProps.app.categoryIds, this.props.app.categoryIds)) {
      this.setState(this.getInitialState(nextProps));
    }
  }

  getInitialState(props) {
    const { app, categories } = props;

    // Get non-sytem categories
    const categoryIds = app.categoryIds.filter(
      (id) => categories[id] && categories[id].grouping !== CategoryGrouping.SYSTEM
    );

    return {
      existingCategoryIds: categoryIds,
      selectedCategoryIds: new Set(categoryIds),
    };
  }

  handleToggleCategory = (evt) => {
    const tagValue = evt.target.dataset.categoryId;
    const checked = evt.target.checked;

    const updated = new Set(this.state.selectedCategoryIds);

    if (checked) {
      updated.add(tagValue);
    } else {
      updated.delete(tagValue);
    }

    this.setState({
      selectedCategoryIds: updated,
    });
  };

  handleSaveCategories = () => {
    const { app } = this.props;
    const { existingCategoryIds, selectedCategoryIds } = this.state;

    // Remove old category ids, add new ones; leave rest alone
    const categoryIds = chain(app.categoryIds)
      .difference(existingCategoryIds)
      .union(Array.from(selectedCategoryIds))
      .value();

    const promise = this.props.onUpdateAppCategories(app, categoryIds);
    this.setState({ promise });
  };

  get editable() {
    const { editable } = this.props;
    const { existingCategoryIds } = this.state;

    return editable || existingCategoryIds.length === 0;
  }

  render() {
    const { app, categories } = this.props;
    const { existingCategoryIds, selectedCategoryIds, promise } = this.state;
    const { valid: canRequestChanges } = getActionValidity(app, SUBMIT_CHANGE_REQUEST);

    const isPublished = app.publishStatus === 'PUBLISHED';

    const categoryUpdated = xor(existingCategoryIds, Array.from(selectedCategoryIds)).length > 0;

    return (
      <div>
        <h3>Tags</h3>
        <SelectCategories
          disabled={!this.editable}
          categories={categories}
          selectedCategoryIds={selectedCategoryIds}
          onChange={this.handleToggleCategory}
        />
        <MarginTop>
          {this.editable && (
            <PromiseActionButton
              onClick={this.handleSaveCategories}
              promise={promise}
              disabled={!categoryUpdated}>
              Update tags
            </PromiseActionButton>
          )}
          {!this.editable && isPublished && canRequestChanges && (
            <DialogOpener
              button={<Button>Request changes</Button>}
              dialog={<CategoryChangeDialog app={app} />}
            />
          )}
        </MarginTop>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  categories: getCategories(state, {
    includeRestricted: true,
    appType: props.app.type,
  }),
});

const mapDispatchToProps = (dispatch) => ({
  onUpdateAppCategories: (app, categoryIds) => dispatch(updateAppCategories(app, categoryIds)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TagEditor);
