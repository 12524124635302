import { map, fromPairs, identity, union } from 'lodash';
import { AssetType, AssetCategory } from 'gam/constants';
import { preferredScreenshotTypes } from 'lib/config';

const { GALLERY_ICON } = AssetType;

// Convert list into object map
const toObjMap = (items, key, valueFunc = identity) =>
  fromPairs(items.map((item) => [item[key], valueFunc(item.value)]));

// Convert object map into list
const fromObjMap = (obj, key, valueFunc = identity) =>
  map(obj, (value, objKey) => ({
    [key]: objKey,
    value: valueFunc(value),
  }));

export class Asset {
  constructor(data) {
    this._data = data;
  }

  get id() {
    return this._data.id;
  }

  get type() {
    return this._data.type;
  }

  get uri() {
    return this._data.uri;
  }

  toJSON() {
    return {
      id: this.id,
    };
  }
}

// All assets for a particular type
export class AssetsOfType {
  constructor(localizedAssets = []) {
    this.assetsByLocale = toObjMap(localizedAssets, 'locale', (items) =>
      map(items, (data) => new Asset(data))
    );
  }

  get locales() {
    return Object.keys(this.assetsByLocale);
  }

  toJSON() {
    return fromObjMap(this.assetsByLocale, 'locale');
  }

  getLocalizedAssets(locale) {
    return this.assetsByLocale[locale];
  }

  setLocalizedAssets(locale, assets) {
    if (!locale || !assets) {
      throw new Error('missing locale/assets');
    }

    this.assetsByLocale[locale] = assets;
  }
}

// Generic wrapper for an assets field
export class Assets {
  constructor(assets = []) {
    Object.defineProperty(this, 'assetsOfTypes', {
      value: toObjMap(assets, 'type', (item) => new AssetsOfType(item)),
    });
  }

  toJSON() {
    return fromObjMap(this.assetsOfTypes, 'type');
  }

  getLocalizedAssets(type, locale) {
    const assetsOfType = this.assetsOfTypes[type];
    return assetsOfType && assetsOfType.getLocalizedAssets(locale);
  }

  setLocalizedAssets(type, locale, assets) {
    let assetsOfType = this.assetsOfTypes[type];
    if (!assetsOfType) {
      this.assetsOfTypes[type] = assetsOfType = new AssetsOfType();
    }

    assetsOfType.setLocalizedAssets(locale, assets);
  }

  getAssetTypes() {
    return Object.keys(this.assetsOfType);
  }

  // Returns list of [{locale, assets}]
  getAssetsPerLocale(type) {
    const assetsOfType = this.assetsOfTypes[type];
    if (!assetsOfType) {
      return [];
    }

    const results = [];
    for (const locale of assetsOfType.locales) {
      results.push({
        locale,
        assets: assetsOfType.getLocalizedAssets(locale),
      });
    }
    return results;
  }
}

export class AppAssets extends Assets {
  constructor(app, { sparse = false } = {}) {
    super(sparse ? [] : app.assets);
    Object.defineProperty(this, 'app', {
      value: app,
    });
  }

  getGalleryIcon() {
    const values = this.getLocalizedAssets(GALLERY_ICON, 'en');
    return values && values[0];
  }

  setGalleryIcon(icon) {
    this.setLocalizedAssets(GALLERY_ICON, 'en', [icon]);
  }

  getScreenshots() {
    let screenshots = [];
    for (const { type, category } of this.app.supportedAssetTypes) {
      if (category === AssetCategory.SCREENSHOT) {
        screenshots = [...screenshots, ...this.getAssetsPerLocale(type)];
      }
    }
    return screenshots;
  }

  getScreenshotAssetTypes() {
    return this.app.supportedAssetTypes
      .filter(({ category }) => category === AssetCategory.SCREENSHOT)
      .map(({ type }) => type);
  }

  // Preview screenshots for admin, etc
  getPreviewScreenshots({
    lang = 'en',
    preferredTypes = preferredScreenshotTypes,
  } = {}) {
    const screenshotTypes = union([
      ...preferredTypes,
      ...this.getScreenshotAssetTypes(),
    ]);

    for (const type of screenshotTypes) {
      const screenshots = this.getLocalizedAssets(type, lang);
      if (screenshots) {
        return screenshots;
      }
    }

    return [];
  }
}
