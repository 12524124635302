import { Tabs } from '@blueprintjs/core';
import styled from 'styled-components';

const TabsWrapper = styled(Tabs as any)`
  .bp3-tab-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export default TabsWrapper;
