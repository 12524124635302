import React, { Component } from 'react';
import { Button, Callout, Checkbox, Intent } from '@blueprintjs/core';

import termsOfServiceUrl from 'gam/docs/platform-tos.md';

import './SetupDeveloper.css';
import { connect } from 'react-redux';

import { fetchEnrollment, fetchUserInfo } from 'gam/actions';
import styled from 'styled-components';

import { AuthClient } from 'lib/network/auth-client';

import { apiEndpointsFitbit } from 'lib/config';

const MessageContainer = styled.div`
  margin-top: 3em;
  max-width: 45%;
`;

class TermsOfService extends Component {
  state = {
    agreeTerms: false,
    message: '',
    intent: null,
  };

  constructor(props) {
    super(props);
    this.enrollDeveloperQuery = this.enrollDeveloperQuery.bind(this);
  }

  onChangeCheckbox = (evt) => {
    this.setState({ agreeTerms: evt.target.checked });
  };

  async enrollDeveloperQuery() {
    const accessToken = AuthClient.getInstance().accessToken;

    const mergedInit = {
      cache: 'no-store',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${accessToken}`,
      },
    };

    await fetch(
      `${apiEndpointsFitbit}/1/user/-/changeDeveloperStatus?termsAccepted=true&developerTermsVersion=2017-09-26`,
      mergedInit
    )
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            message: 'Terms of service signed successfully',
            intent: Intent.SUCCESS,
          });

          this.props.onFetchEnrollment();
          this.props.onFetchUserInfo();
          this.props.onAgreeTermsOfService();
        } else {
          throw new Error('Request failed');
        }
      })
      .catch((error) => {
        this.setState({
          message: 'Failed to submit TOS acceptance request, please try again later',
          intent: Intent.WARNING,
        });
        return error;
      });
  }

  render() {
    const { agreeTerms } = this.state;

    return (
      <div className="SetupDeveloper--agreement">
        <iframe
          className="SetupDeveloper--agreeFrame"
          title="Distribution Agreement"
          src={termsOfServiceUrl}
        />
        <div className="SetupDeveloper--agreeBox">
          <div
            className="SetupDeveloper--agreeBoxContents"
            id="developer-setup-distribution-wrapper">
            <Checkbox
              id="developer-setup-distribution-checkbox"
              value={agreeTerms}
              onChange={this.onChangeCheckbox}
              label="I have read and agree to the Fitbit Platform Terms of Service."
            />
            <Button
              id="developer-setup-distribution-button"
              disabled={!agreeTerms}
              onClick={this.enrollDeveloperQuery}>
              Next
            </Button>
            {this.state.message ? (
              <MessageContainer>
                <Callout intent={this.state.intent}>{this.state.message}</Callout>
              </MessageContainer>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onFetchUserInfo: () => dispatch(fetchUserInfo()),
  onFetchEnrollment: () => dispatch(fetchEnrollment()),
});

export default connect(null, mapDispatchToProps)(TermsOfService);
