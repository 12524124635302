import React, { useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Button } from '@blueprintjs/core';
import styled from 'styled-components';

import AppList from 'gam/components/AppList';
import CreateAppDialog from 'gam/components/CreateAppDialog';
import { RequirementsPopover } from 'lib/controls/popover';

import { createApp } from 'gam/actions';
import { getActionValidity, getRequirementsFromActionValidity } from 'gam/utils';
import { localize } from 'lib/utils';
import { CREATE_APP } from 'lib/types';

import { getDeveloperProfile } from 'gam/state-utils';

const AppsPage = styled.div`
  margin: 1em;
`;

const CardSpacer = styled.div`
  margin-bottom: 2em;
`;

const ProfileHeader = styled.div`
  margin-bottom: 1em;
`;

const DeveloperProfileApps = ({ developerProfile }) => {
  const dispatch = useDispatch();
  const createDialogRef = useRef();

  // TODO use DialogOpener or a similar hook-based abstraction
  const showCreateAppDialog = useCallback(() => {
    createDialogRef.current.show();
  }, [createDialogRef]);

  const submitCreateApp = useCallback(
    (data) => {
      createDialogRef.current.setCreating(true);
      const createAppPromise = dispatch(
        createApp({
          ...data,
          developerProfileId: developerProfile.id,
        })
      );

      createAppPromise
        .then(() => {
          createDialogRef.current.close();
          createDialogRef.current.setCreating(false);
        })
        .catch((err) => {
          createDialogRef.current.setErrorMessage(err.message.replace(/^GraphQL error: /, ''));
          createDialogRef.current.setCreating(false);
        });
    },
    [dispatch, createDialogRef, developerProfile]
  );

  const { name, apps } = developerProfile;

  const createAppAction = getActionValidity(developerProfile, CREATE_APP);

  return (
    <Card>
      <ProfileHeader id="developer-profile-name-wrapper">
        <b>Developer profile:</b> {localize(name) || '<no developer name set>'}
      </ProfileHeader>
      {apps.length > 0 && <AppList apps={apps} />}
      <RequirementsPopover
        disabled={createAppAction.valid}
        requirements={getRequirementsFromActionValidity(createAppAction)}>
        <Button
          disabled={!createAppAction.valid}
          onClick={showCreateAppDialog}
          id="create-app-button">
          Create app
        </Button>
      </RequirementsPopover>

      <CreateAppDialog
        ref={createDialogRef}
        onSubmit={submitCreateApp}
        data-test-type="create-button"
        developerProfile={developerProfile}
      />
    </Card>
  );
};

const AllProfiles = () => {
  const developerProfiles = useSelector(({ gam }) =>
    (gam.userDeveloperInfo.developerProfileIds || []).map((id) => getDeveloperProfile({ gam }, id))
  );

  // Sort by localized name (on a copy)
  const sortedProfiles = [...developerProfiles].sort((a, b) =>
    localize(a.name).localeCompare(localize(b.name))
  );

  return (
    <AppsPage>
      {sortedProfiles.map((profile) => (
        <CardSpacer>
          <DeveloperProfileApps key={profile.id} developerProfile={profile} />
        </CardSpacer>
      ))}
    </AppsPage>
  );
};

export default AllProfiles;
