import React, { Component } from 'react';
import { connect } from 'react-redux';

import AppIconEditor from './AppIconEditor';
import CategoryEditor from './CategoryEditor';
import TagEditor from './TagEditor';

import { AppType } from 'gam/constants';
import { updateApp } from 'gam/actions';
import { AppAssets } from 'gam/models/Assets';

import './MiscDetailsEditor.css';

class MiscDetailsEditor extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState(props);
  }

  getInitialState(props) {
    const { app } = props;

    return {
      // TODO don't parse assets every time
      image: new AppAssets(app).getGalleryIcon(),
    };
  }

  componentWillReceiveProps(nextProps) {
    // If app changes (shallow compare), reload
    if (this.props.app !== nextProps.app) {
      return this.setState(this.getInitialState(nextProps));
    }
  }

  get isClock() {
    return this.props.app.type === AppType.CLOCK;
  }

  get isTile() {
    return this.props.app.type === AppType.TILE;
  }

  handleSelectImage = (imageAsset) => {
    this.setState({ image: imageAsset });

    const { app } = this.props;
    const assets = new AppAssets(app, { sparse: true });
    assets.setGalleryIcon(imageAsset);

    this.props.onUpdateApp(app, {
      assets,
    });
  };

  render() {
    const { app, editable, admin } = this.props;
    const { image } = this.state;
    const showTagsCategories = true; // false until we support categories/tags

    return (
      <div className="MiscDetailsEditor">
        <div className="MiscDetailsEditor--row flex-row">
          {showTagsCategories && (
            <div className="flex-1">
              {this.isClock || this.isTile ? (
                <TagEditor app={app} editable={editable} data-test-type="update-catagory-button" />
              ) : (
                <CategoryEditor app={app} editable={editable} includeRestricted={admin} />
              )}
            </div>
          )}
          {!this.isClock && !this.isTile && (
            <div className="MiscDetailsEditor--middle">
              <div className="MiscDetailsEditor--iconEditor">
                <h3>Icon</h3>
                <AppIconEditor app={app} image={image} onSelectAsset={this.handleSelectImage} />
              </div>
            </div>
          )}
          <div className="flex-3"></div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onUpdateApp: (app, changes) => dispatch(updateApp(app, changes)),
});

export default connect(null, mapDispatchToProps)(MiscDetailsEditor);
