import { ApolloClient } from '@apollo/client';
import { InMemoryCache } from '@apollo/client';
import { ApolloLink } from 'apollo-link';

import { createAuthLink } from './auth-link';
import { createMultipartUploadLink } from './multipart-upload-link';

export function getApolloClient(uri: string) {
  const cache = new InMemoryCache({
    // Manage custom id mappings here to make sure that apollo updates
    // its internal cache
    dataIdFromObject(obj) {
      if (obj.__typename === 'App') {
        // @ts-ignore
        return obj.refId;
      } else {
        // @ts-ignore
        return obj.id || obj._id;
      }
    },
  });

  const authLink = createAuthLink();

  const uploadLink = createMultipartUploadLink({
    uri,
  });

  const link = ApolloLink.from([authLink, uploadLink]);

  return new ApolloClient({
    link,
    // @ts-ignore
    cache: cache.restore(window.__APOLLO_STATE__ || {}),
  });
}
