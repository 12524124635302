import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem } from '@blueprintjs/core';

import {
  ImageBox,
  ImagePlaceholderBox,
  ImageUploadDialog,
} from 'gam/components/ImageManager';

import { AssetType } from 'gam/constants';

const GALLERY_ICON = AssetType.GALLERY_ICON;
const DEFAULT_GALLERY_ICON_INFO = {
  width: 80,
  height: 80,
};

// Edit a single image
export default class AppIconEditor extends Component {
  state = {};

  showUpload = () => {
    this.setState({ showingUploadDialog: true });
  };

  hideUpload = () => {
    this.setState({ showingUploadDialog: false });
  };

  handleSelectAsset = (image) => {
    this.hideUpload();
    this.props.onSelectAsset(image);
  };

  get assetTypeInfo() {
    return this.props.app.supportedAssetTypes.find(
      ({ type }) => type === GALLERY_ICON
    );
  }

  renderImage(image) {
    const menu = (
      <Menu>
        <MenuItem text="Change image" icon="edit" onClick={this.showUpload} />
      </Menu>
    );
    const { width, height } = this.assetTypeInfo || DEFAULT_GALLERY_ICON_INFO;

    return <ImageBox image={image} menu={menu} width={width} height={height} />;
  }

  renderPlaceholder() {
    const { width, height } = this.assetTypeInfo || DEFAULT_GALLERY_ICON_INFO;

    return (
      <ImagePlaceholderBox
        width={width}
        height={height}
        onClick={this.showUpload}
      />
    );
  }

  render() {
    const { app, image } = this.props;
    const { showingUploadDialog } = this.state;

    return (
      <div>
        {image ? this.renderImage(image) : this.renderPlaceholder()}
        <ImageUploadDialog
          app={app}
          isOpen={showingUploadDialog}
          assetTypeInfo={this.assetTypeInfo}
          onSelectAsset={this.handleSelectAsset}
          onClose={this.hideUpload}
        />
      </div>
    );
  }
}

AppIconEditor.propTypes = {
  app: PropTypes.object.isRequired,
  image: PropTypes.object,
  onSelectAsset: PropTypes.func.isRequired,
};
