import React, { Component } from 'react';
import { Button } from '@blueprintjs/core';
import { NonIdealState, Intent, Callout } from '@blueprintjs/core';

import './SetupDeveloper.css';
import { connect } from 'react-redux';

import { fetchEnrollment, fetchUserInfo } from 'gam/actions';

import { AuthClient } from 'lib/network/auth-client';

import { apiEndpointsFitbit } from 'lib/config';
import styled from 'styled-components';

const MessageContainer = styled.div`
  margin-top: 3em;
  max-width: 45%;
`;

class EmailVerification extends Component {
  state = {
    isResendAllowed: this.props.enrollment.emailVerification,
    resendPressed: false,
    message: '',
    intent: null,
  };

  constructor(props) {
    super(props);
    this.handleResend = this.handleResend.bind(this);
    this.emailVerificationQuery = this.emailVerificationQuery.bind(this);
  }

  renderDescription() {
    return <div>Reload this page once you've verified your email address.</div>;
  }

  async emailVerificationQuery() {
    const accessToken = AuthClient.getInstance().accessToken;

    const mergedInit = {
      cache: 'no-store',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${accessToken}`,
      },
    };

    await fetch(`${apiEndpointsFitbit}/1/account/verify-email/resend.json`, mergedInit)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            message: 'Verification email sent with success',
            intent: Intent.SUCCESS,
          });
        } else {
          throw new Error(`${response.status}`);
        }
      })
      .catch((error) => {
        if (error.message == '400') {
          this.setState({
            message: 'An error occurred',
            intent: Intent.WARNING,
          });
          return error;
        } else {
          this.setState({
            message: 'Failed to submit the verification email. Please try again',
            intent: Intent.WARNING,
          });
          return error;
        }
      });

    this.props.onFetchUserInfo();
    this.props.onFetchEnrollment();
  }

  handleResend() {
    if (this.state.isResendAllowed === 'HAS_VERIFIED') {
      return;
    }

    this.emailVerificationQuery();
    this.setState({ resendPressed: true });
  }

  render() {
    return (
      <div className="SetupDeveloper--agreement">
        <NonIdealState
          title="Email verification required"
          description={this.renderDescription()}
          visual="error"
          action={
            <Button
              className="pt-large"
              text="RESEND"
              onClick={this.handleResend}
              intent={Intent.PRIMARY}
              disabled={this.state.isResendAllowed === 'HAS_VERIFIED'}
            />
          }
        />
        {this.state.message ? (
          <MessageContainer>
            <Callout intent={this.state.intent}>{this.state.message}</Callout>
          </MessageContainer>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = ({ gam }) => ({
  enrollment: gam.enrollment,
});

const mapDispatchToProps = (dispatch) => ({
  onFetchEnrollment: () => dispatch(fetchEnrollment()),
  onFetchUserInfo: () => dispatch(fetchUserInfo()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerification);
