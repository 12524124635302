import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Classes, Button } from '@blueprintjs/core';

import DialogOpener from 'lib/components/DialogOpener';

export const ConfirmationDialog = ({ isOpen, onClose, onConfirm, confirmText }) => {
  const handleConfirmClick = useCallback(() => {
    onClose && onClose(); // close dialog first
    onConfirm && onConfirm(); // call onConfirm
  }, [onClose, onConfirm]);

  return (
    <Dialog isOpen={isOpen} onClose={onClose} title="Are you sure?">
      <div className={Classes.DIALOG_BODY}>
        <p>{confirmText}</p>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={handleConfirmClick}>Yes</Button>
          <Button onClick={onClose}>No</Button>
        </div>
      </div>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

export const ConfirmationDialogOpener = ({ button, onConfirm, confirmText }) => (
  <DialogOpener
    button={button}
    dialog={<ConfirmationDialog onConfirm={onConfirm} confirmText={confirmText} isOpen={false} />}
  />
);

ConfirmationDialogOpener.propTypes = {
  button: PropTypes.element.isRequired,
  onConfirm: PropTypes.func.isRequired,
  confirmText: PropTypes.string.isRequired,
};
