import React from 'react';
import styled from 'styled-components';
import { DivClassNameFactory } from './utils';
import {
  Button,
  Callout,
  Checkbox,
  EditableText,
  TextArea,
} from '@blueprintjs/core';
import { isEqual, identity } from 'lodash';
import { Tooltip, TagInput } from '@blueprintjs/core';
import {
  useForm,
  useFormState,
  useField,
  Form as FinalForm,
  Field,
} from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { withStableUniqueId } from 'react-stable-uniqueid';

export const FormGroup = DivClassNameFactory('bp3-form-group');

// Re-export Field
export { Field } from 'react-final-form';

export const WideFormGroup = styled.div.attrs({
  className: 'bp3-form-group',
})`
  align-items: stretch;
`;

export const SimpleInlineInput = ({ id = Math.random(), label, ...rest }) => (
  <div className="bp3-form-group bp3-inline">
    <label className="bp3-label" htmlFor={id}>
      {label}
    </label>
    <div className="bp3-form-content">
      <input id={id} className="bp3-input" {...rest} />
    </div>
  </div>
);

export const SimpleInlineTextArea = ({
  id = Math.random(),
  label,
  ...rest
}) => (
  <div className="bp3-form-group bp3-inline">
    <label className="bp3-label" htmlFor={id}>
      {label}
    </label>
    <div className="bp3-form-content">
      <textarea id={id} className="bp3-input" {...rest} />
    </div>
  </div>
);

// TODO: label id
export const FormRow = ({ className = '', label, children }) => (
  <div className={`bp3-form-group bp3-inline ${className}`}>
    <label className="bp3-label">{label}</label>
    <div className="bp3-form-content">{children}</div>
  </div>
);

export const FormGrid = styled.div`
  display: grid;
  grid-template-columns: [labels] auto [controls] 1fr;
  grid-auto-flow: row;
  grid-gap: 1em;

  & > .bp3-label {
    grid-column: labels;
    grid-row: auto;
  }

  & > .bp3-form-content {
    grid-column: controls;
    grid-row: auto;
  }
`;

export const FormGridRow = withStableUniqueId()(
  ({ uniqueId: id, label, children }) => [
    <label key={`${id}-label`} htmlFor={id} className="bp3-label">
      {label}
    </label>,
    <div key={`${id}-content`} id={id} className="bp3-form-content">
      {children}
    </div>,
  ]
);

// Field (react-final-form) + TagInput
export const TagInputField = ({ name, ...props }) => (
  <Field name={name}>
    {({ input: { value, onChange } }) => (
      <TagInput values={value || []} onChange={onChange} {...props} />
    )}
  </Field>
);

// Wrapper around react-final-form with common mutations,
// helper for getting a reference to the form API
// formRef: function that gets called with ({ form, handleSubmit, ... })
// htmlSubmit: hook up submission to the form so that enter key submits
// htmlComponent: <form> or <div>; note that form cannot be nested
export const SimpleForm = ({
  children,
  formRef,
  htmlFormProps = {},
  htmlSubmit,
  htmlComponent = <form />, // TODO change default to
  ...props
}) => {
  const render = typeof children === 'function' ? children : () => children;

  return (
    <FinalForm mutators={arrayMutators} initialValuesEqual={isEqual} {...props}>
      {(formRenderProps) => {
        if (typeof formRef === 'function') {
          formRef(formRenderProps);
        }

        htmlFormProps = {
          ...htmlFormProps,
          onSubmit: htmlSubmit
            ? formRenderProps.handleSubmit
            : htmlFormProps.onSubmit || ((e) => e.preventDefault()),
        };

        return React.cloneElement(htmlComponent, {
          ...htmlFormProps,
          children: render(formRenderProps),
        });
      }}
    </FinalForm>
  );
};

// Simplified Field that just calls render() with the value
export const FieldValue = ({ name, children: render }) => (
  <Field name={name}>{({ input: { value } }) => render(value)}</Field>
);

// Functional form that requires fewer braces
export const withFieldValue = (name, render) => (
  <FieldValue name={name}>{render}</FieldValue>
);

export const EachField = ({ name, children: render }) => (
  <FieldArray name={name}>
    {({ fields }) => fields.map((name) => <Field name={name}>{render}</Field>)}
  </FieldArray>
);

export const InputField = (props) => <Field component="input" {...props} />;

export const EditableTextField = ({ name, placeholder, ...props }) => {
  const {
    input: { value, onChange, ...inputProps },
  } = useField(name, {
    parse: identity,
    ...props,
  });

  return (
    <EditableText
      {...inputProps}
      defaultValue={value}
      onConfirm={onChange}
      placeholder={placeholder}
    />
  );
};

export const CheckboxField = ({ label, ...props }) => (
  <Field
    type="checkbox"
    {...props}
    component={({ input }) => <Checkbox {...{ label }} {...input} />}
  />
);

export const FileField = ({ name, accept, validate, inputProps }) => {
  const {
    input: { onChange, ...fieldInputProps },
  } = useField(name, {
    validate,
  });

  return (
    <input
      {...fieldInputProps}
      type="file"
      value=""
      accept={accept}
      onChange={(evt) => onChange((evt.target.files || [])[0])}
      {...inputProps}
    />
  );
};

export const TextField = (props) => (
  <Field component="input" className="bp3-input" {...props} />
);

export const TextAreaField = ({ name, validate, ...props }) => {
  const { input: inputProps } = useField(name, {
    validate,
  });

  return <TextArea {...inputProps} {...props} />;
};

export const Condition = ({ name, when, children }) => (
  <Field name={name} subscription={{ value: true }}>
    {({ input: { value } }) => (when(value) ? children : null)}
  </Field>
);

// Submit button that calls submit() for the current form
export const SubmitButton = ({
  children,
  render,
  component = Button,
  disabled,
  ...extraProps
}) => {
  const { submit } = useForm();
  const { valid, dirty } = useFormState();

  const props = {
    ...extraProps,
    onClick: submit,
    disabled: disabled || !valid || !dirty,
  };

  if (render) {
    return render(props);
  }

  return React.createElement(component, {
    ...props,
    children,
  });
};

export const parseOptionalInt = (str) => (str ? parseInt(str, 10) : undefined);
export const parseOptionalFloat = (str) => (str ? parseFloat(str) : undefined);
export const formatOptionalInt = (value) =>
  typeof value === 'number' ? value.toString() : '';

export const UnsavedChangesDot = ({ className, dirty }) =>
  dirty && (
    <Tooltip content="There are currently unsaved changes">
      <span
        className={`${className} bp3-icon-standard bp3-icon-dot bp3-intent-primary`}
      />
    </Tooltip>
  );

export const IndentedUnsavedChangesDot = styled(UnsavedChangesDot)`
  margin-left: 10px;
`;

export const FieldErrorCallout = ({ name }) => {
  const { error } = useField(name);
  return error ? (
    <Callout title="Error" intent="danger">
      {error}
    </Callout>
  ) : null;
};

const FORM_ERROR_SUBSCRIPTION = {
  invalid: true,
  error: true,
  submitError: true,
};

// Show form errors; does NOT show field-specific errors
export const FormErrorCallout = () => {
  const { error, submitError } = useFormState({
    subscription: FORM_ERROR_SUBSCRIPTION,
  });

  const errorMessage = error || submitError;

  return errorMessage ? (
    <Callout title="Error" intent="danger">
      {errorMessage}
    </Callout>
  ) : null;
};
