import React, { Component } from 'react';

import { localize } from 'lib/utils';
import { PublishStatus } from 'gam/constants';
import { makePrivateLink, isPaymentStatusLocked } from 'gam/utils';

import { RatingSummary } from './RatingSummary';
import PublishStatusManager from './PublishStatusManager';
import PlatformList from '../PlatformList';

import './AppOverview.css';

export const Row = (props) => (
  <tr>
    <td style={{ minWidth: '150px' }}>{props.name}</td>
    <td>{props.children}</td>
  </tr>
);

export const UUIDRow = ({ app }) => (
  <Row name="UUID">
    <b>{app.id}</b>
  </Row>
);

export const LinkRow = ({ app, privateLink = makePrivateLink(app) }) => (
  <Row name="Link">
    <div
      style={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '300px',
      }}>
      <a href={privateLink} title={privateLink}>
        {privateLink}
      </a>
    </div>
  </Row>
);

const DeveloperIdRow = ({ id }) => (
  <React.Fragment>
    <Row name="Developer ID">{id}</Row>
    <tr>
      <td colSpan="2">
        <i>Use the Developer ID with App Cluster project config in SDK CLI or Studio</i>
      </td>
    </tr>
  </React.Fragment>
);

export const RatingSummaryRow = ({ app: { ratingSummary } }) => (
  <Row name="Average Rating">
    {ratingSummary && ratingSummary.numRatings > 0 ? (
      <RatingSummary ratingSummary={ratingSummary} />
    ) : (
      'No ratings yet'
    )}
  </Row>
);

class AppOverview extends Component {
  renderDetailRows() {
    const { app } = this.props;
    const hasVersions = app.versions.length > 0;

    const isPublished = app.publishStatus === PublishStatus.PUBLISHED;
    const showRatingSummary = isPublished && app.ratingSummary;

    let rows = (
      <React.Fragment>
        <Row name="Name">{localize(app.name)}</Row>
        <DeveloperIdRow id={app.developerProfileId} />
      </React.Fragment>
    );

    if (hasVersions) {
      rows = (
        <React.Fragment>
          <UUIDRow app={app} />
          <PlatformList app={app} />
          <LinkRow app={app} />
          {showRatingSummary && <RatingSummaryRow app={app} />}
          <DeveloperIdRow id={app.developerProfileId} />
        </React.Fragment>
      );
    }

    return rows;
  }

  renderFreeApp() {
    const { app } = this.props;

    if (isPaymentStatusLocked(app)) {
      return <b className="AppOverview--freeAppCallout">Free App</b>;
    }
  }

  render() {
    const { app, children, publishStatusManager } = this.props;

    return (
      <div className="AppOverview">
        <div className="AppOverview--infoTable">
          <table>
            <tbody>{children || this.renderDetailRows()}</tbody>
          </table>
          {this.renderFreeApp()}
        </div>
        {publishStatusManager || (
          <PublishStatusManager className="AppOverview--publishStatus" app={app} />
        )}
      </div>
    );
  }
}

export default AppOverview;
