import { AsyncActionHandler } from 'lib/action-handler';
import apollo from '../apollo';
import { cancelChangeRequestMutation } from 'gam/queries';
import { fetchApp } from 'gam/actions';

export default new AsyncActionHandler({
  name: 'cancel_change_request',
  handler(app, id) {
    return async (dispatch) => {
      const { developerProfileId, refId } = app;

      await apollo.mutate({
        mutation: cancelChangeRequestMutation,
        variables: {
          developerProfileId,
          refId,
          id,
        },
      });

      // Reload app
      await dispatch(fetchApp({ developerProfileId, refId }));
    };
  },
});
