import React from 'react';
import { HTMLTable, Icon } from '@blueprintjs/core';
import { useTable, useSortBy, actions } from 'react-table';
import styled from 'styled-components';

const SortableTh = styled(({ isSorted, isSortedDesc, children, ...props }) => (
  <th {...props}>
    {children}
    {isSorted && (
      <Icon icon={isSortedDesc ? 'sort-desc' : 'sort-asc'} color="grey" />
    )}
  </th>
))`
  &&& {
    font-weight: normal;
  }

  & > .bp3-icon {
    margin-left: 6px;
  }
`;

const TableHeader = ({ headerGroups }) => (
  <thead>
    {headerGroups.map((headerGroup) => (
      <tr {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map((column) => (
          <SortableTh
            isSorted={column.isSorted}
            isSortedDesc={column.isSortedDesc}
            {...column.getHeaderProps(column.getSortByToggleProps())}
          >
            {column.render('Header')}
          </SortableTh>
        ))}
      </tr>
    ))}
  </thead>
);

const TableRow = ({ row }) => (
  <tr {...row.getRowProps()}>
    {row.cells.map((cell) => (
      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
    ))}
  </tr>
);

function saveSortReducer(state, action, previousState, instance) {
  const { sortStorageName } = instance;

  let sortStorageKey;

  if (sortStorageName) {
    sortStorageKey = `saved-sort-${sortStorageName}`;
  }

  if (action.type === actions.init) {
    if (sortStorageKey) {
      try {
        const storedItem = localStorage.getItem(sortStorageKey);
        const sortBy = storedItem && JSON.parse(storedItem);

        if (Array.isArray(sortBy)) {
          return {
            ...state,
            sortBy,
          };
        }
      } catch (e) {
        console.error(e);
      }
    }

    return state;
  } else if (action.type === actions.toggleSortBy) {
    if (sortStorageKey) {
      try {
        window.localStorage.setItem(
          sortStorageKey,
          JSON.stringify(state.sortBy)
        );
      } catch (e) {
        console.error(e);
      }
    }
  }
}

function useSavedSort(hooks) {
  hooks.stateReducers.push(saveSortReducer);
}

useSavedSort.pluginName = 'useSavedSort';

// Blueprint-themed sortable table
const SortableTable = (tableOptions) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    testId,
  } = useTable(
    {
      ...tableOptions,
    },
    useSortBy,
    useSavedSort
  );

  return (
    <HTMLTable {...getTableProps()} data-test-type={testId}>
      <TableHeader headerGroups={headerGroups} />
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return <TableRow key={row.getRowProps().key} row={row} />;
        })}
      </tbody>
    </HTMLTable>
  );
};

export default SortableTable;
