import { AsyncActionHandler } from 'lib/action-handler';
import apollo from '../apollo';
import { cancelBuildInReviewMutation } from 'gam/queries';

import { fetchApp } from 'gam/actions';

export default new AsyncActionHandler({
  name: 'cancel_build_in_review',
  handler(app, version) {
    return async (dispatch) => {
      const { developerProfileId, refId } = app;
      const { buildReviewId } = version;

      await apollo.mutate({
        mutation: cancelBuildInReviewMutation,
        variables: {
          id: buildReviewId,
          developerProfileId,
          refId,
        },
      });

      await dispatch(fetchApp({ developerProfileId, refId }));
    };
  },
});
