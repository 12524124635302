import UAParser from 'ua-parser-js';
import { map } from 'lodash';
import { LocalizedString } from './types/db/LocalizedString';
import { fallbackLanguages } from 'lib/config';
export { stripGraphQLIntrospection } from './graphql-utils';


// Get list of keys to lookup when localizing
export function getLocaleKeys(lang: string, { includeFallbacks = false } = {}) {
  lang = lang.toLowerCase();

  const keys = [];
  do {
    keys.push(lang);

    const dashIndex = lang.lastIndexOf('-');
    if (dashIndex === -1) {
      break;
    }

    lang = lang.substr(0, dashIndex);
  } while (true);

  if (includeFallbacks) {
    keys.push(...fallbackLanguages);
  }

  return keys;
}

// Get a localized string from LocalizedString array
// If lang is provided, localize for the language, otherwise
// localize to the current browser language
export function localize(stringsArray: LocalizedString[] = [] , lang = null): string {
  // Get keys for looking up strings, by order of preference
  const localeKeys = getLocaleKeys(lang || navigator.language, {
    // only include fallbacks when not localizing for a specific language
    includeFallbacks: !lang,
  });

  const stringMap : {[key: string]: string } = {};
  for (const localizedString of stringsArray) {
    stringMap[localizedString.locale] = localizedString.value;
  }

  for (const key of localeKeys) {
    const value = stringMap[key];
    if (value) {
      return value;
    }
  }

  return '';
}

export function shortenRefId(refId: string) {
  return refId.slice(-12);
}

const uaParser = new UAParser();
const { name: osName } = uaParser.getOS();
export const isiOS = osName === 'iOS';

// Convert [{locale, value}, ...] to {'locale': 'value', ...}
export function toLocaleObject(localizedStrings: LocalizedString[] = []) : {[key: string]: string } {
  const mapping : any= {};
  for (const { locale, value } of localizedStrings) {
    mapping[locale] = value;
  }
  return mapping;
}

// Convert {'locale': 'value', ...} to [{locale, value}, ...]
export function toLocalizedStrings(localeObject: {[key: string]: string }) : LocalizedString[] {
  return map(localeObject, (value, locale) => ({
    locale,
    value,
  }));
}

export function removeTrailingSlashes(url:string) {
  return url.replace(/\/+$/, ''); // Removes one or more trailing slashes from URL
}
