import { ActionHandler } from '../action-handler';

export default new ActionHandler({
  name: 'save_session',
  defaultState: {
    session: {
      isLoggedIn: false,
    },
  },
  handler({ isLoggedIn, userId }) {
    return {
      session: {
        isLoggedIn,
        userId,
      },
    };
  },
  reduce(state, session) {
    return { ...session };
  },
});
