import React from 'react';
import styled from 'styled-components';

import faqUrl from 'gam/docs/faq.md';

const FullScreenIframe = styled.iframe`
  width: 100%;
  height: 100%;
`;

export default () => <FullScreenIframe title="FAQ" src={faqUrl} />;
