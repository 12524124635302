import gql from 'graphql-tag';
import { categoryFragment } from '../common/categories-fragments';

export const getCategoriesQuery = gql`
  query getCategories($appType: AppType) {
    categories (appType: $appType) {
      ...categoryFragment
    }
  }
  ${categoryFragment}
`;

export const getCategoryQuery = gql`
  query getCategory($id: ID!) {
    category (id: $id) {
      ...categoryFragment
    }
  }
  ${categoryFragment}
`;


