import gql from 'graphql-tag';

// Update a category
export const categoryFragment = gql`
  fragment categoryFragment on Category {
    id
    appType
    grouping
    openToDevelopers
    name {
      locale
      value
    }
    blurb {
      locale
      value
    }
    description {
      locale
      value
    }
  }
`;
