import { fetchCategories, fetchPaymentProviders } from 'gam/actions';

// Load categories
export const loadCategoriesAsync = (thunk) => async (dispatch, getState) =>
  Promise.all([
    // Load in parallel
    dispatch(fetchCategories()),
    dispatch(thunk, getState),
  ]);

export const loadPaymentProviders = (thunk) => async (dispatch, getState) =>
  Promise.all([
    // Load in parallel
    dispatch(fetchPaymentProviders()),
    dispatch(thunk, getState),
  ]);
