import React from 'react';

import './VersionCard.css';

export default ({ left, middle, right, fbaUploaded }) => (
  <div>
    <div className="VersionCardColumn bp3-card">
      {fbaUploaded && (
        <div className="VersionCardDanger bp3-callout bp3-intent-danger">
          Closing the tab will result in losing the upload.
        </div>
      )}
      <div className="VersionCard">
        <div className="VersionCard--cardLeft">{left}</div>
        <div className="VersionCard--cardMiddle">{middle}</div>
        <div className="VersionCard--cardRight">{right}</div>
      </div>
    </div>
  </div>
);
