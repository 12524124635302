import { AsyncActionHandler } from 'lib/action-handler';
import apollo from '../apollo';
import { createAppMutation } from 'gam/queries';
import { fetchUserInfo } from 'gam/actions';

export default new AsyncActionHandler({
  name: 'create_app',
  handler(data) {
    return async (dispatch) => {
      await apollo.mutate({
        mutation: createAppMutation,
        variables: data,
      });

      await dispatch(fetchUserInfo());
    };
  },
});
