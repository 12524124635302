import { chain, map } from 'lodash';
import { AppType, CategoryGrouping } from 'gam/constants';
import { localize } from 'lib/utils';

export function createNormalizedMap(
  newObjects = [],
  oldMap = {},
  keyProp = 'id'
) {
  const newMap = {};
  newObjects.forEach((obj) => {
    const mapKey = obj[keyProp];
    newMap[mapKey] = normalizeObjects(obj, oldMap[mapKey]);
  });
  return newMap;
}

export function normalizeObjects(newObj, oldObj) {
  if (oldObj) {
    return {
      ...oldObj,
      ...newObj,
    };
  }
  return newObj;
}

export function pickAll(objs, field) {
  return [].concat(...map(objs, field));
}

export function extractIds(objs, field, newField, keyProp = 'id') {
  return objs.map((obj) => {
    const values = obj[field];
    if (!values) {
      return obj;
    }

    const objCopy = {
      ...obj,
      [newField]: map(values, keyProp),
    };

    delete objCopy[field]; // faster than omit()
    return objCopy;
  });
}

export function mergeApps(state, apps) {
  return {
    apps: {
      ...state.apps,
      ...createNormalizedMap(apps, state.apps, 'refId'),
    },
  };
}

export function mergeAppsById(state, apps) {
  return {
    appsById: {
      ...state.appsById,
      ...createNormalizedMap(apps, state.appsById, 'id'),
    },
  };
}

export function getApp({ gam }, refId) {
  return gam.apps[refId];
}

export function mergeDeveloperProfiles(state, developerProfiles) {
  // Normalize apps
  const apps = pickAll(developerProfiles, 'apps');
  developerProfiles = extractIds(
    developerProfiles,
    'apps',
    'appRefIds',
    'refId'
  );

  return {
    ...mergeApps(state, apps),
    developerProfiles: {
      ...state.developerProfiles,
      ...createNormalizedMap(developerProfiles, state.developerProfiles),
    },
  };
}

// Get developer profile, with apps
export function getDeveloperProfile({ gam }, id) {
  const profile = gam.developerProfiles[id];
  const appRefIds = profile.appRefIds || [];

  return {
    ...profile,
    apps: appRefIds.map((refId) => getApp({ gam }, refId)),
  };
}

export function hasDeveloperProfile({ gam }) {
  const {
    userDeveloperInfo: { developerProfileIds },
  } = gam;

  return developerProfileIds && developerProfileIds.length > 0;
}

// Get categories (excluding system categories)
// Returns an object mapping category ids to category, sorted by name
export function getCategories(
  { gam: { categories } },
  { appType, includeSystem, includeRestricted } = {}
) {
  // Kind of weird having localized sorting here but keeps
  // the logic centralized and simplifies update checks in components
  return chain(categories)
    .filter((category) => {
      if (appType && category.appType !== appType) {
        return false;
      }

      if (!category.openToDevelopers && !includeRestricted) {
        return false;
      }

      if (!includeSystem && category.grouping === CategoryGrouping.SYSTEM) {
        return false;
      }

      return true;
    })
    .sortBy((category) => localize(category.name))
    .keyBy('id')
    .value();
}

export function getAppCategories(state, options = {}) {
  return getCategories(state, {
    appType: AppType.APP,
    ...options,
  });
}

export function getClockCategories(state, options = {}) {
  return getCategories(state, {
    appType: AppType.CLOCK,
    ...options,
  });
}
