import { AsyncActionHandler } from 'lib/action-handler';
import { getCategoriesQuery } from 'gam/queries';
import apollo from '../apollo';
import { createNormalizedMap } from 'gam/state-utils';

export default new AsyncActionHandler({
  name: 'fetch_categories',
  defaultState: {
    categories: {},
  },
  handler() {
    return async () => {
      const { data: response } = await apollo.query({
        query: getCategoriesQuery,
      });

      return {
        entries: response.categories,
      };
    };
  },
  reduce(state, { entries }) {
    return {
      categories: createNormalizedMap(entries, state.categories),
    };
  },
});
