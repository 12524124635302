import React from 'react';
import {
  Popover,
  PopoverInteractionKind,
  Position,
  Icon,
  Intent,
} from '@blueprintjs/core';
import styled from 'styled-components';

import { Padded } from './layout';

const RequirementList = styled.ul`
  list-style: none;
  padding: 0px;
  margin: 0px;
`;

export const RequirementsPopover = ({ requirements, disabled, children }) => (
  <Popover
    disabled={disabled}
    autoFocus={false}
    enforceFocus={false}
    content={
      <Padded>
        <RequirementList>
          {requirements.map(({ key, ok, text }) => (
            <li key={key}>
              <Icon
                icon={ok ? 'small-tick' : 'small-cross'}
                intent={ok ? Intent.PRIMARY : Intent.DANGER}
              />{' '}
              {text}
            </li>
          ))}
        </RequirementList>
      </Padded>
    }
    interactionKind={PopoverInteractionKind.HOVER}
    position={Position.BOTTOM}
  >
    {children}
  </Popover>
);
