import { connectRoutes } from 'redux-first-router';
import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import { routesMap } from './routes';
import { reducers as gamActionReducers } from 'gam/actions';
import asyncThunk from 'lib/async-thunk';
import querySerializer from 'query-string';
import { routeStateReducer, getBasename } from 'lib/route-utils';

const connectedRoutes = connectRoutes(routesMap, {
  basename: getBasename(),
  querySerializer,
});

// Support Redux Devtools Chrome Extension
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default createStore(
  combineReducers({
    location: connectedRoutes.reducer,
    gam: gamActionReducers,
    routes: routeStateReducer,
  }),
  composeEnhancers(
    connectedRoutes.enhancer,
    applyMiddleware(connectedRoutes.middleware, asyncThunk)
  )
);
