import React from 'react';
import styled from 'styled-components';
import { Popover, PopoverInteractionKind } from '@blueprintjs/core';
import { range } from 'lodash';

const Padded = styled.div`
  padding: 1em;
`;

const StarDetails = ({ starCounts, numRatings }) => (
  <table>
    {range(5, 0, -1).map((i) => {
      const count = starCounts[i - 1];

      return (
        <tr key={`stars-${i}`}>
          <td>{'⭐'.repeat(i)}</td>
          <td>
            {count}{' '}
            {count > 0 ? `(${Math.round((100 * count) / numRatings)}%)` : ''}
          </td>
        </tr>
      );
    })}
  </table>
);

const PopoverContent = (props) => (
  <Padded>
    <h5>Ratings</h5>
    <StarDetails {...props} />
  </Padded>
);

export const RatingSummary = ({
  ratingSummary: { numRatings, averageStars, starCounts },
}) => (
  <Popover
    content={<PopoverContent starCounts={starCounts} numRatings={numRatings} />}
    interactionKind={PopoverInteractionKind.HOVER}
  >
    <div>
      {averageStars.toLocaleString(undefined, {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      })}{' '}
      ({numRatings} {numRatings === 1 ? 'rating' : 'ratings'})
    </div>
  </Popover>
);
