import { AsyncActionHandler } from 'lib/action-handler';
import { uploadAppAssetMutation } from 'gam/queries';
import apollo from '../apollo';

export default new AsyncActionHandler({
  name: 'upload_app_asset',
  defaultState: {
    uploadedAssets: {},
  },
  handler(app, options) {
    return async () => {
      const { developerProfileId, refId } = app;
      const { type, file, uploadId } = options;

      // return { uploadId, asset: {id: 'fake'} }

      const { data: response } = await apollo.mutate({
        mutation: uploadAppAssetMutation,
        variables: {
          developerProfileId,
          refId,
          type,
          file,
        },
      });

      return {
        uploadId,
        asset: response.appAsset,
      };
    };
  },
  reduce(state, { uploadId, asset }) {
    return {
      uploadedAssets: {
        ...state.uploadedAssets,
        [uploadId]: asset,
      },
    };
  },
});
